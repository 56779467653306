import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import {variables} from "../../Variables";
import axios from '../Services/AxiosService';
import Select from "../Select";

import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
registerLocale('ru', ru);

const ENTER_KEY = 13

export function StatisticAddHourModal({token}) {
    const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState(new Date())
    const [addTime, setAddTime] = useState("")
    const [selectedUser, setSelectedUser] = useState(null);
    const [users, setUsers] = useState([])
    const [selectedVacancy, setselectedVacancy] = useState(null);
    const [vacancies, setVacancies] = useState([])
    const [comment, setComment] = useState("");
    const [isVideoSend, setIsVideoSend] = useState(false);

    const getUsers = () => {
        let url = variables.API_URL + '/api/get_recruter_options/'
        axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
             }
        }).then(res =>{
            console.log(res.data)
            setUsers(res.data)
            setSelectedUser(res.data[0]?.value)
            console.log(res.data[0]?.value)
        })
    }

    const getVacancies = () => {
        axios.get(variables.API_URL + '/api/vacancy/', {
            headers: {
                "Accept": " application/json",
                "Content-Type": " application/json",
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                let options = res.data.data.map(item => {return {value: item.id, label: item.name}})
                setVacancies(options);
                setselectedVacancy(options[0]?.value)
            });
    }

    const addTimeToRecruter = () => {
        console.log(selectedUser)
        console.log(selectedVacancy)
        if (addTime) {
            let url = variables.API_URL + '/api/add_time_to_recruter/'
            axios.post(url, {
                vacancy: selectedVacancy,
                time: addTime,
                user_id: selectedUser,
                date: selectedDate,
                comment: comment
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                console.log(res)
            })
        }
    }

    useEffect(()=>{
        getUsers()
        getVacancies()
    }, [])

    return (
        <div className='modal fade' id="statsistic_add_hour_modal" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centred">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Добавить время сотруднику</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="input-group mb-3">
                            <span className="input-group-text">Рекрутер</span>
                            <Select className='select-vacancy' value={selectedUser}
                                    onChange={(e)=>setSelectedUser(e.target.value)}
                                    options={users}/>
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text">Вакансия</span>
                            <Select className='select-vacancy' value={selectedVacancy}
                                    onChange={(e)=>setselectedVacancy(e.target.value)}
                                    options={vacancies}/>
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text">Количество минут</span>
                            <input type="number" className='form-control' value={addTime} placeholder="Введите количество минут"  pattern="[0-9]*"
                                   onChange={(e)=>e.target.validity.valid && setAddTime(e.target.value)}/>
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text">Дата</span>
                            <DatePicker
                                locale="ru"
                                dateFormat="dd.MM.yyyy"
                                selected={selectedDate}
                                onChange={(e)=>setSelectedDate(e)}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text">Комментарий</span>
                            <input type="text" className='form-control' value={comment} placeholder="Введите комментарий"
                                   onChange={(e)=>setComment(e.target.value)}/>
                        </div>
                    </div>

                    <button type="button" className="btn btn-primary float-start" data-bs-dismiss="modal"  onClick={addTimeToRecruter}>Отправить</button>
                </div>
            </div>
        </div>
    )
}