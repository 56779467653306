import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, NavLink, Route, Routes } from "react-router-dom";
import "./App.css";
import Applies from "./Components/Applies";
import { Apply } from "./Components/Apply";
import { ApplyForCustomer } from "./Components/ApplyForCustomer";
import ApplyForSales from "./Components/ApplyForSales.jsx";
import { CustomerApply } from "./Components/CustomerApply";
import { Feedbacks } from "./Components/Feedbacks";
import Login from "./Components/login/Login";
import Logout from "./Components/login/Logout";
import Lpr from "./Components/Lpr";
import { PersonApply } from "./Components/PersonApply";
import { Persons } from "./Components/Persons";
import Photos from "./Components/Photos/Photos";
import { Statistic } from "./Components/Statistic/Statistic";
import { Vacancy } from "./Components/Vacancy";
import { VacancyDetail } from "./Components/VacancyStatistic/VacancyDetail";
import { Videos } from "./Components/Videos";
import { getToken } from "./store/userSlice.js";
import { setHeight, setWidth } from "./store/windowSlice";

function App() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const changeWindowSize = () => {
    dispatch(setWidth({ width: window.innerWidth }));
    dispatch(setHeight({ height: window.innerHeight }));
  };

  useEffect(() => {
    dispatch(getToken());
  }, []);

  // useLayoutEffect(() => {
  //   try {
  //     let token = localStorage.getItem("token");
  //     saveTokenData(token);
  //   } catch (e) {}
  // }, []);

  useEffect(() => {
    window.addEventListener("resize", changeWindowSize);
  }, []);

  useEffect(() => {
    if (user.customerId && user.customerId !== "loading" && window.location.pathname == "/") {
      window.open("/vacancy", "_self");
      // console.log(user);
    }
  }, [user.customerId]);

  if (user?.id === "loading") {
    return <div>PRELOADER</div>;
  }

  if (!user.id) {
    return (
      <BrowserRouter>
        <Routes>
          {/* <Route/> */}
          <Route path="/apply_for_sales/" element={<ApplyForSales />} />
          <Route path="/sended_apply/" element={<ApplyForCustomer />} />
          <Route path="/*" element={<Login />} />
          {/* <Route path="/*" element={<Navigate replace to="/signin" />} /> */}
        </Routes>
      </BrowserRouter>
    );
  }

  // суперюзер
  // юзер
  // заказчик
  // гость

  return (
    <>
      {user?.id === "loading" ? (
        <div>PRELOADER</div>
      ) : (
        <BrowserRouter>
          <div className="App container">
            {!user.customerId && <NavigateBar user={user} />}
            <Routes>
              {!user.id ? (
                <Route path="*" element={<Login />} />
              ) : (
                <>
                  <Route
                    path="/"
                    element={
                      <Applies
                        token={user.token}
                        isSuperUser={user?.isSuperUser?.toString()}
                        username={user.username}
                        customerId={user.customerId}
                      />
                    }
                  />
                  {!user.customerId && (
                    <>
                      <Route path="/apply_for_sales/" element={<ApplyForSales />} />
                      <Route path="/sended_apply/" element={<ApplyForCustomer />} />
                      <Route
                        path="/apply/:applyId"
                        element={
                          <Apply
                            userId={user.id}
                            isSuperUser={user?.isSuperUser?.toString()}
                            username={user.username}
                            token={user.token}
                          />
                        }
                      />
                      <Route
                        path="/:personId"
                        element={<PersonApply isSuperUser={user?.isSuperUser?.toString()} token={user.token} />}
                      />
                      <Route path="/person" element={<Persons token={user.token} />} />
                      {user?.isSuperUser?.toString() === "2" && (
                        <Route path="/photos" element={<Photos token={user.token} />} />
                      )}

                      <Route
                        path="/video"
                        element={
                          <Videos
                            token={user.token}
                            isSuperUser={user?.isSuperUser?.toString()}
                            username={user.username}
                          />
                        }
                      />
                      <Route
                        path="/feedback"
                        element={
                          <Feedbacks
                            token={user.token}
                            isSuperUser={user?.isSuperUser?.toString()}
                            username={user.username}
                          />
                        }
                      />
                      <Route
                        path="/lpr"
                        element={
                          <Lpr
                            token={user.token}
                            isSuperUser={user?.isSuperUser?.toString()}
                            username={user.username}
                          />
                        }
                      />
                      <Route
                        path="/statistic"
                        element={
                          <Statistic
                            token={user.token}
                            isSuperUser={user?.isSuperUser?.toString()}
                            username={user.username}
                          />
                        }
                      />
                    </>
                  )}

                  <Route
                    path="/vacancy"
                    element={
                      <Vacancy
                        token={user.token}
                        isSuperUser={user?.isSuperUser?.toString()}
                        customerId={user.customerId}
                      />
                    }
                  />
                  <Route
                    path="/vacancy/:vacancyId"
                    element={
                      <VacancyDetail customerId={user.customerId} token={user.token} isSuperUser={user.isSuperUser} />
                    }
                  />
                  <Route
                    path="/customer_apply/:applyId"
                    element={
                      <CustomerApply
                        userId={user.id}
                        isSuperUser={user?.isSuperUser?.toString()}
                        username={user.username}
                        token={user.token}
                      />
                    }
                  />
                  <Route path="/logout" element={<Logout />} />
                </>
              )}
            </Routes>
          </div>
        </BrowserRouter>
      )}
    </>
  );
}

var NavigateBar = ({ user }) => {
  return (
    <div>
      <nav className="navbar navbar-expand-sm navbar-dark">
        <ul className="navbar-nav">
          <li className="nav-item- m-1">
            <NavLink className="btn btn-light btn-outline-primary" to="/statistic">
              Статистика
            </NavLink>
          </li>
          <li className="nav-item- m-1">
            <NavLink className="btn btn-light btn-outline-primary" to="/">
              Отклики
            </NavLink>
          </li>
          <li className="nav-item- m-1">
            <NavLink className="btn btn-light btn-outline-primary" to="/person">
              Люди
            </NavLink>
          </li>
          <li className="nav-item- m-1">
            <NavLink className="btn btn-light btn-outline-primary" to="/vacancy">
              Вакансии
            </NavLink>
          </li>
          {user?.isSuperUser?.toString() === "2" && (
            <li className="nav-item- m-1">
              <NavLink className="btn btn-light btn-outline-primary" to="/photos">
                Галерея
              </NavLink>
            </li>
          )}
          <li className="nav-item- m-1">
            <NavLink className="btn btn-light btn-outline-primary" to="/video">
              Видео
            </NavLink>
          </li>
          <li className="nav-item- m-1">
            <NavLink className="btn btn-light btn-outline-primary" to="/feedback">
              ОС
            </NavLink>
          </li>
          <li className="nav-item- m-1">
            <NavLink className="btn btn-light btn-outline-primary" to="/lpr">
              ЛПР
            </NavLink>
          </li>
          <li className="nav-item- m-1 btn_logout">
            <NavLink className="btn btn-light btn-outline-primary" to="/logout">
              Выход
              {/* <i class="fa fa-sign-out" aria-hidden="true"></i> */}
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default App;
