import React, { useEffect, useState } from "react";
import { variables } from "../../../Variables";
import axios from "axios";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";

registerLocale("ru", ru);

export function AddTagModal({ token, user, apply, video, setApplyTags }) {
  const [tags, setTags] = useState([]);
  const [search, setSearch] = useState("");
  const [groups, setGroups] = useState([]);
  const getTags = () => {
    let url = variables.API_URL + "/api/get_video_tags?apply_id=" + apply;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTags(res.data.tags);
        setGroups(res.data.groups);
      });
  };

  useEffect(() => {
    setApplyTags(
      tags.filter((num) => {
        return num.status === "active";
      })
    );
  }, [tags]);

  const ChangeStateTag = async (tagId, status, pk) => {
    let url = variables.API_URL + "/api/get_video_tags";
    if (status === "inactive") {
      var response = await axios.put(
        url,
        {
          apply_id: apply,
          tag_id: tagId,
          user_id: user,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 201) {
        const cloneTags = structuredClone(tags);
        var changeTagId = cloneTags.findIndex((tag) => tag.id == tagId);

        cloneTags[changeTagId].status = "active";
        cloneTags[changeTagId].pk = response.data.id;
        setTags(cloneTags);
      }
    } else if (status === "active") {
      var response = await axios.delete(url + "?id=" + pk, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 204) {
        const cloneTags = structuredClone(tags);
        var changeTagId = cloneTags.findIndex((tag) => tag.id == tagId);
        cloneTags[changeTagId].status = "inactive";
        cloneTags[changeTagId].pk = undefined;
        setTags(cloneTags);
      }
    }
  };

  useEffect(() => {
    getTags();
  }, []);

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="modal fade" id="statsistic_add_tag_modal" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centred">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Добавить теги</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="input-group mb-3">
              <span className="input-group-text">Поиск</span>
              <input value={search} type="search" className="search" onChange={onChange} />
            </div>

            <div className="input-group mb-3" id="tag_block">
              <div className={"tags_additional_area"}>
                {tags
                  .filter((tag) => tag.tag.toLowerCase().includes(search.toLowerCase()))
                  .filter((tag) => tag.status === "active")
                  .map((obj, index) => (
                    <a
                      key={obj.id}
                      role="button"
                      className={`mb-2 mx-2 btn btn-outline-primary ${obj.status === "active" ? "active" : ""}`}
                      onClick={() => {
                        ChangeStateTag(obj.id, obj.status, obj.pk);
                      }}
                    >
                      {"#" + obj.tag}
                    </a>
                  ))}
              </div>

              {groups.map((obj, index) => (
                <div className="tags_additional_area">
                  <p className="tag_label">{obj.group}</p>
                  {tags
                    .filter((tag) => tag.tag.toLowerCase().includes(search.toLowerCase()))
                    .filter((tag) => tag.group === obj.group && tag.status === "inactive")
                    .map((obj, index) => [
                      <a
                        key={obj.id}
                        role="button"
                        className={`mb-2 mx-2 btn btn-outline-primary ${obj.status === "active" ? "active" : ""}`}
                        onClick={() => {
                          ChangeStateTag(obj.id, obj.status, obj.pk);
                        }}
                      >
                        {"#" + obj.tag}
                      </a>,
                    ])}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
