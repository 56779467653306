import React, { useEffect, useState } from "react";
import { variables } from "../Variables";
import "react-phone-input-2/lib/bootstrap.css";
import "../App.css";
import LPRService from "./LPRService";
import axios from "./Services/AxiosService";
import { useSelector } from "react-redux";
import Linkify from "react-linkify";

import { useParams } from "react-router-dom";
// new
export function CustomerApply(props) {
  let { applyId } = useParams();
  const windowWidth = useSelector((state) => state.window.width);

  const [error, setError] = useState(false);
  const [isOtOfDate, setIsOutOfDate] = useState(false);

  const [apply, setApply] = useState([]);
  const [exp, setExp] = useState([]);
  const [characteristic, setCharacteristic] = useState([]);
  const [education, setEducation] = useState([]);
  const [photo, setPhoto] = useState();
  const [video, setVideo] = useState([]);
  const [audio, setAudio] = useState([]);
  const [socialNetworks, setSocialNetworks] = useState([]);
  const [imagesGallery, setImagesGallery] = useState([]);

  const getApply = () => {
    let url = variables.API_URL + "/api/customer_apply/" + applyId;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((data) => {
        setApply(data.data.apply_data);
        setSocialNetworks(data.data.apply_data.social_networks);

        setCharacteristic(data.data.apply_data.characteristics);
        setEducation(data.data.apply_data.education_set);
        setExp(data.data.apply_data.experience_set);
        setPhoto(data.data.apply_data.photo);
        setVideo(data.data.video_data);
      })
      .catch((error) => {
        console.log(error);
        setError(true);
        if (error?.request?.status.toString() === "406") setIsOutOfDate(true);
      });
  };

  const getImagesGallery = async () => {
    const response = await fetch(variables.API_URL + "/api/images/" + applyId, {
      method: "GET",
      headers: {
        Accept: " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${props.token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      console.log(data);
      setImagesGallery(data);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getImagesGallery();
  }, []);

  useEffect(() => {
    getApply();
  }, []);

  const getImage = (image, folder) => {
    if (image) {
      if (folder) {
        return variables.API_URL + "/media/media/" + folder + "/" + image.split("/").pop();
      } else {
        return variables.API_URL + "/media/media/" + image.split("/").pop();
      }
    }
  };

  const getAudio = (audio_url) => {
    if (audio_url) {
      return variables.API_URL + "/get_audio/" + audio_url;
    }
  };

  if (error || isOtOfDate) {
    console.log("error: ", error);
    console.log("isOtOfDate: ", isOtOfDate);

    return <div>{isOtOfDate ? <h2>Ссылка устарела</h2> : <h2>Неверная ссылка</h2>}</div>;
  }

  const componentDecorator = (href, text, key) => (
    <a className="linkify__text" href={href} key={key} target="_blank">
      {text}
    </a>
  );

  return (
    <div className="App container">
      <div className="mt-4" style={{ textAlign: "left" }}>
        <div className="row is_mobile">
          <div className="col_5">
            <div className="page-head p-5 bg-light border rounded-3 mb-3">
              <h3 className="mb-3">{apply.name}</h3>
              {photo ? (
                <img style={{ width: "80%", marginLeft: "0.5rem" }} src={getImage(photo)} alt="Profile" />
              ) : null}
              <h4 className="mb-0 mt-2">{apply.position}</h4>
              <p className="mb-3">{apply.age}</p>
              <ul className="list-unstyled">
                <li>
                  <b>Статус: </b>
                  {apply.status}
                </li>
                {apply?.reject_reasons && (
                  <li>
                    <b>Причины отказа: </b>
                    {apply.reject_reasons}
                  </li>
                )}
                <li>
                  <b>Рекрутер: </b>
                  {apply.rec_account}
                </li>
                <li>
                  <b>Город: </b>
                  {apply?.city} {apply?.relocate_trips && ", " + apply.relocate_trips}
                </li>
                {apply?.metro && (
                  <li>
                    <b>Метро: </b>
                    {apply.metro}
                  </li>
                )}
                <li>
                  <b>Зарплата: </b>
                  {apply.salary}
                </li>
                <li>
                  <b>Специализация: </b>
                  {apply.specializations}
                </li>
                <li>
                  <b>Занятость: </b>
                  {apply.employment}
                </li>
                {apply?.email && (
                  <li>
                    <b>email: </b>
                    {apply.email}
                  </li>
                )}
                {socialNetworks.length ? (
                  <li>
                    {socialNetworks.map((item) => {
                      return (
                        <ul className="list-unstyled" key={item.id}>
                          <li>
                            <b>{item.name}: </b>
                            <a href={item.link} target="_blank">
                              {/* {`${item.link}`} */}
                              Ссылка
                            </a>
                          </li>
                        </ul>
                      );
                    })}
                  </li>
                ) : null}
              </ul>
            </div>

            <div>
              {windowWidth >= 701 && video?.length ? (
                <>
                  <h4>Видео:</h4>
                  {video.map((item) => (
                    <div>
                      <a href={item.video_url + "?t=" + props.token} target="_blank">
                        <img style={{ width: "100%", cursor: "pointer" }} src={item.miniature} alt="Profile" />
                      </a>
                      <p
                        style={{
                          wordWrap: "break-word",
                          wordBreak: "break-all",
                        }}
                      >
                        <a href={item.video_url + "?t=" + props.token} target="_blank"></a>
                      </p>
                    </div>
                  ))}
                </>
              ) : null}
              {windowWidth >= 701 && audio?.length ? (
                <>
                  <h4>Обратная связь:</h4>
                  {audio.map((item) => (
                    <p>
                      <a
                        key={item.id}
                        href={getAudio(item.audio_url)}
                        target="_blank"
                        style={{
                          wordWrap: "break-word",
                          wordBreak: "break-all",
                        }}
                      >
                        {item.parent_audio_name.replaceAll(/\d{9,12}/g, "")}
                      </a>
                    </p>
                  ))}
                </>
              ) : null}
            </div>
          </div>
          <div className="col_7">
            <div className="candidate-header">
              {/* <div className={"d-flex justify-content-end apply_button"}>
                <h4 className="mt-1 text-center">Соответствие:</h4>
                <h4 className="mt-1 text-center check_list_score">{apply.percentage_compliance}%</h4>
              </div> */}
              <h4>Характеристика рекрутера:</h4>
              {characteristic?.length ? (
                characteristic?.map((item) => (
                  <div>
                    <span className="comment-recruter">{item.recruter}</span>
                    <Linkify key={item.id} componentDecorator={componentDecorator}>
                      <p className="comment-text" style={{ whiteSpace: "break-spaces" }}>
                        {item.characteristic_text}
                      </p>
                    </Linkify>
                  </div>
                ))
              ) : (
                <h6>Нет характеристики</h6>
              )}
            </div>
            {apply?.letter && (
              <div className="candidate-header">
                <h4>Сопроводительное письмо:</h4>
                <p style={{ whiteSpace: "break-spaces" }}>{apply?.letter?.replaceAll("<br/>", "\n")}</p>
              </div>
            )}
            {imagesGallery.length ? (
              <>
                <h4>Изображения:</h4>
                <div className="image_area">
                  {imagesGallery.map((item, index) => {
                    return (
                      <div className="image_miniature_wrapper" key={item.id}>
                        <a href={getImage(item.image, "apply_images")} target="_blank">
                          <img className="image_miniature" src={getImage(item.image, "apply_images")} alt=""></img>
                        </a>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <></>
            )}
            {/* <h4>Изображения:</h4>
            <div className="image_area">
              {imagesGallery.map((item, index) => {
                return (
                  <div className="image_miniature_wrapper" key={item.id}>
                    <a
                      href={getImage(item.image, "apply_images")}
                      target="_blank"
                    >
                      <img
                        className="image_miniature"
                        // src={"http://media.recruto-crm.ru" + item.image}
                        src={getImage(item.image, "apply_images")} // TODO: Поправить!
                        alt=""
                      ></img>
                    </a>
                  </div>
                );
              })}
            </div> */}
            <h2>Резюме:</h2>
            <h4>О себе:</h4>
            <p style={{ whiteSpace: "break-spaces" }}>{apply?.about?.replaceAll("<br/>", "\n")}</p>
            {windowWidth > 701 ? (
              <>
                <h4>Образование:</h4>
                {education?.length ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Университет</th>
                        <th>Специальность</th>
                        <th>Дата выпуска</th>
                      </tr>
                    </thead>
                    <tbody>
                      {education.map((edu) => (
                        <tr key={edu.university}>
                          <td>{edu.university}</td>
                          <td>{edu.speciality}</td>
                          <td>{edu.date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <h6>Без образования</h6>
                )}
                <h4>Опыт работы:</h4>
                <div style={{ fontSize: "20px", fontWeight: "600" }}>{apply.full_experience}</div>
                <div style={{ fontSize: "16px", fontWeight: "500" }}>
                  Количество мест работы: {apply.experience_count}
                </div>
                <div style={{ fontSize: "16px", fontWeight: "500", marginBottom: "16px" }}>
                  Средняя продолжительность работы: {apply.experience_average} лет
                </div>

                {exp?.length ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: "20%" }}>Дата</th>
                        <th>Работа</th>
                      </tr>
                    </thead>
                    <tbody>
                      {exp.map((exp) => (
                        <tr key={exp.company}>
                          <td style={{ width: "20%" }}>
                            {exp.date}
                            <br />
                            <div style={{ color: "#888" }}>{exp.time}</div>
                          </td>
                          <td className="job-decription">
                            <p>
                              <strong>{exp.company}</strong>
                            </p>
                            {/* {!!exp.company_url && (
                              <a href={exp.company_url} target="_blank">
                                {exp.company_url}
                              </a>
                            )} */}
                            <p>{exp.company_description}</p>
                            <br />
                            <p>
                              <strong>{exp.position}</strong>
                            </p>
                            <br />
                            <p style={{ whiteSpace: "pre-wrap" }}>{exp.description.replaceAll("<br/>", "\n")}</p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <h6>Без опыта работы</h6>
                )}
              </>
            ) : (
              <>
                <h4>Образование:</h4>
                {education?.length ? (
                  education.map((edu) => (
                    <div key={edu.university} className="job-decription job-decription_mobile">
                      <p>
                        <strong>{edu.university}</strong>
                      </p>
                      <br />
                      <p>Год выпуска: {edu.date}</p>
                      <br />
                      <p>{edu.speciality}</p>
                    </div>
                  ))
                ) : (
                  <h6>Без образования</h6>
                )}
                <h4>Опыт работы:</h4>
                <div style={{ fontSize: "20px", fontWeight: "600" }}>{apply.full_experience}</div>
                <div style={{ fontSize: "16px", fontWeight: "500" }}>
                  Количество мест работы: {apply.experience_count}
                </div>
                <div style={{ fontSize: "16px", fontWeight: "500", marginBottom: "16px" }}>
                  Средняя продолжительность работы: {apply.experience_average} лет
                </div>

                {exp?.length ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: "20%" }}>Дата</th>
                        <th>Работа</th>
                      </tr>
                    </thead>
                    <tbody>
                      {exp.map((exp) => (
                        <tr key={exp.company}>
                          <td style={{ width: "20%" }}>
                            {exp.date}
                            <br />
                            <div style={{ color: "#888" }}>{exp.time}</div>
                          </td>
                          <td className="job-decription">
                            <p>
                              <strong>{exp.company}</strong>
                            </p>
                            {/* {!!exp.company_url && (
                              <a href={exp.company_url} target="_blank">
                                {exp.company_url}
                              </a>
                            )} */}
                            <p>{exp.company_description}</p>
                            <br />
                            <p>
                              <strong>{exp.position}</strong>
                            </p>
                            <br />
                            <p style={{ whiteSpace: "pre-wrap" }}>{exp.description.replaceAll("<br/>", "\n")}</p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <h6>Без опыта работы</h6>
                )}
              </>
            )}
          </div>
        </div>

        {windowWidth < 701 && video?.length ? (
          <>
            <h4>Видео:</h4>
            {video.map((item) => (
              <div key={item.id}>
                {/* <a href={item.video_url} target="_blank"> */}

                <a href={item.video_url + "?t=" + props.token} target="_blank">
                  <img style={{ width: "100%", cursor: "pointer" }} src={item.miniature} alt="Profile" />
                </a>
                <p style={{ wordWrap: "break-word", wordBreak: "break-all" }}>
                  <a href={item.video_url + "?t=" + props.token} target="_blank">
                    {/* {item.parent_video_name.replaceAll(/\d{9,12}/g, "")} */}
                  </a>
                </p>
              </div>
            ))}
          </>
        ) : null}
        {windowWidth < 701 && audio?.length ? (
          <>
            <h4>Обратная связь:</h4>
            {audio.map((item) => (
              <p>
                <a
                  key={item.id}
                  href={getAudio(item.audio_url)}
                  target="_blank"
                  style={{ wordWrap: "break-word", wordBreak: "break-all" }}
                >
                  {item.parent_audio_name.replaceAll(/\d{9,12}/g, "")}
                </a>
              </p>
            ))}
          </>
        ) : null}
      </div>
    </div>
  );
}
