import { createSlice } from "@reduxjs/toolkit";


const applyGalleryFilter = createSlice({
    name: 'apply_gallery_filter',
    initialState: {
        gender: '',
        birth_year_start: '',
        birth_year_end: '',
        region: '',
        date_created: '',
        with_video: 'f',
        resume_name: '',
        ordering: '',
        last_active_ordering: '',
        coldStatus: [],
    },
    reducers: {
        setAplyGalleryFilter(state, action) {
            if (action.payload.gender) state.gender = action.payload.gender
            if (action.payload.with_video) state.with_video = action.payload.with_video
            if (action.payload.birth_year_start) state.birth_year_start = action.payload.birth_year_start
            if (action.payload.birth_year_end) state.birth_year_end = action.payload.birth_year_end
            if (action.payload.ordering) state.ordering = action.payload.ordering
            if (action.payload.last_active_ordering) state.last_active_ordering = action.payload.last_active_ordering
            if (action.payload.coldStatus) {
                if (!state.coldStatus.includes(action.payload.coldStatus)) {
                    state.coldStatus.push(action.payload.coldStatus)
                } else {
                    state.coldStatus = state.coldStatus.filter((item) => item !== action.payload.coldStatus);
                }
            }
        },

        setFullAplyGalleryFilter(state, action) {
            if (action.payload.coldStatus) {
                state.coldStatus = action.payload.coldStatus
            }
            if (action.payload.gender) state.gender = action.payload.gender
            if (action.payload.with_video) state.with_video = action.payload.with_video
            if (action.payload.birth_year_start) state.birth_year_start = action.payload.birth_year_start
            if (action.payload.birth_year_end) state.birth_year_end = action.payload.birth_year_end
            if (action.payload.ordering) state.ordering = action.payload.ordering
            if (action.payload.last_active_ordering) state.last_active_ordering = action.payload.last_active_ordering
            if (action.payload.region) state.region = action.payload.region
            if (action.payload.date_created) state.date_created = action.payload.date_created
            if (action.payload.resume_name) state.resume_name = action.payload.resume_name
            return state

        },

        setRegionFilter(state, action) {
            state.region = action.payload.region
        },

        setDateCreatedFilter(state, action) {
            state.date_created = action.payload.date_created
        },

        setResumeNameFilter(state, action) {
            state.resume_name = action.payload.resume_name
        },

        clearAplyFilters(state) {
            state.gender = ''
            state.with_video = 'f'
            state.birth_year_start = ''
            state.birth_year_end = ''
            state.region = ''
            state.date_created = ''
            state.resume_name = ''
            state.ordering = 'start'
            state.last_active_ordering = ''
            state.coldStatus = []
        },
    }
})

export const { clearAplyFilters, setAplyGalleryFilter, setRegionFilter, setDateCreatedFilter, setResumeNameFilter, setFullAplyGalleryFilter } = applyGalleryFilter.actions;

export default applyGalleryFilter.reducer;