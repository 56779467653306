import React, { useEffect, useState } from "react";
import Pagination from "./pagination/Pagination";
import { variables } from "../Variables";
import axios from "./Services/AxiosService";
import { useNavigate, useParams } from "react-router-dom";

const ENTER_KEY = 13;

export function PersonApply(props) {
  let { personId } = useParams();

  const [pageNumber, setPageNumber] = useState(1);
  const [applies, setApplies] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [filterQuery, setFilterQuery] = useState("");
  const [hasProductFilter, setHasProductFilter] = useState(1);
  const navigate = useNavigate();

  const [image, setImage] = useState({});

  const handleMouseEnter = (e) => {
    e.target.style.color = "grey";
    e.target.style.cursor = "pointer";
  };
  const handleMouseLeave = (e) => {
    e.target.style.color = "black";
    e.target.style.cursor = "default";
  };

  const refreshList = (newFilter = "", sampleFilter = "1") => {
    // let filter = '';
    // if (sampleFilter!=='1'){
    //     const prodFilter = '?has_product_filter='+sampleFilter;
    //     const useFilter = newFilter===''?'':'&&filter='+newFilter;
    //     filter = prodFilter + useFilter;
    // }
    // else filter = newFilter===''?'':'?filter='+newFilter;
    fetch(variables.API_URL + "/api/apply/?person=" + personId, {
      headers: {
        Accept: " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${props.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setApplies(data.data);
        setTotalCount(data.count);
      });
    setPageNumber(1);
  };

  useEffect(() => {
    refreshList();
  }, []);

  const handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY) {
      setFilterQuery(e.target.value);
      refreshList(e.target.value, hasProductFilter);
    }
  };

  const getSectionsByURL = (link) => {
    // let filter = '';
    // if (hasProductFilter.toString()!=='1'){
    //     const prodFilter = '&&has_product_filter='+hasProductFilter;
    //     const useFilter = filterQuery===''?'':'&&filter='+filterQuery;
    //     filter = prodFilter + useFilter;
    // } else filter = filterQuery===''?'':'?filter='+filterQuery;
    const url = `${variables.API_URL + "/api/apply/"}${link}` + "&&person=" + personId;
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((response) => response.data);
  };

  const changePage = (page) => {
    getSectionsByURL("?page=" + page.toString()).then((result) => {
      setApplies(result.data);
      setPageNumber(page);
    });
  };

  const deleteApply = (apply_id) => {
    var result = window.confirm("Вы уверены что хотите удалить отклик?");
    if (result) {
      fetch(variables.API_URL + "/api/delete_apply/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        body: JSON.stringify({
          apply_id: apply_id,
        }),
      })
        .then((res) => {
          refreshList();
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    }
  };

  const getImage = (image) => {
    if (image) {
      let galleryImages = [];
      for (let img of image) {
        galleryImages.push({
          original: variables.API_URL + "/media/media/" + img.split("/").pop(),
          thumbnail: variables.API_URL + "/media/media/" + img.split("/").pop(),
        });
      }
      return galleryImages;
    }
  };

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th>Person ID</th>
            <th>Компания</th>
            <th>ФИО</th>
            <th>Возраст</th>
            <th>Статус</th>
            <th>Должность</th>
            <th>ID вакансии</th>
            <th>Опции</th>
          </tr>
        </thead>
        <tbody>
          {applies.map((apply) => (
            <tr key={apply.id}>
              <td>{apply.person_id}</td>
              <td>{apply.vacancy_id == 0 ? apply.extension_company_name : apply.company}</td>
              <td
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={(e) => {
                  if (e.ctrlKey) window.open("/apply/" + apply.id, "_blank");
                  else navigate("/apply/" + apply.id);
                }}
                onAuxClick={(e) => {
                  if (e.button == 1) window.open("/apply/" + apply.id, "_blank");
                }}
              >
                {apply.name}
              </td>
              <td>{apply.age}</td>
              <td>{apply.status}</td>
              <td>{apply.position}</td>
              <td>{apply.vacancy_id}</td>
              <td>
                {apply.youtrack_url ? (
                  <a href={apply.youtrack_url} target="_blank">
                    Ссылка
                  </a>
                ) : null}
              </td>
              {props.isSuperUser === "2" ? (
                <td>
                  <button type="button" className="btn btn-danger" onClick={() => deleteApply(apply.id)}>
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-center">
        <Pagination
          className="pagination-bar"
          currentPage={pageNumber}
          totalCount={totalCount}
          pageSize={10}
          onPageChange={(page) => changePage(page)}
        />
      </div>
    </div>
  );
}
