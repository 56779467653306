import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import jwt from "jwt-decode";

export var getToken = createAsyncThunk("user/getToken", (_, { rejectWithValue }) => {
  try {
    var token = localStorage.getItem("token");
    // console.log("token", token);
    if (!token) throw new Error("Token not exist");
    var tokenDecode = jwt(token);
    // console.log("tokenDecode", tokenDecode);
    tokenDecode["token"] = token;
    return tokenDecode;
  } catch (error) {
    return rejectWithValue("Token error");
  }
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    token: "loading",
    id: "loading",
    username: "loading",
    customerId: "loading",
    isSuperUser: "loading",
  },
  reducers: {
    setToken(state, action) {
      state.token = action.payload.token;
    },
    setUser(state, action) {
      state.id = action.payload.id;
      state.username = action.payload.username;
      state.customerId = action.payload.customerId;
      state.token = action.payload.token;
      state.isSuperUser = action.payload.is_superuser;
    },
    resetUserSlice(state) {
      // console.log("resetUserSlice");
      state.token = "";
      state.id = "";
      state.username = "";
      state.customerId = "";
      state.isSuperUser = "";
      localStorage.removeItem("token");
    },
  },
  extraReducers: {
    [getToken.pending]: (state) => {
      state.token = "loading";
      state.id = "loading";
      state.username = "loading";
      state.customerId = "loading";
      state.isSuperUser = "loading";
    },
    [getToken.fulfilled]: (state, action) => {
      state.token = action.payload.token;
      state.id = action.payload.id;
      state.username = action.payload.uname;
      state.customerId = action.payload.company;
      state.isSuperUser = action.payload.is_superuser;
    },
    [getToken.rejected]: (state, action) => {
      state.token = "";
      state.id = "";
      state.username = "";
      state.customerId = "";
      state.isSuperUser = "";
    },
  },
});

export const { setToken, setUser, resetUserSlice } = userSlice.actions;

export default userSlice.reducer;
