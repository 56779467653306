import React from "react";

function Field(props) {
    return (
        <div className="form-floating mb-3">
            <input disabled={props.disabled} type={props.type} id={props.id} className={"" + (!props.dirty ? "form-control form-control-lg" : "form-control form-control-lg is-invalid")}
                   placeholder="1" onChange={props.onChange} value={props.value} validations="True" required={props.required} onBlur={props.onBlur} onKeyDown={props.onKeyDown}/>
            <label htmlFor={props.id}>{props.required && <span className={"text-danger"}> * </span>}{props.message}</label>
        {/*{props.dirty && <p style={{visibility: props.dirty ? 'visible' : 'hidden', color: 'red'}}*/}
        {/*                                            className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled MuiFormHelperText-marginDense">{props.dirtyMessage}</p>}*/}
        </div>


    )
}
export default Field;