import React, {useEffect, useState} from "react";
import ProfLevelService from "./ProfLevelService";

const profLevelService = new ProfLevelService();

export default function SelectProfLevel(props) {
    const [profLevels, setProfLevels] = useState([])

    useEffect(() => {
        profLevelService.getProfLevels().then(function (result) {
            setProfLevels(result.results)
        });
    }, [])

    return (<>
            <select id={"32"} className="form-select" value={props.value} onChange={props.onChange}
                    style={{marginTop: '5px'}}
                    defaultValue={"default"} required={props.required}>
                <option disabled={props.disabledefault} key={"default"} value={""}>{"Уровень позиции"}</option>
                {profLevels?.map(e =>
                    <option key={e.id} value={e.id}>{e.name}</option>
                )}
            </select>
            <label htmlFor={"32"}>{props.required && <span className={"text-danger"}> * </span>}Уровень</label>
        </>
    )
}