import React, { useEffect, useState, useRef } from "react";
import { variables } from "../Variables";
import "react-phone-input-2/lib/bootstrap.css";
import "../App.css";
import LPRService from "./LPRService";
import axios from "./Services/AxiosService";
import { useSelector } from "react-redux";
import Linkify from "react-linkify";
import { Navigate, useNavigate } from "react-router-dom";
import "./ApplyForSales.css";
import { Modal } from "../Modal.jsx";

var ApplyForSales = (props) => {
  const link = new URLSearchParams(window.location.search).get("link");
  const user = useSelector((state) => state.user);

  const [error, setError] = useState(false);
  const [isOtOfDate, setIsOutOfDate] = useState(false);

  const [apply, setApply] = useState([]);
  const [exp, setExp] = useState([]);
  const [characteristic, setCharacteristic] = useState([]);
  const [education, setEducation] = useState([]);
  const [photo, setPhoto] = useState();
  const [video, setVideo] = useState([]);
  const [audio, setAudio] = useState([]);
  const [records, setRecords] = useState([]);
  var { 0: visibleModal, 1: setVisibleModal } = useState(false);

  const navigate = useNavigate();

  const getApply = async () => {
    if (link) {
      var params = { link: link };
      let url = variables.API_URL + "/api/get_apply_for_sales/";

      axios
        .get(url, {
          params,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        })
        .then((data) => {
          if (user?.id !== "loading" && user?.id !== "") navigate("/apply/" + data?.data?.apply_data?.id);
          setApply(data.data.apply_data);
          setCharacteristic(data.data.apply_data.characteristics);
          setEducation(data.data.apply_data.education_set);
          setExp(data.data.apply_data.experience_set);
          setPhoto(data.data.apply_data.photo);
          setVideo(data.data.video_data.videos);
          setAudio(data.data.audio_data.feedbacks);
          setRecords(data.data.record_data.records);
        })
        .catch((error) => {
          setError(true);
          if (error.request.status.toString() === "406") setIsOutOfDate(true);
        });
    }
  };

  const getImage = (image, folder) => {
    if (image) {
      if (folder) {
        return variables.API_URL + "/media/" + folder + "/" + image.split("/").pop();
      } else {
        return variables.API_URL + "/media/" + image.split("/").pop();
      }
    }
  };

  useEffect(() => {
    getApply();
  }, []);

  const getFile = (document) => {
    if (document) {
      window.open(variables.API_URL + document, "_blank");
    }
  };
  const getVideo = (video_url) => {
    if (video_url) {
      return variables.API_URL + "/get_video/" + video_url;
    }
  };

  const getAudio = (audio_url) => {
    if (audio_url) {
      return variables.API_URL + "/get_audio/" + audio_url;
    }
  };

  const getRecord = (audio_url) => {
    if (audio_url) {
      return variables.API_URL + "/get_record/" + audio_url;
    }
  };

  const componentDecorator = (href, text, key) => (
    <a className="linkify__text" href={href} key={key} target="_blank">
      {text}
    </a>
  );

  var handleOpenModal = () => {
    setVisibleModal((prev) => !prev);
  };

  if (!apply?.id) {
    return <div>{isOtOfDate ? <h2>Ссылка устарела</h2> : <h2>Неверная ссылка</h2>}</div>;
  } else {
  }

  return (
    <>
      {!apply?.id && <div>{isOtOfDate ? <h2>Ссылка устарела</h2> : <h2>Неверная ссылка</h2>}</div>}
      {!!apply?.id && (
        <div className="App container">
          {visibleModal && <RequestInterviewModal setVisible={setVisibleModal} apply={apply} link={link} />}
          <div className="mt-4" style={{ textAlign: "left" }}>
            <div className="apply_wrapper">
              <div className="">
                <div className="page-head p-5 bg-light border rounded-3 mb-3 img_wrapp">
                  {photo ? (
                    <img style={{ width: "80%", marginLeft: "0.5rem" }} src={getImage(photo)} alt="Profile" />
                  ) : null}
                  <div className="person">
                    {!!apply?.position && <h4 style={{ margin: 0 }}>{apply.position}</h4>}
                    {!!apply?.age && <p style={{ margin: 0 }}>Возраст: {apply.age}</p>}
                    {!!apply?.salary && <p style={{ margin: 0 }}>Зарплата: {apply.salary}</p>}
                  </div>

                  <button onClick={handleOpenModal} className="request_interview_btn">
                    <span>Запросить интервью</span>
                  </button>
                </div>

                <div>
                  {!!video?.length && (
                    <>
                      <h4>Видео:</h4>
                      {video.map((item, index) => (
                        <div className="bottom-border-div">
                          <video
                            className="fast_video"
                            style={{width: '100%', height: '100%'}}
                            src={getVideo(item.video_url)}
                            type="video/mp4"
                            muted
                            poster={getImage(item.miniature)}
                            controls
                          ></video>
                        </div>
                      ))}
                    </>
                  )}
                  {!!audio.length && (
                    <>
                      <h4>Обратная связь:</h4>
                      {audio.map((item) => (
                        <p>
                          <a
                            key={item.id}
                            href={getAudio(item.audio_url)}
                            target="_blank"
                            style={{
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                            }}
                          >
                            {item.parent_audio_name.replace(/\d{9,12}/g, "")}
                          </a>
                        </p>
                      ))}
                    </>
                  )}
                  {!!records.length && (
                    <>
                      <h4>Записи:</h4>
                      {records.map((item) => (
                        <p>
                          <a
                            key={item.id}
                            href={getRecord(item.record_url)}
                            target="_blank"
                            style={{ wordWrap: "break-word", wordBreak: "break-all" }}
                          >
                            {item.record_name}
                          </a>
                        </p>
                      ))}
                    </>
                  )}
                </div>
              </div>
              <div className="">
                <h2>Резюме:</h2>
                <>
                  <h4>Образование:</h4>
                  {education?.length ? (
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Специальность</th>
                          <th>Дата выпуска</th>
                        </tr>
                      </thead>
                      <tbody>
                        {education.map((edu) => (
                          <tr key={edu.university}>
                            <td>{edu.speciality}</td>
                            <td>{edu.date}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <h6>Без образования</h6>
                  )}
                  <h4>Опыт работы:</h4>
                  <div style={{ fontSize: "20px", fontWeight: "600" }}>{apply.full_experience}</div>
                  <div style={{ fontSize: "16px", fontWeight: "500" }}>
                    Количество мест работы: {apply.experience_count}
                  </div>
                  <div style={{ fontSize: "16px", fontWeight: "500", marginBottom: "16px" }}>
                    Средняя продолжительность работы: {apply.experience_average} лет
                  </div>

                  {exp?.length ? (
                    <table className="table">
                      <thead>
                        <tr>
                          <th style={{ width: "20%" }}>Дата</th>
                          <th>Работа</th>
                        </tr>
                      </thead>
                      <tbody>
                        {exp.map((exp) => (
                          <tr key={exp.id}>
                            <td style={{ width: "20%" }}>
                              {exp.date}
                              <br />
                              <div style={{ color: "#888" }}>{exp.time}</div>
                            </td>
                            <td className="job-decription">
                              <p>
                                <strong>{exp.position}</strong>
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <h6>Без опыта работы</h6>
                  )}
                </>

                {!!characteristic?.length && (
                  <>
                    <h4>Характеристика рекрутера:</h4>
                    {characteristic?.map((item) => (
                      <div>
                        <span className="comment-recruter">{item.recruter}</span>
                        <Linkify key={item.id} componentDecorator={componentDecorator}>
                          <p className="comment-text" style={{ whiteSpace: "break-spaces" }}>
                            {item.characteristic_text}
                          </p>
                        </Linkify>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ApplyForSales;

var RequestInterviewModal = ({ setVisible, apply, link }) => {
  var { 0: requestInterviewStatus, 1: setRequestInterviewStatus } = useState(""); // OK preloader Error ""
  var { 0: formValidate, 1: setFormValidate } = useState({
    company: false,
    post: false,
    fullName: false,
    phone: false,
  });

  var disableSubmitBtn = !Object.values(formValidate).reduce((condition, value) => {
    return condition && value;
  }, true);

  var handleSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData(e.target);
    var data = {
      company: formData.get("company"),
      post: formData.get("post"),
      fullName: formData.get("fullName"),
      phone: formData.get("phone"),
    };
    requestInterview(data);
  };

  var requestInterview = (data) => {
    if (requestInterviewStatus) return;
    var data = { applyId: apply?.id, ...data };
    let url = variables.API_URL + "/api/request_interview/";
    setRequestInterviewStatus("preloder");
    axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 201) setRequestInterviewStatus("OK");
        else setRequestInterviewStatus("Error");
      })
      .catch(() => {
        setRequestInterviewStatus("Error");
      });
  };

  var handleClose = (e) => {
    setVisible((prev) => !prev);
  };

  var handleChangeInput = (e) => {
    var copyFormValidate = structuredClone(formValidate);
    if (!!e?.target?.value?.trim()?.length) {
      copyFormValidate[e.target.name] = true;
    } else {
      copyFormValidate[e.target.name] = false;
    }
    setFormValidate(copyFormValidate);
  };

  return (
    <Modal>
      <div
        className={"request_interview_modal"}
        // onClick={handleClose}
      >
        <form
          id="requestInterviewForm"
          onSubmit={handleSubmit}
          className="request_interview_modal_form"
          // onClick={(e) => {
          //   e.stopPropagation();
          //   e.preventDefault();
          // }}
        >
          <button onClick={handleClose} className="request_interview_modal_close_btn">
            ✖
          </button>
          <input
            name="company"
            type="text"
            placeholder="Кампания"
            className="request_interview_modal_form_input"
            onChange={handleChangeInput}
          />
          <input
            name="post"
            type="text"
            placeholder="Должность"
            className="request_interview_modal_form_input"
            onChange={handleChangeInput}
          />
          <input
            name="fullName"
            type="text"
            placeholder="ФИО"
            className="request_interview_modal_form_input"
            onChange={handleChangeInput}
          />
          <input
            name="phone"
            type="number"
            // pattern="\d{10}"
            placeholder="+7 (800) 555-35-35"
            // onChange={handlePhoneChange}
            className="request_interview_modal_form_input"
            onChange={handleChangeInput}
          />
          {/* <button>Запросить</button> */}
          {/* <input type="submit" value="Отправить" form="requestInterviewForm" /> */}
          <button
            // id="requestInterviewSubbmit"
            // type="submit"
            // form="myform"
            disabled={disableSubmitBtn || requestInterviewStatus}
            className={`request_interview_btn ${disableSubmitBtn || requestInterviewStatus ? "disable_btn" : ""}`}
            // className={`request_interview_btn`}
            // onClick={requestInterview}
            // style={requestInterviewStatus == "OK" ? { backgroundColor: "white", color: "#0d6efd" } : {}}
          >
            {!requestInterviewStatus && <span>Запросить интервью</span>}
            {requestInterviewStatus === "preloader" && <div className="preloader rot" />}
            {requestInterviewStatus === "OK" && <span>Запрос отправлен</span>}
            {requestInterviewStatus === "Error" && <span>Ошибка</span>}
          </button>
          {/* <label htmlFor="requestInterviewSubbmit"></label> */}
        </form>
      </div>
    </Modal>
  );
};
