import React, {useEffect, useState} from "react";
import LPRStatusService from "./LPRStatusService";

const lprStatusService = new LPRStatusService();

export default function SelectLPRStatus(props) {
    const [statuses, setStatuses] = useState([])

    useEffect(() => {
        lprStatusService.getStatuses().then(function (result) {
            setStatuses(result.results)
            props.setStatus(result.results.find(status => {
                return status.name === 'Текущий'
            }).id)
        });
    }, [])
    return (<>
            <select id={"30"} className="form-select" required={"True"} value={props.value} onChange={props.onChange} style={{marginTop: '5px'}}
                    defaultValue={""}>
                {/*<option disabled={props.disabledefault} key={"default"} value={""}>{"Статус ЛПР"}</option>*/}
                {statuses?.map(e =>
                    <option key={e.id} value={e.id}>{e.name}</option>
                )}
            </select>
            <label htmlFor={"30"}>{props.required && <span className={"text-danger"}> * </span>}Статус</label>
        </>
    )
}