import React, { useState, useEffect } from "react";
import "./Select.css";

const Select = ({
  options,
  placeholder,
  required,
  value,
  className,
  disabled,
  onChange,
  hasDescription,
}) => {
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (hasDescription) setDescription(options[0]?.description || "");
  }, [options, value]);

  return (
    <div className={className} style={{ position: "relative" }}>
      <select
        id={"30"}
        className="form-select"
        required={required}
        value={value}
        onChange={onChange}
        style={{ borderRadius: "0px", width: "100%" }}
        // defaultValue={""}
      >
        {placeholder && (
          <option disabled key={"default"} value={""}>
            {placeholder}
          </option>
        )}
        {options?.map((e, index) => (
          <option
            key={index}
            onClick={() => hasDescription && setDescription(e.description)}
            value={e.value}
          >
            {e.label}
          </option>
        ))}
      </select>
      {hasDescription && (
        <div className="modal-question-mark">
          <i className="fa fa-question-circle fa-lg"></i>
        </div>
      )}
      {description && (
        <div className=" type-description-abs type-description">
          {description}
        </div>
      )}
    </div>
  );
};

export default Select;
