import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import Pagination from "./pagination/Pagination";
import { variables } from "../Variables";
import axios from './Services/AxiosService';
import { useNavigate, NavLink  } from "react-router-dom";
import Select_1 from './Select.js'
import "./Vacancy.css"
import './Statistic/Statistic.css'
import "./VacancyStatistic/VacancyStatistic.css"
import { VacancyStatistic } from "./VacancyStatistic/VacancyStatistic"
import { useDispatch, useSelector } from "react-redux";
import { clearVacancyFilters, setVacancyFilter, setFullVacacnyFilter, setShowStat } from '../store/vacancyFilterSlice'
import { useSearchParams } from "react-router-dom";
import Select from 'react-select';
import InputOption from './InputOptions'
import { colors } from "./Colors"
import ModalCheckListConstructor from "./ModalCheckListConstructor";
import VacancyChangeModal from './VacancyChangeModal'


const ENTER_KEY = 13

export function Vacancy(props) {
    const navigate = useNavigate();
    const mounted = useRef();

    const [pageNumber, setPageNumber] = useState(1)
    const [vacancies, setVacancies] = useState([])
    const [vacAmount, setVacAmount] = useState('')
    const [totalCount, setTotalCount] = useState("")
    const [countSort, setCountSort] = useState(0)
    const [isEdit, setIsEdit] = useState(false)

    const [statusList, setStatusList] = useState([])
    const [stageList, setStageList] = useState([])
    const [companyOptions, setCompanyOptions] = useState([])

    const [vacancy, setVacancy] = useState(null)

    const [modalTitle, setModalTitle] = useState("");
    const [recruterOptions, setRecruterOptions] = useState([])
    const showStat = useSelector(state => state.vacancy_filter.showStat)

    const statusFilterList = useSelector(state => state.vacancy_filter.statusFilter)
    const stageFilterList = useSelector(state => state.vacancy_filter.stageFilter)
    const recruterFilterList = useSelector(state => state.vacancy_filter.recruterFilter)
    const companyFilterList = useSelector(state => state.vacancy_filter.companyFilter)
    const hasCandidateFilter = useSelector(state => state.vacancy_filter.hasCandidates)
    const [searchParams, setSearchParams] = useSearchParams()

    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [selectedRecruter, setSelectedRecruter] = useState([])

    const [modalCheckListConstructor, setShowModalCheckListConstructor] =
        useState({ show: false, vacancy: {} });

    const dispatch = useDispatch()

    const refreshStatusList = () => {
        axios.get(variables.API_URL + '/api/get_vacancy_status_list/', {
            headers: {
                "Accept": " application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${props.token}`
            },
        })
            .then(response => {
                let data = response.data;
                setStatusList(data);
            })
    }

    const refreshStageList = () => {
        axios.get(variables.API_URL + '/api/get_vacancy_stage_list/', {
            headers: {
                "Accept": " application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${props.token}`
            },
        })
            .then(response => {
                let data = response.data;
                setStageList(data);
            })
    }

    const refreshCompanyList = () => {
        axios.get(variables.API_URL + '/api/get_company_options/', {
            headers: {
                "Accept": " application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${props.token}`
            },
        })
            .then(response => {
                setCompanyOptions(response.data);
                if (searchParams.get('company')) {
                    let options = searchParams.get('company').split('-')
                    let selectedData = response.data.filter(item => options.includes(String(item.value)))
                    setSelectedCompanies(selectedData)
                }
            })
    }


    const refreshList = (orderCount = '0') => {
        let filter = ''
        if (statusFilterList.lenght != 0) filter += "?status_filter=" + statusFilterList.join(',')
        if (stageFilterList.lenght != 0) filter += "&&stage_filter=" + stageFilterList.join(',')
        if (recruterFilterList.lenght != 0) filter += "&&recruter_filter=" + recruterFilterList.join(',')
        if (companyFilterList.lenght != 0) filter += "&&company_filter=" + companyFilterList.join(',')
        if (hasCandidateFilter) filter += "&&has_candidate=" + hasCandidateFilter

        fetch(variables.API_URL + '/api/vacancy/' + filter, {
            headers: {
                "Accept": " application/json",
                "Content-Type": " application/json",
                Authorization: `Bearer ${props.token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setVacancies(data.data);
                setVacAmount(data.vacancy_amount)
                // setTotalCount(data.count);
            });
        setPageNumber(1)
    }

    const refreshRecruterList = () => {
        fetch(variables.API_URL + '/api/get_recruter_options/', {
            headers: {
                "Accept": " application/json",
                "Content-Type": " application/json",
                Authorization: `Bearer ${props.token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setRecruterOptions(data);
                if (searchParams.get('recruter')) {
                    let queryRecruterSet = searchParams.get('recruter').split('-')
                    setSelectedRecruter(data.filter(item => queryRecruterSet.includes(String(item.value))))
                }
            });
    }

    useLayoutEffect(() => {
        dispatch(clearVacancyFilters())
    }, [])


    useLayoutEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            refreshList()
        }
    }, [statusFilterList, recruterFilterList, companyFilterList, stageFilterList, hasCandidateFilter]);

    useLayoutEffect(() => {
        refreshStatusList()
        refreshStageList()
        refreshRecruterList()
        refreshCompanyList()
    }, [])

    useLayoutEffect(() => {
        dispatch(setFullVacacnyFilter({
            statusFilter: searchParams.get('status') ? searchParams.get('status').split('-').filter(item => item).map(num => Number(num)) : [1,],
            stageFilter: searchParams.get('stage') ? searchParams.get('stage').split('-').filter(item => item).map(num => Number(num)) : null,
            recruterFilter: searchParams.get('recruter') ? searchParams.get('recruter').split('-').filter(item => item).map(num => Number(num)) : null,
            companyFilter: searchParams.get('company') ? searchParams.get('company').split('-').filter(item => item) : null,
            hasCandidates: searchParams.get('hasCandidates') || 'no'
        }))
        if (searchParams.get('showStat')) dispatch(setShowStat({ showStat: searchParams.get('showStat') === "true" ? true : false }))
    }, [])

    // const getSectionsByURL = (link) => {
    //     const url = `${variables.API_URL + '/api/vacancy/'}${link}`;
    //     return axios.get(url, {
    //         headers: {
    //             Authorization: `Bearer ${props.token}`
    //         }
    //     }).then(response => response.data);
    // }


    // const changePage = (page) => {
    //     getSectionsByURL("?page=" + page.toString()).then((result) => {
    //         setVacancies(result.data);
    //         setVacAmount(result.vacancy_amount)
    //         setPageNumber(page)
    //     })
    // }

    const changeStatusFilter = (id) => {
        let prevParams = searchParams.get('status') || ''
        if (!prevParams.includes('-' + id.toString() + '-')) {
            searchParams.set('status', '-' + id.toString() + '-' + prevParams)
            setSearchParams(searchParams, { replace: true })
        }
        else {
            searchParams.set('status', prevParams.replace('-' + id.toString() + '-', ''))
            setSearchParams(searchParams, { replace: true })
        }

        dispatch(setVacancyFilter({ statusFilter: id }))
    }

    const changeStageFilter = (id) => {
        let prevParams = searchParams.get('stage') || ''
        if (!prevParams.includes('-' + id.toString() + '-')) {
            searchParams.set('stage', '-' + id.toString() + '-' + prevParams)
            setSearchParams(searchParams, { replace: true })
        }
        else {
            searchParams.set('stage', prevParams.replace('-' + id.toString() + '-', ''))
            setSearchParams(searchParams, { replace: true })
        }

        dispatch(setVacancyFilter({ stageFilter: id }))
    }

    const changeRecruterFilter = (options) => {
        setSelectedRecruter(options)
        options = options.map((item) => item.value)
        searchParams.set('recruter', options.join('-'))
        setSearchParams(searchParams, { replace: true })

        dispatch(setVacancyFilter({ recruterFilter: options }))
    }

    const changeCompanyFilter = (options) => {
        console.log(options)
        setSelectedCompanies(options)
        options = options.map((item) => item.value)
        searchParams.set('company', options.join('-'))
        setSearchParams(searchParams, { replace: true })

        dispatch(setVacancyFilter({ companyFilter: options }))
    }

    const changeHasCandidate = () => {
        if (hasCandidateFilter === 'yes') {
            dispatch(setVacancyFilter({ hasCandidates: 'no' }))
            searchParams.set('hasCandidates', 'no')
        } else {
            dispatch(setVacancyFilter({ hasCandidates: 'yes' }))
            searchParams.set('hasCandidates', 'yes')
        }
        setSearchParams(searchParams, { replace: true })
    }

    const changeShowStat = (bool) => {
        dispatch(
            setShowStat({
                showStat: bool
            })
        )

        searchParams.set('showStat', String(bool))
        setSearchParams(searchParams, { replace: true })
    }

    const addClick = () => {
        setModalTitle("Добавить Вакансию")
        setVacancy(null)
        setIsEdit(false)
    }

    const EditVacancy = (vacancy) => {
        setModalTitle('Изменить вакансию')
        setVacancy(vacancy)
        setIsEdit(true)
    }

    return (
        <div style={{ height: "100%", width: "100%" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h2 style={{ margin: "0", padding: "10px 10px 10px 0" }}>Вакансий: {vacAmount}</h2>
                    <div style={{ display: "flex" }}>
                        <div style={{ display: 'flex' }} className="m-2">
                            <button type="button" className={"btn " + (showStat ? "btn-primary" : "btn-outline-primary")}
                                onClick={() => changeShowStat(true)} style={{ borderRadius: "0" }}>
                                Таблица
                            </button>
                            <button type="button" className={"btn " + (showStat ? "btn-outline-primary" : "btn-primary")}
                                onClick={() => changeShowStat(false)} style={{ borderRadius: "0" }}>
                                Статистика
                            </button>
                        </div>
                        { props.customerId &&
                            <NavLink className="btn btn-primary m-2 float-end" to="/logout">
                                Выход
                            </NavLink>
                        }
                        {props.isSuperUser.toString() === '2' &&
                            <button type="button" className="btn btn-primary m-2 float-end"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={() => addClick()}>
                                Добавить Вакансию
                            </button>
                        }
                    </div>
                </div>

            <div className="vacancy-stat-container">
                {showStat ?
                    <div className="vacancy-table-container" style={{ width: '100%' }}>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Название вакансии</th>
                                    <th>Название компании</th>
                                    <th>Количество откликов</th>
                                    <th>Стадия</th>
                                    <th>Статус</th>
                                    <th>Ответственный рекрутер</th>
                                    {props.isSuperUser.toString() === '2' ? <th></th> : null}
                                </tr>
                            </thead>
                            <tbody>
                                {vacancies.map(vacancy =>
                                    <tr key={vacancy.id}>
                                        <td>{vacancy.id}</td>
                                        <td className="vacancy-navigate" onClick={() => { navigate('/vacancy/' + vacancy.id) }}>{vacancy.name}</td>
                                        <td>{vacancy.company.name}</td>
                                        <td>{vacancy.apply_in_progress}/{vacancy.apply_sum}</td>
                                        <td>{vacancy.stage_name}</td>
                                        <td>{vacancy.status_name}</td>
                                        <td>{vacancy.recruter_name}</td>
                                        {props.isSuperUser.toString() === '2' ?
                                        <>
                                        <td>
                                            <button
                                                type="button"
                                                className="btn btn-default"
                                                onClick={() => {
                                                    setShowModalCheckListConstructor({
                                                        ...modalCheckListConstructor,
                                                        show: true,
                                                        vacancy: vacancy,
                                                    });
                                                }}
                                            >
                                                <i className="fa fa-list-alt" aria-hidden="true" />
                                            </button>
                                        </td>
                                        <td><button type="button" className="btn btn-default"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            onClick={() => EditVacancy(vacancy)}>
                                            <i className="fa fa-pencil" aria-hidden="true" /></button>
                                        </td>
                                        </> : null}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    :
                    <div style={{ width: '100%' }}>
                        <VacancyStatistic token={props.token} recruterOptions={recruterOptions} stageList={stageList} EditVacancy={EditVacancy} vacancies={vacancies} customerId={props.customerId} isSuperUser={props.isSuperUser}/>
                    </div>
                }
                <div className="check-options">
                    <h4>Статус</h4>
                    {statusList.map((item) =>
                        <div className="mb-3" style={{ display: "flex" }} key={item.value + 1231}>
                            <input id={item.value + 1231} type="checkbox" style={{ accentColor: colors.status[item.value - 1] }} className="chackbox-vacancy" checked={statusFilterList.includes(item.value)} onChange={() => changeStatusFilter(item.value)} />
                            <label className="chackbox-vacancy-label" htmlFor={item.value + 1231}>{item.label}</label>
                        </div>
                    )}

                    <h4>Стадия</h4>
                    {stageList.map((item) =>
                        <div className="mb-3" style={{ display: "flex" }} key={item.value + 1231}>
                            <input id={item.value + 1231} type="checkbox" style={{ accentColor: colors.stage[item.value - 1] }} className="chackbox-vacancy" checked={stageFilterList.includes(item.value)} onChange={() => changeStageFilter(item.value)} />
                            <label className="chackbox-vacancy-label" htmlFor={item.value + 1231}>{item.label}</label>
                        </div>
                    )}
                    { !props.customerId &&
                    <div>
                    <h4 className="mb-3">Рекрутер</h4>
                    <Select
                        className="mb-3"
                        components={{
                            Option: InputOption
                        }}
                        value={selectedRecruter}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        options={recruterOptions}
                        onChange={(options) => changeRecruterFilter(options)}
                        placeholder='Рекрутер...'
                    />

                    <h4 className="mb-3">Компания</h4>
                    <Select
                        className="mb-3"
                        components={{
                            Option: InputOption
                        }}
                        value={selectedCompanies}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        options={companyOptions}
                        onChange={(options) => changeCompanyFilter(options)}
                        placeholder='Компания...'
                    />

                    <div className="mb-3" style={{ display: "flex" }}>
                            <input id={3221} type="checkbox" style={{ accentColor: colors.stage[0] }} className="chackbox-vacancy" checked={hasCandidateFilter === 'yes'} onChange={changeHasCandidate} />
                            <label className="chackbox-vacancy-label" htmlFor={3221}>Есть кандидаты</label>
                        </div>
                    </div>
                    }  

                </div>


            </div>
            {/*<div className="text-center">*/}
            {/*    <Pagination*/}
            {/*        className="pagination-bar"*/}
            {/*        currentPage={pageNumber}*/}
            {/*        totalCount={totalCount}*/}
            {/*        pageSize={10}*/}
            {/*        onPageChange={page => changePage(page)}*/}
            {/*    />*/}
            {/*</div>*/}

            <VacancyChangeModal modalTitle={modalTitle} vacancy={vacancy} isEdit={isEdit} recruterOptions={recruterOptions}
             statusList={statusList} stageList={stageList} token={props.token} refreshList={refreshList} 
            />


            {modalCheckListConstructor.show ? (
                <ModalCheckListConstructor
                    token={props.token}
                    vacancy={modalCheckListConstructor.vacancy}
                    isOpened={modalCheckListConstructor.show}
                    onModelClose={() =>
                        setShowModalCheckListConstructor({
                            ...modalCheckListConstructor,
                            show: false,
                        })
                    }
                ></ModalCheckListConstructor>
            ) : (
                <div></div>
            )}
        </div>
    )
}