import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { variables } from "../../Variables";
import { ApplyForCustomer } from "../ApplyForCustomer";
import { saveTokenData } from "../Services/LoginService";
import "./Login.css";

async function loginUser(credentials) {
  return fetch(variables.API_URL + "/api/login/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export default function Login({}) {
  var { 0: username, 1: setUserName } = useState("");
  var { 0: password, 1: setPassword } = useState("");
  var { 0: isCustomer, 1: setIsCustomer } = useState(false);
  var { 0: loader, 1: setLoader } = useState(false);

  var usernameInputRef = useRef(null);

  useEffect(() => {
    var url = new URL(window.location.href);
    if (url.pathname === "/sended_apply/") {
      setIsCustomer(true);
    }
  }, []);

  useEffect(() => {
    usernameInputRef?.current?.focus();
  }, []);

  var userNameHandler = (e) => {
    setUserName(e?.target?.value || "");
  };

  var passwordHandler = (e) => {
    setPassword(e?.target?.value || "");
  };

  var handleSubmit = async (e) => {
    setLoader(true);

    loginUser({
      username,
      password,
    })
      .then((response) => saveTokenData(response.token))
      .finally(() => {
        setLoader(false);
      });
  };

  var pressEnterHandler = (e) => {
    if (e?.keyCode === 13) {
      e?.target?.nextSibling?.focus();
    }
  };

  if (isCustomer) {
    return <ApplyForCustomer />;
  }

  return (
    <div id="login-wrapper" className="login-wrapper">
      <div className="login">
        <h1>Вход</h1>
        <div className="login_body">
          <input
            type="text"
            placeholder="Логин"
            value={username}
            onChange={userNameHandler}
            onKeyDown={pressEnterHandler}
            ref={usernameInputRef}
          />
          <input
            type="password"
            placeholder="Пароль"
            value={password}
            onChange={passwordHandler}
            onKeyDown={pressEnterHandler}
          />
          {/* <Icon name={"eye"} className={"icon_eye"} /> */}
          <button disabled={!(username?.length && password?.length) || loader} onClick={handleSubmit}>
            {loader ? <div className="preloader rot" /> : "Войти"}
          </button>
          {/* {!!error && <span className="error_message">{error}</span>} */}
          {/* <span>
            Для получения уведомлений начните чат с телеграм ботом{" "}
            <a href="https://t.me/RecrutoCRMBot" target="_blank">
              @RecrutoCRMBot
            </a>
          </span> */}
        </div>
      </div>
    </div>
  );
}
