import React, { useEffect, useState } from "react";
import { variables } from "../Variables";
import "react-phone-input-2/lib/bootstrap.css";
import "../App.css";
import LPRService from "./LPRService";
import axios from "./Services/AxiosService";
import { useSelector } from "react-redux";
import Linkify from "react-linkify";
import { useNavigate } from "react-router-dom";

// new
export function ApplyForCustomer(props) {
  const link = new URLSearchParams(window.location.search).get("link");
  const user = useSelector((state) => state.user);
  const windowWidth = useSelector((state) => state.window.width);

  const [error, setError] = useState(false);
  const [isOtOfDate, setIsOutOfDate] = useState(false);

  const [apply, setApply] = useState([]);
  const [exp, setExp] = useState([]);
  const [characteristic, setCharacteristic] = useState([]);
  const [education, setEducation] = useState([]);
  const [photo, setPhoto] = useState();
  const [video, setVideo] = useState([]);
  const [audio, setAudio] = useState([]);
  const [socialNetworks, setSocialNetworks] = useState([]);
  const [imagesGallery, setImagesGallery] = useState([]);
  const [records, setRecords] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [applyTags, setApplyTags] = useState([]);
  const [videoTags, setVideoTags] = useState([]);
  const navigate = useNavigate();

  const getApply = async () => {
    if (link) {
      let params = "?link=" + link;
      let url = variables.API_URL + "/api/get_apply_id_by_link/";
      axios
        .get(url + params, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((data) => {
          setApply(data.data.apply_data);
          setImagesGallery(data.data.images_data);
          setSocialNetworks(data.data.apply_data.social_networks);
          setCharacteristic(data.data.apply_data.characteristics);
          setEducation(data.data.apply_data.education_set);
          setExp(data.data.apply_data.experience_set);
          setPhoto(data.data.apply_data.photo);
          setVideo(data.data.video_data.videos);
          setAudio(data.data.audio_data.feedbacks);
          setRecords(data.data.record_data.records);
          setDocuments(data.data.documents_data);
          setApplyTags(data.data.tag_apply);
          setVideoTags(data.data.video_data.videos.map((obj) => obj.tags));
          if (user.token) navigate("/apply/" + data?.data?.apply_data?.id);
        })
        .catch((error) => {
          setError(true);
          if (error.request.status.toString() === "406") setIsOutOfDate(true);
        });
    }
  };

  const getImage = (image, folder) => {
    if (image) {
      if (folder) {
        return variables.API_URL + "/media/" + folder + "/" + image.split("/").pop();
      } else {
        return variables.API_URL + "/media/" + image.split("/").pop();
      }
    }
  };

  useEffect(() => {
    getApply();
  }, []);

  const getFile = (document) => {
    if (document) {
      // window.open(variables.API_URL + "/media/media/documents/" + document.split("/").pop(), "_blank");
      window.open(variables.API_URL + document, "_blank");
    }
  };
  const getVideo = (video_url) => {
    if (video_url) {
      return variables.API_URL + "/get_video/" + video_url;
    }
  };

  const getAudio = (audio_url) => {
    if (audio_url) {
      return variables.API_URL + "/get_audio/" + audio_url;
    }
  };

  const getRecord = (audio_url) => {
    if (audio_url) {
      return variables.API_URL + "/get_record/" + audio_url;
    }
  };

  if (error || isOtOfDate) {
    return <div>{isOtOfDate ? <h2>Ссылка устарела</h2> : <h2>Неверная ссылка</h2>}</div>;
  }

  const componentDecorator = (href, text, key) => (
    <a className="linkify__text" href={href} key={key} target="_blank">
      {text}
    </a>
  );

  return (
    <div className="App container">
      <div className="mt-4" style={{ textAlign: "left" }}>
        <div className="row is_mobile">
          <div className="col_5">
            <div className="page-head p-5 bg-light border rounded-3 mb-3">
              <h3 className="mb-3">{apply.name}</h3>
              {photo ? (
                <img style={{ width: "80%", marginLeft: "0.5rem" }} src={getImage(photo)} alt="Profile" />
              ) : null}
              <h4 className="mb-0 mt-2">{apply.position}</h4>
              <p className="mb-3">{apply.age}</p>
              <ul className="list-unstyled">
                <li>
                  <b>Статус: </b>
                  {apply.status}
                </li>
                {apply?.reject_reasons && (
                  <li>
                    <b>Причины отказа: </b>
                    {apply.reject_reasons}
                  </li>
                )}
                {/* <li>
                  <b>Рекрутер: </b>
                  {apply.rec_account}
                </li> */}
                <li>
                  <b>Город: </b>
                  {apply?.city} {apply?.relocate_trips && ", " + apply.relocate_trips}
                </li>
                {apply?.metro && (
                  <li>
                    <b>Метро: </b>
                    {apply.metro}
                  </li>
                )}
                <li>
                  <b>Зарплата: </b>
                  {apply.salary}
                </li>
                <li>
                  <b>Специализация: </b>
                  {apply.specializations}
                </li>
                <li>
                  <b>Занятость: </b>
                  {apply.employment}
                </li>
                {apply?.languages && (
                  <li style={{ whiteSpace: "break-spaces" }}>
                    <b>Языки: </b>
                    {apply?.languages.replaceAll("     ", "\n")}
                  </li>
                )}
                {socialNetworks.length ? (
                  <li>
                    {socialNetworks.map((item) => {
                      return (
                        <ul className="list-unstyled" key={item.id}>
                          <li>
                            <b>{item.name}: </b>
                            <a href={item.link} target="_blank">
                              Ссылка
                            </a>
                          </li>
                        </ul>
                      );
                    })}
                  </li>
                ) : null}
              </ul>
            </div>

            <div>
              {windowWidth >= 701 && video?.length ? (
                <>
                  <h4>Видео:</h4>
                  {video.map((item, index) => (
                    <div className="bottom-border-div">
                      <div className="apply_tags_additional_area">
                        {videoTags[index].map((item, index) => (
                          <a className="btn btn-outline-primary active" key={index}>
                            {"#" + item.tag}
                          </a>
                        ))}
                      </div>
                      <a href={getVideo(item.video_url)} target="_blank">
                        <img
                          style={{ width: "100%", cursor: "pointer" }}
                          src={getImage(item.miniature)}
                          alt="Profile"
                        />
                      </a>
                      <p
                        style={{
                          wordWrap: "break-word",
                          wordBreak: "break-all",
                        }}
                      >
                        <a href={getVideo(item.video_url)} target="_blank">
                          Сслыка
                        </a>
                      </p>
                    </div>
                  ))}
                </>
              ) : null}
              {windowWidth >= 701 && audio?.length ? (
                <>
                  <h4>Обратная связь:</h4>
                  {audio.map((item) => (
                    <p>
                      <a
                        key={item.id}
                        href={getAudio(item.audio_url)}
                        target="_blank"
                        style={{
                          wordWrap: "break-word",
                          wordBreak: "break-all",
                        }}
                      >
                        {item.parent_audio_name.replace(/\d{9,12}/g, "")}
                      </a>
                    </p>
                  ))}
                </>
              ) : null}
              {windowWidth >= 701 && !!records?.length ? (
                <>
                  <h4>Записи:</h4>
                  {records.map((item) => (
                    <p>
                      <a
                        key={item.id}
                        href={getRecord(item.record_url)}
                        target="_blank"
                        style={{ wordWrap: "break-word", wordBreak: "break-all" }}
                      >
                        {item.record_name}
                      </a>
                    </p>
                  ))}
                </>
              ) : null}
            </div>
          </div>
          <div className="col_7">
            <div className="candidate-header">
              {/* <div className={"d-flex justify-content-end apply_button"}>
                <h4 className="mt-1 text-center">Соответствие:</h4>
                <h4 className="mt-1 text-center check_list_score">{apply.percentage_compliance}%</h4>
              </div> */}

              <h4>Характеристика рекрутера:</h4>
              {characteristic?.length ? (
                characteristic?.map((item) => (
                  <div>
                    <span className="comment-recruter">{item.recruter}</span>
                    <Linkify key={item.id} componentDecorator={componentDecorator}>
                      <p className="comment-text" style={{ whiteSpace: "break-spaces" }}>
                        {item.characteristic_text}
                      </p>
                    </Linkify>
                  </div>
                ))
              ) : (
                <h6>Нет характеристики</h6>
              )}
            </div>
            {apply?.letter && (
              <div className="candidate-header">
                <h4>Сопроводительное письмо:</h4>
                <p style={{ whiteSpace: "break-spaces" }}>{apply?.letter?.replaceAll("<br/>", "\n")}</p>
              </div>
            )}
            {imagesGallery.length ? (
              <>
                <h4>Изображения:</h4>
                <div className="image_area">
                  {imagesGallery.map((item, index) => {
                    return (
                      <div className="image_miniature_wrapper" key={item.id}>
                        <a href={getImage(item.image, "apply_images")} target="_blank">
                          <img className="image_miniature" src={getImage(item.image, "apply_images")} alt=""></img>
                        </a>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <></>
            )}

            {!!documents.length > 0 && (
              <>
                <h4>Документы:</h4>
                <table className="table mb-5" style={{ width: "100%", verticalAlign: "middle" }}>
                  <thead>
                    <tr>
                      <th style={{ width: "15%" }}>Файл</th>
                      <th style={{ width: "40%" }}>Название</th>
                      <th style={{ width: "20%" }}>Автор</th>
                      <th>Дата добавления</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map((document) => {
                      return (
                        <tr key={document.id}>
                          <td>
                            <i
                              onClick={() => getFile(document.document)}
                              className="fa fa-file-o fa-2x file_icon"
                              style={{ marginLeft: "5px" }}
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>{document.filename}</td>
                          <td>{document.user}</td>
                          <td>{document.date}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}

            <h2>Резюме:</h2>
            <div className="apply_tags_additional_area">
              {applyTags.map((obj, index) => (
                <a key={index} className="btn btn-outline-primary active">
                  {"#" + obj.tag}
                </a>
              ))}
            </div>
            <h4>О себе:</h4>
            <p style={{ whiteSpace: "break-spaces" }}>{apply?.about?.replaceAll("<br/>", "\n")}</p>
            {windowWidth > 701 ? (
              <>
                <h4>Образование:</h4>
                {education?.length ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Университет</th>
                        <th>Специальность</th>
                        <th>Дата выпуска</th>
                      </tr>
                    </thead>
                    <tbody>
                      {education.map((edu) => (
                        <tr key={edu.university}>
                          <td>{edu.university}</td>
                          <td>{edu.speciality}</td>
                          <td>{edu.date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <h6>Без образования</h6>
                )}
                <h4>Опыт работы:</h4>
                <div style={{ fontSize: "20px", fontWeight: "600" }}>{apply.full_experience}</div>
                <div style={{ fontSize: "16px", fontWeight: "500" }}>
                  Количество мест работы: {apply.experience_count}
                </div>
                <div style={{ fontSize: "16px", fontWeight: "500", marginBottom: "16px" }}>
                  Средняя продолжительность работы: {apply.experience_average} лет
                </div>

                {exp?.length ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: "20%" }}>Дата</th>
                        <th>Работа</th>
                      </tr>
                    </thead>
                    <tbody>
                      {exp.map((exp) => (
                        <tr key={exp.company}>
                          <td style={{ width: "20%" }}>
                            {exp.date}
                            <br />
                            <div style={{ color: "#888" }}>{exp.time}</div>
                          </td>
                          <td className="job-decription">
                            <p>
                              <strong>{exp.company}</strong>
                            </p>
                            {/* {!!exp.company_url && (
                              <a href={exp.company_url} target="_blank">
                                {exp.company_url}
                              </a>
                            )} */}
                            <p>{exp.company_description}</p>
                            <br />
                            <p>
                              <strong>{exp.position}</strong>
                            </p>
                            <br />
                            <p style={{ whiteSpace: "pre-wrap" }}>{exp.description.replaceAll("<br/>", "\n")}</p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <h6>Без опыта работы</h6>
                )}
              </>
            ) : (
              <>
                <h4>Образование:</h4>
                {education?.length ? (
                  education.map((edu) => (
                    <div key={edu.university} className="job-decription job-decription_mobile">
                      <p>
                        <strong>{edu.university}</strong>
                      </p>
                      <br />
                      <p>Год выпуска: {edu.date}</p>
                      <br />
                      <p>{edu.speciality}</p>
                    </div>
                  ))
                ) : (
                  <h6>Без образования</h6>
                )}
                <h4>Опыт работы:</h4>
                <div style={{ fontSize: "20px", fontWeight: "600" }}>{apply.full_experience}</div>
                <div style={{ fontSize: "16px", fontWeight: "500" }}>
                  Количество мест работы: {apply.experience_count}
                </div>
                <div style={{ fontSize: "16px", fontWeight: "500", marginBottom: "16px" }}>
                  Средняя продолжительность работы: {apply.experience_average} лет
                </div>

                {exp?.length ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: "20%" }}>Дата</th>
                        <th>Работа</th>
                      </tr>
                    </thead>
                    <tbody>
                      {exp.map((exp) => (
                        <tr key={exp.company}>
                          <td style={{ width: "20%" }}>
                            {exp.date}
                            <br />
                            <div style={{ color: "#888" }}>{exp.time}</div>
                          </td>
                          <td className="job-decription">
                            <p>
                              <strong>{exp.company}</strong>
                            </p>
                            {/* {!!exp.company_url && (
                              <a href={exp.company_url} target="_blank">
                                {exp.company_url}
                              </a>
                            )} */}
                            <p>{exp.company_description}</p>
                            <br />
                            <p>
                              <strong>{exp.position}</strong>
                            </p>
                            <br />
                            <p style={{ whiteSpace: "pre-wrap" }}>{exp.description.replaceAll("<br/>", "\n")}</p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <h6>Без опыта работы</h6>
                )}
              </>
            )}
          </div>
        </div>

        {windowWidth < 701 && video?.length ? (
          <>
            <h4>Видео:</h4>
            {video.map((item) => (
              <div>
                <a href={getVideo(item.video_url)} target="_blank">
                  <img style={{ width: "100%", cursor: "pointer" }} src={getImage(item.miniature)} alt="Profile" />
                </a>
                <p style={{ wordWrap: "break-word", wordBreak: "break-all" }}>
                  <a href={getVideo(item.video_url)} target="_blank">
                    Ссылка
                  </a>
                </p>
              </div>
            ))}
          </>
        ) : null}
        {windowWidth < 701 && audio?.length ? (
          <>
            <h4>Обратная связь:</h4>
            {audio.map((item) => (
              <p>
                <a
                  key={item.id}
                  href={getAudio(item.audio_url)}
                  target="_blank"
                  style={{ wordWrap: "break-word", wordBreak: "break-all" }}
                >
                  {item.parent_audio_name.replace(/\d{9,12}/g, "")}
                </a>
              </p>
            ))}
          </>
        ) : null}
        {windowWidth < 701 && records?.length ? (
          <>
            <h4>Записи:</h4>
            {records.map((item) => (
              <p>
                <a
                  key={item.id}
                  href={getRecord(item.record_url)}
                  target="_blank"
                  style={{ wordWrap: "break-word", wordBreak: "break-all" }}
                >
                  {item.record_name}
                </a>
              </p>
            ))}
          </>
        ) : null}
      </div>
    </div>
  );
}
