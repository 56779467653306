let stage = [
    '#00bbd3',
    '#02a8f3',
    '#0187d0',
    '#3e50b4',
    '#9b26af',
    '#ffffff',
]

let status = [
    '#3e50b4',
    '#f34235',
    '#fe9700',
    '#feea3a',
    '#ccdb38',
    '#8ac249',
    '#4bae4f',
    '#fe5621',
    '#fe5621',
    '#fe9700',
]

let action = [
    '#ccdb38',
    '#8ac249',
    '#4bae4f',
    '#009587',
    '#00bbd3',
    '#2095f2',
    '#3e50b4',
    '#9b26af',
    '#f34235',
    '#fe9700',
    '#feea3a',
    '#717171',
]

let stageByName = {
    "Заявка": '#00bbd3',
    "Бриф": '#02a8f3',
    "Проект + КП": '#0187d0',
    "Поиск": '#3e50b4',
    "Поиск (новые вводные)": '#9b26af',
    "Стоп": '#969696',
}

let recruter = {
    'Novozhilova': '#9b26af',
    'myamlin': '#717171',
    'Globa' :'#009587',
    'nikolaev.kirill.v': '#8ac249',
    'yazynina': '#4bae4f',
    'nikashkina': '#0187d0',
    'psarevadarya': '#fe9700',
    'zhdanova': '#f34235',
    'mashanova' :'#3e50b4',
    'bolshutkina': '#fe5621',
    'Malinina': '#ccdb38',
    'notarius': '#d526a6',
    'goldobina': '#fec006',
    'Tomasheva' :'#2095f2',
}

let candidateStatus = {
    'Передан заказчику': '#d526a6',
    'Выход на стажировку': '#ccdb38',
    'Испытательный срок': '#8ac249',
    'Успех': '#4bae4f',
    'Не прошёл стажировку': '#fe9700',
    'Не прошёл испытательный срок': '#f34235',
    'Уволен вскоре после ИС': '#fec006'
}

export const colors={
    stage,
    status,
    action,
    stageByName,
    recruter,
    candidateStatus
}