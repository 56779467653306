import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { variables } from "../Variables";
import "./ModalCheckListConstructor.css";

const ModalCheckListConstructor = (props) => {
  const [template, setTemplate] = useState({
    id: -1,
    name: String(props.vacancy.id) + " " + props.vacancy.name + " " + props.vacancy.company.name,
    vacancy: props.vacancy.id,
    sections: [],
  });

  const [templates, setTemplates] = useState([{ pk: -1, name: "Новый шаблон" }]);

  // const [blockSave, setBlocksave] = useState(false);

  const checkTemplate = async () => {
    for (var section of template.sections) {
      if (!section.validName) return;
      for (var question of section.questions) {
        if (!question.validQuestion) return;
        if ((question.type === "range") | (question.type === "test")) {
          if (!question.rangeValid) return;
        }
      }
    }
    return true;
  };

  const addValidTrue = async (data) => {
    for (var section of data?.sections) {
      section.validName = true;
      for (var question of section?.questions) {
        question.validQuestion = true;
        if ((question?.type === "range") | (question?.type === "test")) {
          question.rangeValid = true;
        }
      }
    }
  };

  const switchTemplate = async (index_template) => {
    if (index_template === 0) return;
    const pk = templates[index_template].pk;
    const response = await fetch(variables.API_URL + "/api/checklist/template/" + pk, {
      method: "GET",
      headers: {
        Accept: " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${props.token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      await addValidTrue(data);
      setTemplate({ ...template, sections: data.sections });
    }
  };

  const templateValid = async () => {
    var newTemplate = template;
    for (var section of newTemplate.sections) {
      delete section.validName;
      for (var question of section.questions) {
        delete question.validQuestion;
        delete question.rangeValid;
      }
    }
  };

  const closeModalCheckListConstructor = async () => {
    const checkTemplateFlag = await checkTemplate();
    if (checkTemplateFlag) {
      await templateValid();
      props.onModelClose(false);
      if (template.id !== -1) {
        if (template.sections.length > 0) {
          fetchPatchCheckListTemplate();
        } else {
          fetchDeleteCheckListTemplate();
        }
      } else if (template.sections.length) {
        fetchPostCheckListTemplate();
      }
    }
  };

  const [curTemplateName, setCurTemplateName] = useState("");

  const fetchGetAllCheckListTemplate = async () => {
    const response = await fetch(variables.API_URL + "/api/checklist/template/all", {
      method: "GET",
      headers: {
        Accept: " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${props.token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      setTemplates([...templates, ...data]);
    }
  };

  const fetchGetCheckListTemplate = async () => {
    const response = await fetch(variables.API_URL + "/api/checklist/template/?vacancy=" + props.vacancy.id, {
      method: "GET",
      headers: {
        Accept: " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${props.token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();

      await addValidTrue(data);
      setTemplate(data);
    }
  };

  const fetchPatchCheckListTemplate = async () => {
    const response = await fetch(variables.API_URL + "/api/checklist/template/?vacancy=" + props.vacancy.id, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      body: JSON.stringify(template),
    });
  };

  const fetchPostCheckListTemplate = async () => {
    const response = await fetch(variables.API_URL + "/api/checklist/template/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      body: JSON.stringify(template),
    });
  };

  const fetchDeleteCheckListTemplate = async () => {
    const response = await fetch(variables.API_URL + "/api/checklist/template/?vacancy=" + props.vacancy.id, {
      method: "DELETE",
      headers: {
        Accept: " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${props.token}`,
      },
    });
  };

  const addSection = () => {
    var newSections = template.sections;
    newSections.push({
      id: -1,
      questions: [],
      name: "",
      template: template.id,
    });
    setTemplate({ ...template, sections: [...newSections] });
  };

  const delSection = (event, index_section) => {
    var newSections = template.sections;
    newSections.splice(index_section, 1);
    setTemplate({ ...template, sections: [...newSections] });
  };

  const editSectionName = (event, index_section) => {
    const newSectionName = event.target.value;
    var newSections = template.sections;
    newSections[index_section].name = newSectionName;
    setTemplate({ ...template, sections: [...newSections] });
    newSectionName.trim() === ""
      ? (newSections[index_section].validName = false)
      : (newSections[index_section].validName = true);
  };

  const editQuestionType = (event, index_section, index_question) => {
    const new_type = event.target.value;
    var newSections = template.sections;
    var question = newSections[index_section].questions[index_question];

    if (new_type === "boolean") {
      question.rangeValid = true;
    } else if ((new_type === "range") | (new_type === "test")) {
      question.min_value > question.max_value ? (question.rangeValid = false) : (question.rangeValid = true);
    }

    question.type = new_type;
    setTemplate({ ...template, sections: [...newSections] });
  };

  const editQuestion = (event, index_section, index_question) => {
    var newSections = template.sections;
    const new_question = event.target.value;
    var question = newSections[index_section].questions[index_question];
    question.question = new_question;
    question.question === "" ? (question.validQuestion = false) : (question.validQuestion = true);
    setTemplate({
      ...template,
      sections: [...newSections],
    });
  };

  const addQuestion = (event, index_section) => {
    var newSections = template.sections;
    newSections[index_section].questions.push({
      id: -1,
      type: "boolean",
      question: "",
      max_value: 0,
      min_value: 0,
      validQuestion: false,
    });
    setTemplate({ ...template, sections: [...newSections] });
  };

  const delQuestion = (event, index_section, index_question) => {
    var newSections = template.sections;
    newSections[index_section].questions.splice(index_question, 1);
    setTemplate({ ...template, sections: [...newSections] });
  };

  const editRangeQuestionMinValue = (event, index_section, index_question) => {
    var newSections = template.sections;
    var question = newSections[index_section].questions[index_question];
    var new_min_value = event.target.value;

    if (
      Boolean(new_min_value.match(/\./)) |
      (Number(new_min_value) > Number(question.max_value)) |
      (new_min_value === "")
    ) {
      question.rangeValid = false;
    } else {
      question.rangeValid = true;
    }
    // if (new_min_value > question.max_value) {
    //   return;
    // }
    question.min_value = new_min_value;
    setTemplate({ ...template, sections: [...newSections] });
    // }
  };

  const editRangeQuestionMaxValue = (event, index_section, index_question) => {
    var newSections = template.sections;
    var question = newSections[index_section].questions[index_question];
    var new_max_value = event.target.value;

    if (
      Boolean(new_max_value.match(/\./)) |
      (Number(new_max_value) < Number(question.min_value)) |
      (new_max_value === "")
    ) {
      question.rangeValid = false;
    } else {
      question.rangeValid = true;
    }

    question.max_value = new_max_value;
    setTemplate({ ...template, sections: [...newSections] });
  };

  useEffect(() => {
    fetchGetCheckListTemplate();
    fetchGetAllCheckListTemplate();
  }, []);

  return (
    <div className={"container modal-content rounded-5 shadow mt-0"}>
      <Modal show={props.isOpened} onHide={props.onModelClose} className="modal fade modal-check-list-constructor">
        <Modal.Header closeButton>
          <Modal.Title>
            <div>{template.name}</div>
            <div className="template_selector_title">
              Шаблон:
              <select
                className="select_question_type template_selector"
                value={curTemplateName}
                onChange={(event) => {
                  switchTemplate(event.target.value);
                  setCurTemplateName(event.target.value);
                }}
              >
                {templates.map((template, index_template) => (
                  <option value={index_template}>{template.name}</option>
                ))}
              </select>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* SECTION */}
          {template.sections.map((section, index_section) => (
            <div className="section" key={index_section}>
              <div className="section_head">
                <i className="fa fa-bars fa-2x dnd_bar" aria-hidden="true"></i>
                <input
                  type="text"
                  // className="section_input"
                  className={section.validName ? "section_input" : "section_input input_error"}
                  placeholder="Новый раздел"
                  value={section.name}
                  onChange={(event) => {
                    editSectionName(event, index_section);
                  }}
                />
                <button
                  className="btn_checklist"
                  onClick={(event) => {
                    delSection(event, index_section);
                  }}
                >
                  <i className="fa fa-minus" aria-hidden="true"></i>
                </button>
              </div>
              <hr className="hr_line" />
              <div className="section_body">
                {section?.questions?.map((question, index_question) => {
                  if (question.type === "boolean") {
                    return (
                      <div>
                        <div className="question" key={index_question}>
                          <i className="fa fa-bars fa-2x dnd_bar" aria-hidden="true"></i>
                          <div className="options">
                            <div className="options_head">
                              <input
                                type="text"
                                // className="question_input"
                                className={question.validQuestion ? "question_input" : "question_input input_error"}
                                placeholder="Новый вопрос"
                                value={question.question}
                                onChange={(event) => {
                                  editQuestion(event, index_section, index_question);
                                }}
                              />
                            </div>
                            <div className="options_body">
                              <select
                                className="select_question_type"
                                value={question.type}
                                onChange={(event) => {
                                  editQuestionType(event, index_section, index_question);
                                }}
                              >
                                <option value="boolean">Да/Нет</option>
                                <option value="range">Диапазон</option>
                                <option value="test">Тестовое</option>
                              </select>
                              <input
                                type="number"
                                className="input_numbers"
                                value={question.max_value}
                                onChange={(event) => {
                                  editRangeQuestionMaxValue(event, index_section, index_question);
                                }}
                              />
                            </div>
                          </div>
                          <button
                            className="btn_checklist"
                            onClick={(event) => {
                              delQuestion(event, index_section, index_question);
                            }}
                          >
                            <i className="fa fa-minus" aria-hidden="true"></i>
                          </button>
                        </div>
                        <hr className="hr_line" />
                      </div>
                    );
                  } else if ((question.type === "range") | (question.type === "test")) {
                    return (
                      <div>
                        <div className="question">
                          <i className="fa fa-bars fa-2x dnd_bar" aria-hidden="true"></i>
                          <div className="options">
                            <div className="options_head">
                              <input
                                type="text"
                                // className="question_input"
                                className={question.validQuestion ? "question_input" : "question_input input_error"}
                                placeholder="Новый вопрос"
                                value={question.question}
                                onChange={(event) => {
                                  editQuestion(event, index_section, index_question);
                                }}
                              />
                            </div>
                            <div className="options_body">
                              <select
                                className="select_question_type"
                                value={question.type}
                                onChange={(event) => {
                                  editQuestionType(event, index_section, index_question);
                                }}
                              >
                                <option value="boolean">Да/Нет</option>
                                <option value="range">Диапазон</option>
                                <option value="test">Тестовое</option>
                              </select>
                              <input
                                type="number"
                                // className="input_numbers"
                                className={question.rangeValid ? "input_numbers" : "input_numbers input_error"}
                                value={question.min_value}
                                onChange={(event) => {
                                  editRangeQuestionMinValue(event, index_section, index_question);
                                }}
                              />
                              <input
                                type="number"
                                // className="input_numbers"
                                className={question.rangeValid ? "input_numbers" : "input_numbers input_error"}
                                value={question.max_value}
                                onChange={(event) => {
                                  editRangeQuestionMaxValue(event, index_section, index_question);
                                }}
                              />
                            </div>
                          </div>
                          <button
                            className="btn_checklist"
                            onClick={(event) => {
                              delQuestion(event, index_section, index_question);
                            }}
                          >
                            <i className="fa fa-minus" aria-hidden="true"></i>
                          </button>
                        </div>
                        <hr className="hr_line" />
                      </div>
                    );
                  }
                })}
                <div className="section_footer">
                  <button
                    className="btn_checklist"
                    onClick={(event) => {
                      addQuestion(event, index_section);
                    }}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="btn__add__area__wrap"></div>
            </div>
          ))}
          <div className="btn__add__area__wrap">
            <button
              className={"btn btn-light btn-outline-primary btn__add__area"}
              onClick={() => {
                addSection();
              }}
            >
              + Раздел
            </button>
          </div>
        </Modal.Body>
        {/* <Modal.Footer> */}
        <button className="btn btn-primary float-start" onClick={closeModalCheckListConstructor}>
          Сохранить изменения
        </button>
        {/* </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default ModalCheckListConstructor;
