import jwt from "jwt-decode";
import {setUser} from "../../store/userSlice";
import store from '../../store';


export const saveTokenData = (token) => {
  let token_data = jwt(token)
  localStorage.setItem('token', token);
  store.dispatch(setUser({
    id: token_data.id,
    username: token_data.uname,
    customerId: token_data.company,
    is_superuser: token_data.is_superuser,
    token: token,
  }))
}