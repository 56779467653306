import React, { useEffect, useState } from "react";
import { variables } from "../Variables";
import axios from "./Services/AxiosService";

export function CharacteristicModal({ token, apply_id, refreshApply, userId }) {
  const [text, setText] = useState("");
  const { 0: isForCustomer, 1: setIsForCustomer } = useState(true);

  const addCharacteristic = () => {
    let url = variables.API_URL + "/api/apply_characteristic/";
    axios
      .post(
        url,
        {
          apply: apply_id,
          characteristic_text: text,
          is_for_customer: isForCustomer,
          recruter: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        refreshApply();
        setText("");
      });
  };

  return (
    <div className="modal fade" id="add_characteristic_modal" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centred">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Добавить характеристику</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="input-group mb-3">
              <span className="input-group-text">Текст</span>
              <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="form-control"
                aria-label="With textarea"
              ></textarea>
            </div>
            <div className="switcher">
              <input
                id={"isForCustomer"}
                className="switcher__input"
                type="checkbox"
                checked={isForCustomer}
                onChange={() => setIsForCustomer(!isForCustomer)}
              />
              <label htmlFor={"isForCustomer"} className="switcher__switcher"></label>
              <div>Для заказчика</div>
            </div>
          </div>
          <button
            disabled={!text}
            onClick={addCharacteristic}
            type="button"
            className="btn btn-primary float-start"
            data-bs-dismiss="modal"
          >
            Отправить
          </button>
        </div>
      </div>
    </div>
  );
}
