import React, { useEffect, useState } from "react";
import Pagination from "./pagination/Pagination";
import { variables } from "../Variables";
import axios from "./Services/AxiosService";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";

const ENTER_KEY = 13;

export function Persons(props) {
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(1);
  const [persons, setPersons] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [filterQuery, setFilterQuery] = useState("");
  const [phone, setPhone] = useState("");
  const [additional_phone, setAddPhone] = useState("");
  const [personId, setPersonId] = useState("");
  const [countSort, setCountSort] = useState(0);
  var { 0: additinalPhoneStatus, 1: setAdditinalPhoneStatus } = useState("OK");

  const refreshList = (newFilter = "", orderCount = "0") => {
    let filter = "";
    if (orderCount.toString() !== "0") {
      const prodFilter = "?order=" + orderCount;
      const useFilter = newFilter === "" ? "" : "&&filter=" + newFilter;
      filter = prodFilter + useFilter;
    } else filter = newFilter === "" ? "" : "?filter=" + newFilter;
    fetch(variables.API_URL + "/api/persons/" + filter, {
      headers: {
        Accept: " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${props.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPersons(data.data);
        setTotalCount(data.count);
      });
    setPageNumber(1);
  };

  useEffect(() => {
    refreshList();
  }, []);

  const getSectionsByURL = (link) => {
    let filter = "";
    if (countSort.toString() !== "1") {
      const prodFilter = "&&order=" + countSort;
      const useFilter = filterQuery === "" ? "" : "&&filter=" + filterQuery;
      filter = prodFilter + useFilter;
    } else filter = filterQuery === "" ? "" : "&&filter=" + filterQuery;
    const url = `${variables.API_URL + "/api/persons/"}${link}` + filter;
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((response) => response.data);
  };

  const changeFilterQuery = (e) => {
    setFilterQuery(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY) {
      setFilterQuery(e.target.value);
      refreshList(e.target.value);
    }
  };

  function sortCountNumber(number) {
    setCountSort(number);
    refreshList(filterQuery, number);
  }

  const changePage = (page) => {
    getSectionsByURL("?page=" + page.toString()).then((result) => {
      setPersons(result.data);
      setPageNumber(page);
    });
  };

  const checkPhone = (number, pId) => {
    if (number.length !== 1) {
      setPhone(number.split("https://wa.me/")[1]);
    } else setPhone("7");
    setPersonId(pId);
  };

  const checkAddPhone = (number, pId) => {
    if (number.length !== 1) {
      setAddPhone(number);
    } else setAddPhone("7");
    setPersonId(pId);
  };

  const editPhone = () => {
    fetch(variables.API_URL + "/api/persons/" + personId, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      body: JSON.stringify({
        number: "https://wa.me/" + phone,
      }),
    })
      .then((res) => {
        res.json();
        refreshList(filterQuery, countSort);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const AdditionalPhone = () => {
    fetch(variables.API_URL + "/api/additional_number/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      body: JSON.stringify({
        add_number: additional_phone,
        person_id: personId,
      }),
    })
      .then((res) => {
        console.log("AdditionalPhone res", res);
        if (res.status === 404) {
          setAdditinalPhoneStatus("Такой номер уже существует");
        } else if (res.status === 200) {
          setAdditinalPhoneStatus("OK");
        } else {
          setAdditinalPhoneStatus("Ошибка");
        }
        refreshList(filterQuery, countSort);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  return (
    <div>
      <input
        type="text"
        className="form-control"
        placeholder="Введите id person или номер телефона..."
        style={{ marginTop: "5px" }}
        value={filterQuery}
        onChange={changeFilterQuery}
        onKeyDown={handleKeyDown}
      />
      <table className="table">
        <thead>
          <tr>
            <th width='7%'>ID</th>
            <th width='20%'>Номер</th>
            <th width='15%'>Дополнительный номер</th>
            <th width='20%'>Email</th>
            {countSort === 0 ? (
              <th width='8%'>
                <button
                  className="btn default"
                  onClick={() => sortCountNumber(1)}
                  style={{ fontWeight: "bold", padding: "0rem" }}
                >
                  Количество откликов
                </button>
              </th>
            ) : null}
            {countSort === 1 ? (
              <th width='8%'>
                <button
                  className="btn default"
                  onClick={() => sortCountNumber(2)}
                  style={{ fontWeight: "bold", padding: "0rem" }}
                >
                  Количество откликов ↓
                </button>
              </th>
            ) : null}
            {countSort === 2 ? (
              <th width='8%'>
                <button
                  className="btn default"
                  onClick={() => sortCountNumber(0)}
                  style={{ fontWeight: "bold", padding: "0rem" }}
                >
                  Количество откликов ↑
                </button>
              </th>
            ) : null}
            <th width='10%'></th>
            <th width='5%'></th>
            <th width='5%'></th>
          </tr>
        </thead>
        <tbody>
          {persons.map((person) => (
            <tr key={person.id}>
              <td><div>{person.id}</div></td>
              <td><div>{person.number}</div></td>
              <td><div>{person.additional_phone}</div></td>
              <td><div>{person.email}</div></td>
              <td><div>{person.apply_count}</div></td>
              <td>
                <button type="button" className="btn btn-light mr-1" onClick={() => navigate("/" + person.id)}>
                  Открыть
                </button>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-default"
                  data-bs-toggle="modal"
                  data-bs-target="#editPhone"
                  onClick={() => checkPhone(person.number, person.id)}
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
              </td>
              {person.additional_phone === "-" ? (
                <td>
                  <button
                    type="button"
                    className="btn btn-default"
                    data-bs-toggle="modal"
                    data-bs-target="#AdditionalPhone"
                    onClick={() => checkAddPhone(person.additional_phone, person.id)}
                  >
                    <i className="fa fa-address-book-o" aria-hidden="true"></i>
                  </button>
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-center">
        <Pagination
          className="pagination-bar"
          currentPage={pageNumber}
          totalCount={totalCount}
          pageSize={10}
          onPageChange={(page) => changePage(page)}
        />
      </div>

      <div className="modal fade" id="editPhone" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centred" style={{ maxWidth: "333px" }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Изменение телефона</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="input-group mb-3">
                <PhoneInput country={"ru"} value={phone} onChange={(phone_n) => setPhone(phone_n)} />
              </div>
            </div>
            <button type="button" className="btn btn-primary float-start" onClick={editPhone} data-bs-dismiss="modal">
              Изменить телефон
            </button>
          </div>
        </div>
      </div>

      <div className="modal fade" id="AdditionalPhone" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centred" style={{ maxWidth: "333px" }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Дополнительный телефон</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="input-group mb-3">
                <PhoneInput country={"ru"} value={additional_phone} onChange={(phone_n) => setAddPhone(phone_n)} />
              </div>
              {additinalPhoneStatus !== "OK" && <span style={{ color: "red" }}>{additinalPhoneStatus}</span>}
            </div>
            <button
              type="button"
              className="btn btn-primary float-start"
              onClick={AdditionalPhone}
              //   data-bs-dismiss="modal"
            >
              Добавить телефон
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
