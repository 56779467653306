import axios from './Services/AxiosService';
import {variables} from "../Variables";

const API_URL = variables.API_URL
function header_with_token(token) {
    return {
        "Accept": " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${token}`
    }
}
export default class LPRService {

    // constructor(props) {
    //     this.header = {
    //             "Accept":" application/json",
    //             "Content-Type":" application/json",
    //             Authorization: `Bearer ${props.token}`
    //         }
    //         console.log(this.header)
    // }



    searchProducts(name = '', min_price = '', max_price = '', category = '') {
        const url = `${API_URL}/api/lpr/?name=${name}&price_min=${min_price}&price_max=${max_price}&category=${category}`;
        // return axios.get(url).then(response => response.data.data.results);
        return fetch(url).then(response => response.json()).then(data => data.data)
    }

    getLPRs(page='', professional_industry='', professional_area='', filter='', token=null) {
        const url = `${API_URL}/api/lpr/?page=${page}&professional_industry=${professional_industry}&professional_area=${professional_area}&filter=${filter}`;
        // return axios.get(url).then(response => response.data.data.results);
        return fetch(url, token && {headers: {
        "Accept": " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${token}`
    }   }).then(response => response.json()).then(data => data.data)
    }

    getProfessionalAreas() {
        const url = `${API_URL}/api/professional_area/`;
        // return axios.get(url).then(response => response.data.data.results);
        return fetch(url).then(response => response.json()).then(data => data.data)
    }

    getLPRByURL(link) {
        const url = `${link}`;
        return axios.get(url).then(response => response.data.data);
    }
    //
    // getProduct(pk) {
    //     const url = `${API_URL}/api/lpr/${pk}`;
    //     // return axios.get(url).then(response => response.data.data.results);
    //     return fetch(url).then(response => response.json()).then(data => data.data.results)
    // }
    //
    // deleteProduct(lpr) {
    //     const url = `${API_URL}/api/lpr/${product.pk}`;
    //     return axios.delete(url, {headers: this.header});
    // }
    //
    createLPR(LPR, token) {
        const url = `${API_URL}/api/lpr/`;
        return axios.post(url, LPR, {headers: {
        "Accept": " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${token}`
    }   }).then(response => console.log(response.data.data.results));
    }
    //
    // updateProduct(product) {
    //     const url = `${API_URL}/api/lpr/${product.pk}/`;
    //     return axios.put(url, product, {headers: this.header}).then(response => response.data.data.results);
    //     ;
    // }
}