import React, {useEffect, useState} from "react";
import {variables} from "../Variables";
import axios from './Services/AxiosService';
import Select from "./Select";

import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
registerLocale('ru', ru);

const ENTER_KEY = 13

export function AddHourModal({token, user, apply, getApply}) {

    const [selectedDate, setSelectedDate] = useState(new Date())
    const [addTime, setAddTime] = useState("")
    const [selectedType, setSelectedType] = useState(1);
    const [comment, setComment] = useState("");
    const [types, setTypes] = useState([]);

    const getTypes = () => {
        let url = variables.API_URL + '/api/get_addtime_type_options/'
        axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
             }
        }).then(res =>{
            setTypes(res.data)
        })
    }

    const addTimeToRecruter = () => {
        console.log(selectedType)
        if (addTime) {
            let url = variables.API_URL + '/api/add_time_to_recruter_by_recruter/'
            axios.post(url, {
                time: addTime,
                type: selectedType,
                date: selectedDate,
                comment: comment,
                recruter: user,
                apply: apply,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                console.log(res)
                alert("Время добавлено!")
                setSelectedDate(new Date())
                setAddTime('')
                setSelectedType(1)
                setComment('')
                getApply()
            })
        }
    }

    useEffect(()=>{
        getTypes()
    }, [])

    return (
        <div className='modal fade' id="statsistic_add_hour_modal" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centred">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Добавить время сотруднику</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="input-group mb-3">
                            <span className="input-group-text">Тип</span>
                            <Select className='select-vacancy' value={selectedType}
                                    onChange={(e)=>setSelectedType(e.target.value)}
                                    options={types}
                                    hasDescription/>
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text">Количество минут</span>
                            <input type="number" className='form-control' value={addTime} placeholder="Введите количество минут"  pattern="[0-9]*"
                                   onChange={(e)=>e.target.validity.valid && setAddTime(e.target.value)}/>
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text">Дата</span>
                            <DatePicker
                                locale="ru"
                                dateFormat="dd.MM.yyyy"
                                selected={selectedDate}
                                onChange={(e)=>setSelectedDate(e)}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text">Комментарий</span>
                            <input type="text" className='form-control' value={comment} placeholder="Введите комментарий"
                                   onChange={(e)=>setComment(e.target.value)}/>
                        </div>
                    </div>

                    <button type="button" className="btn btn-primary float-start" data-bs-dismiss="modal"  onClick={addTimeToRecruter}>Отправить</button>
                </div>
            </div>
        </div>
    )
}