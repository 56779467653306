import React, { useState } from "react";
import "./ModalChekList.css";
import Modal from "react-bootstrap/Modal";
import { variables } from "../Variables";
import { useEffect } from "react";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";

const ModalChekList = (props) => {
  const [checkList, setCheckList] = useState({
    id: -1,
    sections: [],
  });

  const getCheckList = async () => {
    const response = await fetch(
      variables.API_URL + "/api/checklist/?apply=" + props.apply.id,
      {
        method: "GET",
        headers: {
          Accept: " application/json",
          "Content-Type": " application/json",
          Authorization: `Bearer ${props.token}`,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      console.log(data);
      for (var section of data.sections) {
        for (var question of section.questions) {
          if (question.type === "boolean") {
            if (question.answer === 1) {
              question.answer = true;
            } else {
              question.answer = false;
            }
          }
        }
      }
      setCheckList(data);
    } else {
      console.log(response.status);
    }
  };

  const closeCheckList = async () => {
    if (checkList.id !== -1) {
      await booleanNumberize();
      await fetchPatchCheckList();
      // await getApply();
      // props.setApply();
    }
    props.onModelClose(false);
  };

  const fetchPatchCheckList = async () => {
    console.log(checkList);
    const response = await fetch(
      variables.API_URL + "/api/checklist/?apply=" + props.apply.id,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        body: JSON.stringify(checkList),
      }
    );
    if (response.ok) {
      const data = await response.json();
      props.setApply({ ...props.apply, percentage_compliance: data });
    }
  };

  const handleSetCheckBox = (event, index_section, index_question) => {
    var newSections = checkList.sections;
    newSections[index_section].questions[index_question].answer =
      event.target.checked;
    setCheckList({ ...checkList, sections: newSections });
  };

  const rangeAnswerEditor = (event, index_section, index_question) => {
    let newSections = checkList.sections;
    let value = event.target.value;
    let question = newSections[index_section].questions[index_question];

    if (value === "") {
      value = "";
    } else if (value === "-") {
      value = "-";
    } else if (value < question.min_value) {
      value = question.min_value;
    } else if (value > question.max_value) {
      value = question.max_value;
    }
    question.answer = value;

    setCheckList({ ...checkList, sections: newSections });
  };

  const booleanNumberize = async () => {
    var newSections = checkList.sections;
    for (var section of newSections) {
      for (var question of section.questions) {
        if (question.type === "boolean") {
          if (question.answer) {
            question.answer = 1;
          } else {
            question.answer = 0;
          }
        } else if ((question.type === "range") | (question.type === "test")) {
          if (!String(question.answer).match(/^-?\d+$/)) {
            question.answer = 0;
            // if (question.max_value < 0) {
            //   question.answer = question.max_value;
            // } else if (question.min_value > 0) {
            //   question.answer = question.min_value;
            // } else {
            //   question.answer = 0;
            // }
          }
        }
      }
    }
    console.log(newSections.questions);
    setCheckList({ ...checkList, sections: newSections });
  };

  useEffect(() => {
    getCheckList();
  }, []);

  return (
    <div className={"container modal-content rounded-5 shadow mt-0"}>
      <Modal
        show={props.isOpened}
        onHide={props.onModelClose}
        className={"modal fade modal-check-list"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="fw-bold mb-0">Чек-лист: {checkList.name}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {checkList.id !== -1 ? (
            <div className="chek_list_body">
              {checkList.sections.map((section, index_section) => {
                if (section.questions.length > 0) {
                  return (
                    <div className="check_list_section" key={section.id}>
                      <div className="check_list_section_header">
                        {section.name}
                      </div>
                      <div className="check_list_section_body">
                        {section.questions.map((question, index_question) => {
                          if (question.type === "boolean") {
                            return (
                              <div
                                className="check_list_section_item"
                                key={index_question}
                              >
                                <div className="boolean_question_wrap">
                                  <FormCheckInput
                                    className="check_list_checkbox"
                                    id={question.id}
                                    onChange={(event) =>
                                      handleSetCheckBox(
                                        event,
                                        index_section,
                                        index_question
                                      )
                                    }
                                    checked={Boolean(question.answer)}
                                  ></FormCheckInput>
                                  <div>{question.question}</div>
                                </div>
                              </div>
                            );
                          } else if (
                            (question.type === "range") |
                            (question.type === "test")
                          ) {
                            return (
                              <div className="check_list_section_item">
                                <div className="input_range_with_values">
                                  <div className="question_range_values_min">
                                    {question.min_value}
                                  </div>
                                  <input
                                    placeholder="0"
                                    min={question.min_value}
                                    max={question.max_value}
                                    className="number_range_input"
                                    type="number"
                                    value={question.answer}
                                    onChange={(event) =>
                                      rangeAnswerEditor(
                                        event,
                                        index_section,
                                        index_question
                                      )
                                    }
                                  ></input>
                                  <div className="question_range_values_max">
                                    {question.max_value}
                                  </div>
                                </div>
                                <div className="question_range_text">
                                  {question.question}
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  );
                } else {
                  <div></div>;
                }
              })}
            </div>
          ) : (
            <h4 className="fw-bold mb-0">Чек-лист не создан</h4>
          )}
        </Modal.Body>
        {/* <Modal.Footer> */}
        <button
          className="btn btn-primary float-start"
          onClick={closeCheckList}
        >
          Сохранить изменения
        </button>
        {/* </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default ModalChekList;
