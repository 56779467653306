import React, {useEffect, useState} from "react";
import ProfIndustryService from "./ProfIndustryService";

const profIndustryService = new ProfIndustryService();

export default function SelectProfIndustry(props) {
    const [profAreas, setProfAreas] = useState([])

    useEffect(() => {
        profIndustryService.getProfIndustries().then(function (result) {
            setProfAreas(result.results)
        });
    }, [])

    return (<>
            <select id={"31"} className="form-select" required={"True"} value={props.value} onChange={props.onChange}
                    style={{marginTop: '5px'}}
                    defaultValue={"default"}>
                <option disabled={props.disabledefault} key={"default"} value={""}>{"Отрасль компании"}</option>
                {profAreas?.map(e =>
                    <option key={e.id} value={e.id}>{e.name}</option>
                )}
            </select>
            <label htmlFor={"31"}>{props.required && <span className={"text-danger"}> * </span>}Отрасль компании</label>
        </>
    )
}