import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { variables } from "../../Variables";
import { useNavigate, useParams } from "react-router-dom";
import axios from '../Services/AxiosService';
import Gallery from "react-photo-gallery";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearAplyFilters, setAplyGalleryFilter, setDateCreatedFilter, setRegionFilter, setResumeNameFilter, setFullAplyGalleryFilter} from '../../store/applyGalleryFilterSlice'
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Photos.css'
import Select from '../Select'
import { Button } from "react-bootstrap";
import PhotoAlbum from "react-photo-album";
import Pagination from "../pagination/Pagination";
import Preloader from '../Preloader/Preloader'

// new
export default function Photos(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const apply_filter = useSelector(state => state.apply_gallery_filter)
    const [searchParams, setSearchParams] = useSearchParams()

    const [applies, setApplies] = useState([])
    const [totalCount, setTotalCount] = useState("")
    const [currentPage, setCurrentPage] = useState("")

    const [startDate, setStartDate] = useState('')

    const [region, setRegion] = useState('')
    const [resumeName, setResumeName] = useState('')
    const [coldStatusOpt, setColdStatusOpt] = useState('')
    const [isLoad, setIsLoad] = useState(false)

    const isFirst = useRef()

    useEffect(() => {
        isFirst.current = false
    }, [])


    const year_range = ['-', ...[...Array(82).keys()].map(i => i + new Date().getFullYear() - 81).reverse()].map((item) => {return {label: item, value: item}});

    const refreshList = (page, newState = null) => {
        setIsLoad(true)
        let new_apply_filter
        if (newState) {
            new_apply_filter = newState
        } else {
            new_apply_filter = apply_filter
        }
        
        let filter = '';
        if (new_apply_filter.gender !== '') { filter += '&&gender=' + new_apply_filter.gender }
        if (new_apply_filter.with_video !== '') { filter += '&&with_video=' + new_apply_filter.with_video }
        if (new_apply_filter.birth_year_start !== '') { filter += '&&birth_year_start=' + new_apply_filter.birth_year_start }
        if (new_apply_filter.birth_year_end !== '') { filter += '&&birth_year_end=' + new_apply_filter.birth_year_end }
        if (new_apply_filter.region !== '') { filter += '&&region=' + new_apply_filter.region }
        if (new_apply_filter.date_created !== '') { filter += '&&date_created=' + new_apply_filter.date_created }
        if (new_apply_filter.resume_name !== '') { filter += '&&resume_name=' + new_apply_filter.resume_name }
        if (new_apply_filter.ordering !== '') { filter += '&&ordering=' + new_apply_filter.ordering }
        if (new_apply_filter.last_active_ordering !== '') { filter += '&&last_active_ordering=' + new_apply_filter.last_active_ordering }
        if (new_apply_filter.coldStatus?.length > 0) { filter += '&&cold_status=' + new_apply_filter.coldStatus.join(',') }

        fetch(variables.API_URL + '/api/apply_gallery/?page=' + page + filter, {
            headers: {
                "Accept": " application/json",
                "Content-Type": " application/json",
                Authorization: `Bearer ${props.token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setApplies(data.data.map((item) => {return {'id': item.id, 'src': variables.API_URL + '/' + item.photo + '?size=250x250', width: 600, height: 600, title: item.name, cold_status: item.cold_status}}))
                setTotalCount(data.count);
                setIsLoad(false)
            })
            .catch((error) => 
                {
                    console.log(error)
                    setIsLoad(false)
                }
            )
    }

    const refreshColdStatusList = () => {

        fetch(variables.API_URL + '/api/cold_status_options/', {
            headers: {
                "Accept": " application/json",
                "Content-Type": " application/json",
                Authorization: `Bearer ${props.token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setColdStatusOpt(data)
            })
    }

    useLayoutEffect(() => {
        dispatch(clearAplyFilters())
    }, [])

    useLayoutEffect(() => {
        refreshColdStatusList()
    }, [])

    // useLayoutEffect(() => {
    //     dispatch(setAplyGalleryFilter({
    //         page: 1,
    //     }))
    //     searchParams.set('page', 1)
    //     setSearchParams(searchParams, { replace: true })
    // }, [apply_filter.gender, apply_filter.birth_year_start, apply_filter.birth_year_end, apply_filter.region,
    //      apply_filter.date_created, apply_filter.resume_name, apply_filter.ordering, apply_filter.coldStatus]);

    useLayoutEffect(() => {
        if (!isFirst.current) {
            isFirst.current = true
        } else {
            refreshList(1)
            setCurrentPage(1)
            searchParams.set('page', 1)
            setSearchParams(searchParams, { replace: true })

        }
    }, [apply_filter]);


    useLayoutEffect(() => {
        let state
        state = dispatch(setFullAplyGalleryFilter({
            gender: searchParams.get('gender') || '',
            with_video: searchParams.get('with_video') || '',
            birth_year_start: Number(searchParams.get('birth_year_start')) || '',
            birth_year_end: Number(searchParams.get('birth_year_end')) || '',
            ordering: searchParams.get('ordering') || '',
            last_active_ordering: searchParams.get('last_active_ordering') || '',
            coldStatus: searchParams.get('cold_status') ? searchParams.get('cold_status').split('-').filter(item => item).map(num => num) : null,
            region: searchParams.get('region') || '',
            date_created: searchParams.get('date_created') || '',
            resume_name: searchParams.get('resume_name') || '',
        }))
        refreshList(Number(searchParams.get('page')) || 1, state.payload)
        setRegion(searchParams.get('region') || '')
        if (searchParams.get('date_created')) {
            setStartDate(new Date(searchParams.get('date_created')))
        }
        setResumeName(searchParams.get('resume_name') || '')
        setCurrentPage(Number(searchParams.get('page')) || 1)
    }, [])

    const changePage = (page) => {
        window.scrollTo(0, 0);
        searchParams.set('page', page)
        setSearchParams(searchParams, { replace: true })
        setCurrentPage(page)
        refreshList(page)
    }

    const changeGenderFilter = (gender) => {
        if (apply_filter.gender == gender) gender = 'false'
        searchParams.set('gender', gender)
        setSearchParams(searchParams, { replace: true })

        dispatch(setAplyGalleryFilter({ gender: gender }))
    }

    const changeWithVideoFilter = () => {
        let with_video = 'f'
        if (apply_filter.with_video == 'f') with_video = 't'
        searchParams.set('with_video', with_video)
        setSearchParams(searchParams, { replace: true })

        dispatch(setAplyGalleryFilter({ with_video: with_video }))
    }

    const changeStartYearFilter = (year) => {
        searchParams.set('birth_year_start', year)
        setSearchParams(searchParams, { replace: true })

        dispatch(setAplyGalleryFilter({ birth_year_start: year }))
    }

    const changeEndYearFilter = (year) => {
        searchParams.set('birth_year_end', year)
        setSearchParams(searchParams, { replace: true })

        dispatch(setAplyGalleryFilter({ birth_year_end: year }))
    }

    const changeDateCreatedFilter = (date) => {
        let new_date = `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? '0'+(date.getMonth() + 1) : date.getMonth() + 1}-${date.getDate() < 10 ? '0'+date.getDate() : date.getDate()}`
        searchParams.set('date_created', new_date)
        setSearchParams(searchParams, { replace: true })

        dispatch(setDateCreatedFilter({ date_created: new_date }))
        setStartDate(date)
    }

    const changeFilterQuery = (filter, e) => {
        if (e.keyCode == 13) {
            if (filter.length < 1001) {
                searchParams.set('resume_name', filter)
                setSearchParams(searchParams, { replace: true })

                dispatch(setResumeNameFilter({ resume_name: filter }))
            } else {
                filter = filter.slice(0, 1000)
                searchParams.set('resume_name', filter)
                setSearchParams(searchParams, { replace: true })
                alert("Слишком длинная строка")
                setResumeName(filter)
                dispatch(setResumeNameFilter({ resume_name: filter }))
            }
        }
    }

    const changeRegionFilter = (region, e) => {
        if (e.keyCode == 13) {
            searchParams.set('region', region)
            setSearchParams(searchParams, { replace: true })

            dispatch(setRegionFilter({ region: region }))
        }
    }

    const changeOrdering = (value) => {
        searchParams.set('ordering', value)
        setSearchParams(searchParams, { replace: true })

        dispatch(setAplyGalleryFilter({ ordering: value }))
    }

    const changeLastActiveOrdering = (value) => {
        searchParams.set('last_active_ordering', value)
        setSearchParams(searchParams, { replace: true })

        dispatch(setAplyGalleryFilter({ last_active_ordering: value }))
    }

    const changeColdStatusFilter = (id) => {
        let prevParams = searchParams.get('cold_status') || ''
        if (!prevParams.includes('-' + id.toString() + '-')) {
            searchParams.set('cold_status', '-' + id.toString() + '-' + prevParams)
            setSearchParams(searchParams, { replace: true })
        }
        else {
            searchParams.set('cold_status', prevParams.replace('-' + id.toString() + '-', ''))
            setSearchParams(searchParams, { replace: true })
        }

        dispatch(setAplyGalleryFilter({ coldStatus: id }))
    }

    const clearFilter = () => {
        setSearchParams('', { replace: true })
        setRegion('')
        setResumeName('')
        setStartDate('')
        dispatch(clearAplyFilters())
    }

    // const onNavigate = (e) => {
    //     onClick={(e)=> {
    //         if (e.ctrlKey) window.open('/apply/' + apply.id, "_blank")
    //         else navigate('/apply/' + apply.id)
    //     }} onAuxClick={(e) => {
    //         if (e.button == 1) window.open('/apply/' + apply.id, "_blank")
    //     }}
    // }

    const changeColdStatus = (photo, cold_status) => {
        let url = variables.API_URL + '/api/apply/' + photo.id
        let new_status = null
        if (cold_status != photo.cold_status) new_status = cold_status
        axios.put(url, {
            cold_status: new_status
        },
        {
            headers: {
            Authorization: `Bearer ${props.token}`
            }
        }).then(res => {
            setApplies(prevState => prevState.map((item) => { 
                if (item.id === photo.id) {
                    item.cold_status = new_status
                }
                return item
            }))
        }).catch((err) => {
            console.log(err);
        });
    }

    const rowConstraints = [
        { height: 200, minPhotos: 2, maxPhotos: 4 }, // first row is 200 pixels tall and can have between 2 and 4 photos
        { height: 300, minPhotos: 3, maxPhotos: 5 }, // second row is 300 pixels tall and can have between 3 and 5 photos
        { height: 250, minPhotos: 2, maxPhotos: 3 }, // third row is 250 pixels tall and can have between 2 and 3 photos
        // ...
      ];

    return (
        <div className="main-gallery-block">
            {applies &&
            <div className='photo_fit'>
                 {/* <Gallery photos={applies} onClick={(item)=> {
                    if (item.nativeEvent.ctrlKey) window.open('/apply/' + item.target.id, "_blank")
                    else navigate('/apply/' + item.target.id)
                }}/> */}

            <PhotoAlbum
                layout="masonry"
                photos={applies}
                columns={(containerWidth) => {
                    return 3;
                }}
                onClick={({photo, event})=> {
                    if (event.ctrlKey) window.open('/apply/' + photo.id, "_blank")
                    else navigate('/apply/' + photo.id)
                }}
                renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                    <div onMouseUp={(e) => {
                        if (e.button == 1) window.open('/apply/' + photo.id, "_blank")
                    }} className="photo-wrapper" style={{ position: "relative", ...wrapperStyle }}>
                        {
                        <div className="photo-button">
                            <div style={{display: 'flex'}}>
                                <i style={(photo.cold_status ===  1) ? {color: '#0d6efd'} : {color: 'gray'}} className="fa fa-heart" aria-hidden="true" onClick={() => changeColdStatus(photo, 1)}></i>
                                <i style={(photo.cold_status ===  2) ? {color: '#0d6efd'} : {color: 'gray'}} className="fa fa-phone mx-2" aria-hidden="true" onClick={() => changeColdStatus(photo, 2)}></i>
                                <i style={(photo.cold_status ===  3) ? {color: '#0d6efd'} : {color: 'gray'}} class="fa fa-window-close-o" aria-hidden="true" onClick={() => changeColdStatus(photo, 3)}></i>
                            </div>
                        </div>
                        }
                        {renderDefaultPhoto({ wrapped: true })}
                        {photo.title && (
                            <div className="photo-title">
                                {photo.title}
                            </div>
                        )}
                    </div>
                )}
            />

            { (currentPage && totalCount) &&
                <div className="text-center">
                    <Pagination
                        className="pagination-bar mt-3"
                        currentPage={currentPage}
                        totalCount={totalCount}
                        pageSize={100}
                        onPageChange={page => changePage(page)}
                    />
                </div>
            }
            </div>

            }

            <div className="filter-block" >
                    <h4 >Всего откликов: {totalCount}</h4>
                    <div style={{ display: 'flex' }} className="m-2">
                        <button type="button" className={"btn " + (apply_filter.ordering === 'start' ? "btn-primary" : "btn-outline-primary")}
                            onClick={() => changeOrdering('start')} style={{ borderRadius: "0" }}>
                            Новые
                        </button>
                        <button type="button" className={"btn " + (apply_filter.ordering === 'end' ? "btn-primary" : "btn-outline-primary")}
                            onClick={() => changeOrdering('end')} style={{ borderRadius: "0" }}>
                            Старые
                        </button>
                    </div>
                    <h4 >Последняя активность:</h4>
                    <div style={{ display: 'flex' }} className="m-2">
                        <button type="button" className={"btn " + (apply_filter.last_active_ordering === 'start' ? "btn-primary" : "btn-outline-primary")}
                            onClick={() => changeLastActiveOrdering('start')} style={{ borderRadius: "0" }}>
                            Новые
                        </button>
                        <button type="button" className={"btn " + (apply_filter.last_active_ordering === 'end' ? "btn-primary" : "btn-outline-primary")}
                            onClick={() => changeLastActiveOrdering('end')} style={{ borderRadius: "0" }}>
                            Старые
                        </button>
                    </div>
                    <div className="" style={{ display: "flex", margin: '15px 0' }}>
                        <input id={'with_wideo'} type="checkbox" className="chackbox-vacancy" checked={apply_filter.with_video == 't'} onChange={() => changeWithVideoFilter()} />
                        <label className="chackbox-vacancy-label" htmlFor={'with_wideo'}>С видео</label>
                    </div>
                    <h4>Пол:</h4>
                    <div style={{display: 'flex'}}>
                        <div  style={{ display: "flex" }}>
                            <input id={'male123'} type="checkbox" className="chackbox-vacancy" checked={apply_filter.gender === 'M'} onChange={() => changeGenderFilter('M')} />
                            <label className="chackbox-vacancy-label" htmlFor={'male123'}>М</label>
                        </div>
                        <div className="mx-3" style={{ display: "flex" }}>
                            <input id={'female123'} type="checkbox" className="chackbox-vacancy" checked={apply_filter.gender === 'F'} onChange={() => changeGenderFilter('F')} />
                            <label className="chackbox-vacancy-label" htmlFor={'female123'}>Ж</label>
                        </div>
                    </div>
                    <h4>Год рождения:</h4>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                        c 
                        <Select options={year_range} value={apply_filter.birth_year_start} onChange={(e) => changeStartYearFilter(e.target.value)}/>
                        по
                        <Select options={year_range} value={apply_filter.birth_year_end} onChange={(e) => changeEndYearFilter(e.target.value)}/>

                    </div>
                    <h4>Статус:</h4>
                    {coldStatusOpt?.length > 0 && coldStatusOpt?.map((item) =>
                        <div className="mb-3" style={{ display: "flex" }}>
                            <input id={item.value + 1231} type="checkbox" className="checkbox-status" checked={apply_filter.coldStatus.includes(item.value)} onChange={() => changeColdStatusFilter(item.value)} />
                            <label className="chackbox-vacancy-label" htmlFor={item.value + 1231}>{item.label}</label>
                        </div>
                    )}
                    <h4>Регион:</h4>
                    <input type="text" className="form-control" placeholder="Введите название региона ..." style={{marginTop:'5px'}} value={region}
                               onChange={(e) => setRegion(e.target.value)} onKeyDown={(e) => changeRegionFilter(e.target.value, e)}/>
                    <h4>Дата создания:</h4>
                    <div className="input-group">
                            <DatePicker
                                placeholderText="Выберите дату ..."
                                wrapperClassName="datePicker"
                                locale="ru"
                                dateFormat="dd.MM.yyyy"
                                selected={startDate}
                                onChange={(e)=>changeDateCreatedFilter(e)}
                            />
                        </div>
                    <h4>Поиск по названию:</h4>
                    <textarea placeholder="Введите название резюме..." style={{marginTop:'5px'}} value={resumeName} 
                         onChange={(e) => setResumeName(e.target.value)} onKeyDown={(e) => changeFilterQuery(e.target.value, e)}
                         class="form-control" aria-label="With textarea"></textarea>

                    <Button className="mt-3" onClick={clearFilter}>Очистить фильтры</Button>
            </div>
        

        {isLoad && <Preloader />}

        </div>
    );
}
