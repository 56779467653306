import React, { useEffect, useLayoutEffect, useState } from "react";
import { variables } from "../Variables";
import startsWith from "lodash.startswith";
import { useNavigate, useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import SelectProfArea from "./selectProfArea";
import LPRService from "./LPRService";
import SelectProfLevel from "./selectProfLevel";
import SelectProfIndustry from "./selectProfIndustry";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Field from "./field";
import "./miui.css";
import "./Apply.css";
import jwt from "jwt-decode";
import SelectLPRStatus from "./selectLPRStatus";
import { CandidateModal } from "./Candidate/CandidateModal";
import axios from "./Services/AxiosService";
import ModalChekList from "./ModalChekList";
import { CharacteristicModal } from "./CharacteristicModal";
import { AddHourModal } from "./AddHourModal";
import { useSelector } from "react-redux";
import ModalSocialNetwork from "./ModalSocialNetwork";
import { AddTagModal } from "./Modals/AddTag/AddTagModal";
import { AddVideoTagModal } from "./Modals/AddVideoTag/AddVideoTagModal";
import { ReactComponent as TrashIcon } from "../static/full-trash.svg";

const lprService = new LPRService();

export function Apply(props) {
  let { applyId } = useParams();
  const navigate = useNavigate();
  const windowWidth = useSelector((state) => state.window.width);

  const [apply, setApply] = useState([]);
  const [exp, setExp] = useState([]);
  const [education, setEducation] = useState([]);
  const [photo, setPhoto] = useState();
  const [miniature, setMiniature] = useState({});
  const [video, setVideo] = useState([]);
  const [audio, setAudio] = useState([]);
  const [records, setRecords] = useState([]);

  const [isEditingName, setIsEditingName] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [editedPosition, setEditedPosition] = useState("");

  const [audioRecorder, setAudioRecorder] = useState();
  const [recordedAudioStream, setRecordedAudioStream] = useState(null);

  const [modalTitle, setModalTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneDirty, setPhoneDirty] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [firstNameDirty, setFirstNameDirty] = useState(false);
  const [secondName, setSecondName] = useState("");
  const [secondNameDirty, setSecondNameDirty] = useState(false);
  const [patronymic, setPatronymic] = useState("");
  const [profArea, setProfArea] = useState("");
  const [position, setPosition] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyLink, setCompanyLink] = useState("");
  const [profLevel, setProfLevel] = useState("");
  const [profIndustry, setProfIndustry] = useState("");
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState("");
  const [addAudioModal, setAddAudioModal] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState("");

  const [modalCheckList, setShowModalCheckList] = useState({
    show: false,
  });
  const [showSocialNetwork, setShowSocialNetwork] = useState(false);
  const [socialNetworks, setSocialNetworks] = useState([]);
  const [waitResponse, setWait] = useState(false);
  const [imagesGallery, setImagesGallery] = useState([]);

  const [inputVideoStatus, setInputVideoStatus] = useState("text"); // text load good err
  const [inputAudioStatus, setInputAudioStatus] = useState("text"); // text load good err
  const [inputRecordStatus, setInputRecordStatus] = useState("text"); // text load good err
  const [inputAudioStreamStatus, setInputAudioStreamStatus] = useState("not_recording"); // not_recording recording load no_mic
  const [showDownloadFilePreloader, setShowDownloadFilePreloader] = useState(false);

  const [documents, setDocuments] = useState([]);
  const [tags, setTags] = useState([]);
  const [videotags, setVideotags] = useState([]);
  const [flag, setFlag] = useState(false);
  const [videotagsall, setVideotagsall] = useState([]);
  const [groups, setGroups] = useState([]);

  var { 0: currentPhotoId, 1: setCurrentPhotoId } = useState(apply?.photos?.at(0));

  useEffect(() => {
    if (!currentPhotoId) {
      setCurrentPhotoId(apply?.photos?.at(0)?.id);
    }
  }, [apply?.photos?.length]);

  const getDocuments = async () => {
    const response = await fetch(variables.API_URL + "/api/documents_for_apply/" + applyId, {
      method: "GET",
      headers: {
        Accept: " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${props.token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      setDocuments(data);
    }
  };
  useEffect(() => {
    if (inputVideoStatus === "good" || inputVideoStatus === "err") {
      const timer = setInterval(() => {
        setInputVideoStatus("text");
      }, 6000);
      return () => clearInterval(timer);
    } else if (inputAudioStatus === "good" || inputAudioStatus === "err") {
      const timer = setInterval(() => {
        setInputAudioStatus("text");
      }, 6000);
      return () => clearInterval(timer);
    } else if (inputRecordStatus === "good" || inputRecordStatus === "err") {
      const timer = setInterval(() => {
        setInputRecordStatus("text");
      }, 6000);
      return () => clearInterval(timer);
    }
  }, [inputVideoStatus, inputAudioStatus, inputRecordStatus]);

  const getImagesGallery = async () => {
    const response = await fetch(variables.API_URL + "/api/images/" + applyId, {
      method: "GET",
      headers: {
        Accept: " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${props.token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      setImagesGallery(data);
    }
  };

  useEffect(() => {
    let btn = document.getElementById("");
    getDocuments();
  }, []);

  const postImagesGallery = async (event) => {
    const files = event.target.files;
    // Иногда может придти FileList с length = 0
    var images = new FormData();
    if (files.length === 0) return;
    for (var file of files) {
      images.append(file.name, file, file.name);
    }
    const url = variables.API_URL + "/api/images/" + applyId;
    axios
      .post(url, images, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((res) => {
        getImagesGallery();
        event.target.value = "";
      })
      .catch((err) => {
        event.target.value = "";
      });
  };

  const sendDocuments = async (event) => {
    setShowDownloadFilePreloader(true);
    const files = event.target.files;
    // Иногда может придти FileList с length = 0
    var images = new FormData();
    if (files.length === 0) return;
    for (var file of files) {
      images.append(file.name, file, file.name);
    }
    const url = variables.API_URL + "/api/documents_for_apply/" + applyId;
    axios
      .post(url, images, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((res) => {
        setShowDownloadFilePreloader(false);
        getDocuments();
        event.target.value = "";
      })
      .catch((err) => {
        setShowDownloadFilePreloader(false);
        event.target.value = "";
      });
  };

  const getApply = async () => {
    fetch(variables.API_URL + "/api/apply/" + applyId, {
      method: "GET",
      headers: {
        Accept: " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${props.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setApply(data);
        getVideo(data);
        getAudio(data);
        setSocialNetworks(data.social_networks);
        getRecords(data);
      });
  };

  const getPhoto = () => {
    fetch(variables.API_URL + "/api/photo/" + applyId, {
      method: "GET",
      headers: {
        Accept: " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${props.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPhoto(data);
      });
  };

  const getExp = () => {
    fetch(variables.API_URL + "/api/experience/?filter=" + applyId, {
      method: "GET",
      headers: {
        Accept: " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${props.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setExp(data.data);
      });
  };

  const getVideo = (apply) => {
    if (apply) {
      fetch(variables.API_URL + "/api/video/?filter=" + apply.person_id, {
        method: "GET",
        headers: {
          Accept: " application/json",
          "Content-Type": " application/json",
          Authorization: `Bearer ${props.token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setVideo(data.data);
          setVideotags(Array(data.data.length).fill([]));
          // setMiniature({})
          for (let idData in data.data) {
            getMiniature(data.data[idData].id);
          }
        });
    }
  };
  useEffect(() => {
    if (video.length !== 0) {
      let arr_tags = [];
      video.map((item, index) => {
        let result = videotagsall.filter(
          (person_A) => !item.video_tags.some((person_B) => person_A.tag === person_B.tag)
        );
        arr_tags.push(item.video_tags.concat(result));
      });
      setVideotags(arr_tags);
      setFlag(true);
    }
  }, [video]);
  const getAudio = (apply) => {
    if (apply) {
      fetch(variables.API_URL + "/api/audio/?filter=" + apply.person_id, {
        method: "GET",
        headers: {
          Accept: " application/json",
          "Content-Type": " application/json",
          Authorization: `Bearer ${props.token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setAudio(data.data);
        });
    }
  };

  const getRecords = async (apply) => {
    const response = await fetch(variables.API_URL + "/api/records/?personId=" + apply.person_id, {
      method: "GET",
      headers: {
        Accept: " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${props.token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      setRecords(data);
    }
  };

  function getMiniature(videoId) {
    fetch(variables.API_URL + "/api/miniature/?filter=" + videoId, {
      headers: {
        Authorization: `Bearer ${props.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data[0])
          setMiniature((prevState) => ({
            ...prevState,
            [videoId]: data.data[0].miniature,
          }));
      });
  }

  const getEducation = () => {
    fetch(variables.API_URL + "/api/education/?filter=" + applyId, {
      method: "GET",
      headers: {
        Accept: " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${props.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setEducation(data.data);
      });
  };

  const getExternalUrl = (videoId) => {
    fetch(variables.API_URL + "/api/post_external_video/" + videoId, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      body: JSON.stringify({
        username: props.username,
      }),
    })
      .then((data) => {
        getVideo();
      })
      .catch((err) => {
        alert(err);
      });
  };

  const sendApplyToCustomer = () => {
    setWait(true);
    let url = variables.API_URL + "/api/send_apply_to_customer/";
    axios
      .post(
        url,
        {
          applyId: applyId,
        },
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        }
      )
      .then((res) => {
        alert("Ссылка на кандидата отправлена в telegram!");
        setWait(false);
      })
      .catch((err) => {
        setWait(false);
      });
  };

  const sendApplyToSales = () => {
    setWait(true);
    let url = variables.API_URL + "/api/send_apply_to_sales/";
    axios
      .post(
        url,
        {
          applyId: applyId,
        },
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        }
      )
      .then((res) => {
        alert("Ссылка на кандидата отправлена в telegram!");
        setWait(false);
      })
      .catch((err) => {
        setWait(false);
      });
  };

  function createLPR() {
    lprService
      .createLPR(
        {
          lpr: {
            first_name: firstName,
            second_name: secondName,
            patronymic: patronymic,
            number: phone,
            company: {
              name: companyName,
              link: companyLink,
            },
            professional_area: {
              id: profArea,
            },
            professional_industry: {
              id: profIndustry,
            },
            professional_level: {
              id: profLevel.length > 0 ? profLevel : null,
            },
            person: apply.person_id,
            // "user": props.userId, DISABLED DUE INCOMPATIBILITY WITH OLDER LOGIN. USERID CAN BE NOT SET DUE TO OLD VERSION
            user: {
              id: jwt(props.token)?.id,
            },
            position: position,
            comment: comment,
            status: {
              id: status,
            },
          },
        },
        props.token
      )
      .then((result) => {
        setErrors("");
        handleClose();
      })
      .catch(function (errors) {
        setErrors(errors.response.data.data.results);
      });
  }

  const getImage = (image, folder) => {
    if (image) {
      if (folder) {
        return variables.API_URL + "/media/" + folder + "/" + image.split("/").pop();
      } else {
        return variables.API_URL + "/media/" + image.split("/").pop();
      }
    }
  };

  const getFile = (document) => {
    if (document) {
      // window.open(variables.API_URL + "/media/media/documents/" + document.split("/").pop(), "_blank");
      window.open(variables.API_URL + document, "_blank");
    }
  };
  const getVideoTagsAll = () => {
    let url = variables.API_URL + "/api/video_tags";
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((res) => {
        setVideotagsall(res.data.tags);
        setGroups(res.data.groups);
      });
  };
  useEffect(() => {
    getApply();
    getExp();
    getEducation();
    getPhoto();
    getImagesGallery();
    getVideoTagsAll();
  }, []);

  function handleClose() {
    setShowModal(false);
  }

  function handleCreate(e) {
    e.preventDefault();
    if (phoneDirty || firstNameDirty || secondNameDirty) {
    } else {
      createLPR();
    }
  }

  function handleShow() {
    setModalTitle("Добавить ЛПР");
    setPhone("7");
    setFirstName("");
    setSecondName("");
    setPatronymic("");
    setProfArea("");
    setProfIndustry("");
    setPosition("");
    setCompanyName("");
    setCompanyLink("");
    setProfLevel("");
    setErrors("");
    setPhoneDirty(false);
    setFirstNameDirty(false);
    setSecondNameDirty(false);
    setComment("");
    setStatus("");
    setShowModal(true);
  }

  function onChangePhone(value, country, e, formattedvalue) {
    setPhone(value);
  }

  function blurHandler(e, country) {
    switch (e.target.name) {
      case "phone":
        if ((startsWith(phone, country.dialCode) || startsWith(country.dialCode, phone)) && phone.length >= 11) {
          setPhoneDirty(false);
        } else {
          setPhoneDirty(true);
        }
        break;
    }
  }

  function blurHandlerName(e, name) {
    switch (name) {
      case "firstName":
        if (e.target.value.length > 0) {
          setFirstNameDirty(false);
        } else {
          setFirstNameDirty(true);
        }
        break;
      case "secondName":
        if (e.target.value.length > 0) {
          setSecondNameDirty(false);
        } else {
          setSecondNameDirty(true);
        }
        break;
    }
  }

  const addTimeToRecruter = (time) => {
    let url = variables.API_URL + "/api/add_time_to_recruter_by_recruter/";
    axios
      .post(
        url,
        {
          time: time,
          type: 1,
          comment: "normal phone call: " + time,
          recruter: props.userId,
          apply: applyId,
        },
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        }
      )
      .then((res) => {
        getApply();
        alert("Время добавлено!");
      });
  };

  const sendVideo = async (event) => {
    const files = event.target.files;
    if (!files.length) return;
    const video = files[0];

    let form_data = new FormData();
    form_data.append("video", video, video.name);
    let url = variables.API_URL + "/api/video_for_apply/" + applyId;
    setInputVideoStatus("load");
    axios
      .post(url, form_data, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((res) => {
        event.target.value = null;
        getVideo(apply);
        setInputVideoStatus("good");
      })
      .catch((err) => {
        event.target.value = null;
        setInputVideoStatus("err");
      });
  };

  const sendRecord = async (event) => {
    const files = event.target.files;
    if (!files.length) return;
    const record = files[0];

    let form_data = new FormData();
    form_data.append("record", record, record.name);
    let url = variables.API_URL + "/api/record_for_apply/" + applyId;
    setInputRecordStatus("load");
    axios
      .post(url, form_data, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((res) => {
        event.target.value = "";
        getRecords(apply);
        setInputRecordStatus("good");
      })
      .catch((err) => {
        event.target.value = "";
        setInputRecordStatus("err");
      });
  };

  const sendRecordedAudioStream = async () => {
    let form_data = new FormData();
    const extension = recordedAudioStream.type.split(";")[0].split("/")[1];
    const filename = `Live audio recording ${new Date()}.${extension}`;
    form_data.append("record", recordedAudioStream, filename);
    let url = variables.API_URL + "/api/record_for_apply/" + applyId;

    await axios
      .post(url, form_data, {
        headers: {
          "Content-Type": "audio/webm",
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((res) => {
        getRecords(apply);
        setInputAudioStreamStatus("not_recording");
      })
      .catch((err) => {
        setInputAudioStreamStatus("err");
      });
  };

  const saveRecordedAudioStream = () => {
    if (recordedAudioStream) {
      const downloadUrl = URL.createObjectURL(recordedAudioStream);
      const extension = recordedAudioStream.type.split(";")[0].split("/")[1];
      const filename = `Live audio recording ${new Date()}.${extension}`;

      let link = document.createElement("a");
      link.download = filename;
      link.href = downloadUrl;
      link.click();
    }
  };

  const toggleAudioStreamRecording = async () => {
    switch (inputAudioStreamStatus) {
      case "not_supported":
        alert("Запись с микрофона не поддерживается вашим браузером.");
        break;

      case "not_recording":
        await startRecording().then(() => setInputAudioStreamStatus("recording"));
        break;

      case "recording":
        await stopRecording()
          .then(() => setInputAudioStreamStatus("load"))
          .catch((err) => setInputAudioStreamStatus("not_recording"));
        break;

      case "err":
        saveRecordedAudioStream();
        setInputAudioStreamStatus("not_recording");
        break;
    }
  };

  const startRecording = async () => {
    const mimeType = "audio/webm";
    await navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((audioStream) => {
        const localAudioRecorder = new MediaRecorder(audioStream, { mimeType: mimeType });
        localAudioRecorder.ondataavailable = (e) => setRecordedAudioStream(e.data);
        localAudioRecorder.start();
        setAudioRecorder(localAudioRecorder);
      })
      .catch((err) => {
        alert("Отключите блокировку микрофона в настройках разрешений для этой страницы.");

        throw err;
      });
  };

  const stopRecording = async () => {
    if (audioRecorder.state === "recording") {
      audioRecorder.stop();
      audioRecorder.stream.getTracks().forEach((track) => track.stop());
    } else {
      alert("Произошла ошибка. Доступ к микрофону был блокирован во время записи.");

      throw new Error("Microphone was blocked during recording");
    }
  };

  useEffect(() => {
    if (!navigator.mediaDevices && navigator.mediaDevices.getUserMedia) setInputAudioStreamStatus("not_supported");
  }, []);

  useEffect(() => {
    if (recordedAudioStream && inputAudioStreamStatus === "load") {
      sendRecordedAudioStream();
    }
  }, [recordedAudioStream]);

  const updateName = async () => {
    let url = variables.API_URL + "/api/apply/" + applyId;
    await axios
      .put(
        url,
        {
          name: editedName,
          position: editedPosition,
        },
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        },
        {}
      )
      .then((res) => {
        let localApply = { ...apply };
        localApply.name = res.data.name;
        localApply.position = res.data.position;
        setApply(localApply);
        setIsEditingName(false);
      })
      .catch((err) => {});
  };

  const toggleIsEditingName = () => {
    setIsEditingName(isEditingName ? false : true);
  };

  useEffect(() => {
    if (isEditingName && apply) {
      setEditedName(apply.name);
      setEditedPosition(apply.position);
    }
  }, [isEditingName]);

  const handleDeleteVideo = async (videoId) => {
    if (window.confirm("Вы уверены, что хотите удалить это видео?")) {
      let url = `${variables.API_URL}/api/deleteVideo/${videoId}`;
      try {
        const response = await axios.delete(url, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        });
        if (response.status === 200) {
          // Проверка на статус 204, если вы используете HTTP_204_NO_CONTENT
          setVideo((prevVideos) => prevVideos.filter((video) => video.id !== videoId));
          alert("Видео успешно удалено!");
        }
      } catch (error) {
        console.error("Ошибка при удалении видео:", error);
        alert("Не удалось удалить видео.");
      }
    }
  };
  const handleDeleteImage = async (imageId) => {
    if (window.confirm("Вы уверены, что хотите удалить это фото?")) {
      let url = `${variables.API_URL}/api/delete_image_gallery/${imageId}`;
      try {
        const response = await axios.delete(url, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        });
        if (response.status === 200) {
          setImagesGallery((prevImage) => prevImage.filter((imagesGallery) => imagesGallery.id !== imageId));
          alert("Фото успешно удалено!");
        }
      } catch (error) {
        console.error("Ошибка при удалении фото:", error);
        alert("Не удалось удалить фото.");
      }
    }
  };

  const handleDeleteComment = async (ComId) => {
    if (window.confirm("Вы уверены, что хотите удалить эту характеристику?")) {
      let url = `${variables.API_URL}/api/delete_comment_rec/${ComId}`;
      try {
        const response = await axios.delete(url, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        });
        if (response.status === 200) {
          setApply((prevApply) => ({
            ...prevApply, // Скопировать все существующие поля из apply
            characteristics: prevApply.characteristics.filter((item) => item.id !== ComId),
          }));
          alert("Характеристика успешно удалена!");
        }
      } catch (error) {
        console.error("Ошибка при удалении характеристики:", error);
        alert("Не удалось удалить характеристику.");
      }
    }
  };
  const handleDeleteAudioBack = async (AudioId) => {
    if (window.confirm("Вы уверены, что хотите удалить эту обратную связь?")) {
      let url = `${variables.API_URL}/api/delete_audio/${AudioId}`;
      try {
        const response = await axios.delete(url, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        });
        if (response.status === 200) {
          setAudio((prevAudio) => prevAudio.filter((audio) => audio.id !== AudioId));
          alert("Обратная связь успешно удалена!");
        }
      } catch (error) {
        console.error("Ошибка при удалении обратной связи:", error);
        alert("Не удалось удалить обратную связь.");
      }
    }
  };
  const handleDeleteRecordBack = async (RecordId) => {
    if (window.confirm("Вы уверены, что хотите удалить эту запись?")) {
      let url = `${variables.API_URL}/api/delete_record/${RecordId}`;
      try {
        const response = await axios.delete(url, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        });
        if (response.status === 200) {
          setRecords((prevRecords) => prevRecords.filter((records) => records.id !== RecordId));
          alert("Запись успешно удалена!");
        }
      } catch (error) {
        console.error("Ошибка при удалении записи:", error);
        alert("Не удалось удалить запись.");
      }
    }
  };
  const handleDeleteNetworks = async (NetworkId) => {
    if (window.confirm("Вы уверены, что хотите удалить соцсеть?")) {
      let url = `${variables.API_URL}/api/delete_network/${NetworkId}`;
      try {
        const response = await axios.delete(url, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        });
        if (response.status === 200) {
          setSocialNetworks((prevNetworks) => prevNetworks.filter((socialNetworks) => socialNetworks.id !== NetworkId));
          alert("Соцсеть успешно удалена!");
        }
      } catch (error) {
        console.error("Ошибка при удалении соцсети:", error);
        alert("Не удалось удалить соцсеть");
      }
    }
  };

  const handleDeleteDocuments = async (DocumentId) => {
    if (window.confirm("Вы уверены, что хотите удалить Документ?")) {
      let url = `${variables.API_URL}/api/delete_document/${DocumentId}`;
      try {
        const response = await axios.delete(url, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        });
        if (response.status === 200) {
          setDocuments((prevDocuments) => prevDocuments.filter((documents) => documents.id !== DocumentId));
          alert("Документ успешно удален!");
        }
      } catch (error) {
        console.error("Ошибка при удалении документа:", error);
        alert("Не удалось удалить документ.");
      }
    }
  };

  var previousPhotoHandler = () => {
    var index = apply?.photos?.findIndex((item) => item?.id == currentPhotoId);
    if (index != -1 && index - 1 >= 0) {
      setCurrentPhotoId(apply?.photos?.at(index - 1)?.id);
    }
  };

  var nextPhotoHandler = () => {
    var index = apply?.photos?.findIndex((item) => item?.id == currentPhotoId);
    if (index != -1 && index + 1 < apply?.photos?.length) {
      setCurrentPhotoId(apply?.photos?.at(index + 1)?.id);
    }
  };

  return (
    <div className="mt-4" style={{ textAlign: "left" }}>
      <Button height={"45px"} className="mb-2" variant="primary" onClick={() => navigate(-1)}>
        Назад
      </Button>
      <Button
        height={"45px"}
        className="mb-2 mx-2"
        variant="outline-primary"
        data-bs-toggle="modal"
        data-bs-target="#statsistic_add_hour_modal"
      >
        + Добавить время
      </Button>
      {/* <Button height={"45px"} className="mb-2 mx-2" variant="outline-primary" onClick={() => addTimeToRecruter(3)}>
        <i className="fa fa-phone fa-2xs" aria-hidden="true" onClick={null}></i> 3
      </Button>
      <Button height={"45px"} className="mb-2 mx-2" variant="outline-primary" onClick={() => addTimeToRecruter(6)}>
        <i className="fa fa-phone fa-2xs" aria-hidden="true" onClick={null}></i> 6
      </Button> */}
      <div className="row">
        <div className="col-5">
          <div className="p-5 bg-light border rounded-3 mb-3">
            {isEditingName ? (
              <div className="candidate-name-wrapper mb-3">
                <input
                  type="text"
                  className="candidate-name-input"
                  value={editedName}
                  onChange={(e) => setEditedName(e.target.value)}
                />
                <span className="candidate-name-edit" onClick={updateName}>
                  <i className="fa fa-solid fa-check fa-lg"></i>
                </span>
                <span className="candidate-name-edit" onClick={() => setIsEditingName(false)}>
                  <i className="fa fa-solid fa-times fa-lg"></i>
                </span>
              </div>
            ) : (
              <div className="candidate-name-wrapper mb-3">
                <h3 className="candidate-name-text">{apply.name}</h3>
                <span className="candidate-name-edit" onClick={toggleIsEditingName}>
                  <i className="fa fa-solid fa-pencil fa-lg"></i>
                </span>
              </div>
            )}

            {/* {photo ? (
              <img style={{ width: "80%", marginLeft: "0.5rem" }} src={getImage(photo.image)} alt="Profile" />
            ) : null} */}

            {currentPhotoId && (
              <>
                <div className="avatar_galary">
                  <div style={{ minWidth: "40px" }}>
                    {!(apply?.photos?.at(0)?.id == currentPhotoId) && (
                      <button
                        onClick={previousPhotoHandler}
                        // disabled={apply?.photos?.at(0)?.id == currentPhotoId}
                      >
                        {"<"}
                      </button>
                    )}
                  </div>

                  <img src={getImage(apply?.photos?.find((item) => item?.id == currentPhotoId)?.src)} alt="Profile" />
                  <div style={{ minWidth: "40px" }}>
                    {!(apply?.photos?.at(-1)?.id == currentPhotoId) && (
                      <button
                        onClick={nextPhotoHandler}
                        // disabled={apply?.photos?.at(-1)?.id == currentPhotoId}
                      >
                        {">"}
                      </button>
                    )}
                  </div>
                </div>
              </>
            )}
            {!isEditingName ? (
              <h4 className="mb-0 mt-2">{apply.position}</h4>
            ) : (
              <div className="candidate-name-wrapper mb-3">
                <input
                  type="text"
                  className="candidate-name-input"
                  value={editedPosition}
                  onChange={(e) => setEditedPosition(e.target.value)}
                />
              </div>
            )}
            <p className="mb-3">{apply.age}</p>
            <ul className="list-unstyled">
              {!!apply.birth && (
                <li>
                  <b>Дата рождения: </b>
                  {apply.birth}
                </li>
              )}
              <li>
                <b>Номер телефона: </b>
                {apply?.phone?.replace("https://wa.me/", "")}
              </li>
              {apply?.mail && (
                <li>
                  <b>Mail: </b>
                  {apply?.mail}
                </li>
              )}
              {apply?.additional_phone && apply?.additional_phone != "-" && (
                <li>
                  <b>Дополнительный телефона: </b>
                  {apply?.additional_phone}
                </li>
              )}
              {apply?.phone?.replace("https://wa.me/", "") && apply?.phone?.replace("https://wa.me/", "") != "-" && (
                <li>
                  <b>Вован: </b>
                  <a href={`https://web.wo1.me/?number=${apply?.phone?.replace("https://wa.me/", "")}`} target="_blank">
                    Ссылка
                  </a>
                </li>
              )}
              {apply?.additional_phone && apply?.additional_phone != "-" && (
                <li>
                  <b>Вован (на дополнительный телефон): </b>
                  <a href={`https://web.wo1.me/?number=${apply?.additional_phone}`} target="_blank">
                    Ссылка
                  </a>
                </li>
              )}
              <li>
                <b>Статус: </b>
                {apply.status}
              </li>
              <li>
                <b>Добавлен как: </b>
                {apply?.begin || ""}
              </li>
              {apply?.reject_reasons && (
                <ul>
                  <li>
                    <b>Причины отказа: </b>
                    {apply.reject_reasons}
                  </li>
                </ul>
              )}
              <li>
                <b>Рекрутер: </b>
                {apply.rec_account}
              </li>
              <li>
                <b>Город: </b>
                {apply?.city} {apply?.relocate_trips && ", " + apply.relocate_trips}
              </li>
              {apply?.metro && (
                <ul>
                  <li>
                    <b>Метро: </b>
                    {apply.metro}
                  </li>
                </ul>
              )}
              <li>
                <b>Зарплата: </b>
                {apply.salary}
              </li>
              <li>
                <b>Специализация: </b>
                {apply.specializations}
              </li>
              <li>
                <b>Занятость: </b>
                {apply.employment}
              </li>
              {apply?.date && (
                <li>
                  <b>Дата добавления: </b>
                  {apply.date}
                </li>
              )}
              {!!apply.last_active && (
                <li>
                  <b>Последняя активность: </b>
                  {apply.last_active}
                </li>
              )}
              <li>
                <b>ID вакансии: </b>
                {apply.vacancy_id}
              </li>
              <li>
                <b>Заказчик: </b>
                {apply.company}
              </li>
              <li>
                <b>Вакансия: </b>
                {apply.vacancy}
              </li>
              <li>
                <b>Person ID: </b>
                <a href={window.location.origin + "/" + apply.person_id}>{apply.person_id}</a>
              </li>
              {apply?.full_url && (
                <li>
                  <b>Резюме на: </b>

                  <a href={apply?.full_url?.replaceAll(/\?.*/g, "") || ""} target="_blank" rel="noopener noreferrer">
                    HeadHunter
                  </a>
                </li>
              )}

              {apply?.languages && (
                <li style={{ whiteSpace: "break-spaces" }}>
                  <b>Языки: </b>
                  {apply?.languages.replaceAll("     ", "\n")}
                </li>
              )}
              {socialNetworks.length ? (
                <li>
                  {socialNetworks.map((item) => {
                    return (
                      <ul className="list-unstyled" key={item.id}>
                        <li>
                          <b>{item.name}: </b>
                          <a href={item.link.match(/^https?:/) ? item.link : "//" + item.link} target="_blank">
                            {/* {`${item.link}`} */}
                            Ссылка
                          </a>
                          {props.isSuperUser === "2" && (
                            <Button className="trash-button" onClick={() => handleDeleteNetworks(item.id)}>
                              <TrashIcon />
                            </Button>
                          )}
                        </li>
                      </ul>
                    );
                  })}
                </li>
              ) : null}

              {!!apply?.lprs?.length && (
                <li>
                  <b>ЛПРы: </b>
                  {apply.lprs.map((item) => (
                    <div style={{ display: "block", width: "fit-content" }} key={item.id}>
                      {item.second_name} {item.first_name} {item.patronymic}, {item.company__name}
                    </div>
                  ))}
                </li>
              )}

              {!!apply?.other_applies?.length && (
                <li>
                  <b>Другие отклики: </b>
                  {apply.other_applies.map((item, index) => (
                    <a
                      style={{ display: "block", width: "fit-content" }}
                      key={index}
                      href={`/apply/${item?.id}`}
                      target="_blank"
                    >
                      {item.position}, {item.vacancy_id__name}
                    </a>
                  ))}
                </li>
              )}
            </ul>
          </div>
          <div className="button_additional_area">
            {inputVideoStatus !== "load" && (
              <input
                id="input_video"
                type="file"
                className="input__file"
                accept="video/*"
                multiple
                onChange={sendVideo}
              />
            )}

            <label htmlFor="input_video" className="btn btn-light btn-outline-primary additional_button">
              {(() => {
                if (inputVideoStatus === "text") {
                  return <p className="m-0">Добавить видео</p>;
                } else if (inputVideoStatus === "load") {
                  return <div className="preloader rot"></div>;
                } else if (inputVideoStatus === "good") {
                  return <i className="fa fa-check fa-2x m-0" aria-hidden="true"></i>;
                } else if (inputVideoStatus === "err") {
                  return <i className="fa fa-times fa-2x m-0" aria-hidden="true"></i>;
                }
              })()}
            </label>

            <label
              className="btn btn-light btn-outline-primary additional_button"
              onClick={() => {
                if (inputAudioStatus === "load") return;
                setAddAudioModal(true);
              }}
            >
              {(() => {
                if (inputAudioStatus === "text") {
                  return <p className="m-0">Добавить ОС</p>;
                } else if (inputAudioStatus === "load") {
                  return <div className="preloader rot"></div>;
                } else if (inputAudioStatus === "good") {
                  return <i className="fa fa-check fa-2x m-0" aria-hidden="true"></i>;
                } else if (inputAudioStatus === "err") {
                  return <i className="fa fa-times fa-2x m-0" aria-hidden="true"></i>;
                }
              })()}
            </label>
            {/* <input
              id="input_audio"
              type="file"
              className="input__file"
              accept=".ogg"
              multiple
              onChange={() => {
                setAddAudioModal(true);
              }}
            />
            <label htmlFor="input_audio" className="btn btn-light btn-outline-primary additional_button">
              Добавить обратную связь
            </label> */}

            {/* <button className="btn btn-light btn-outline-primary m-0">Добавить запись</button> */}
            {inputRecordStatus !== "load" && (
              <input
                id="input_record"
                type="file"
                className="input__file"
                accept="audio/*"
                multiple
                onChange={(event) => {
                  sendRecord(event);
                }}
              />
            )}

            <label htmlFor="input_record" className="btn btn-light btn-outline-primary additional_button">
              {(() => {
                if (inputRecordStatus === "text") {
                  return <p className="m-0">Добавить запись</p>;
                } else if (inputRecordStatus === "load") {
                  return <div className="preloader rot"></div>;
                } else if (inputRecordStatus === "good") {
                  return <i className="fa fa-check fa-2x m-0" aria-hidden="true"></i>;
                } else if (inputRecordStatus === "err") {
                  return <i className="fa fa-times fa-2x m-0" aria-hidden="true"></i>;
                }
              })()}
            </label>

            {inputAudioStreamStatus !== "load" && (
              <button
                id="input_audio_stream"
                className="input__file"
                onClick={(event) => {
                  toggleAudioStreamRecording();
                }}
              />
            )}

            <label htmlFor="input_audio_stream" className="btn btn-light btn-outline-primary additional_button mic-btn">
              {(() => {
                if (inputAudioStreamStatus === "not_recording") {
                  return <i className="fa fa-solid fa-microphone fa-lg m-0"></i>;
                } else if (inputAudioStreamStatus === "recording") {
                  return <i className="fa fa-solid fa-square fa-lg m-0"></i>;
                } else if (inputAudioStreamStatus === "load") {
                  return <div className="preloader rot"></div>;
                } else if (inputAudioStreamStatus === "not_supported") {
                  return <i className="fa fa-solid fa-microphone-slash fa-lg m-0"></i>;
                } else if (inputAudioStreamStatus === "err") {
                  return <i className="fa fa-times fa-lg m-0"></i>;
                }
              })()}
            </label>
          </div>

          {!!apply?.additional_action && (
            <div>
              <h4>Дополнительные действия:</h4>
              {apply?.additional_action?.map((item) => (
                <div key={item.id}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "15px 0",
                    }}
                  >
                    <span className="comment-recruter">{item.recruter}</span>
                    <span className="comment-recruter">{item.date}</span>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <p className="comment-text" style={{ whiteSpace: "break-spaces" }}>
                      {item.type}
                    </p>
                    <p className="comment-text" style={{ whiteSpace: "break-spaces" }}>
                      Минут: {item.time}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div>
            {windowWidth >= 701 && video?.length ? (
              <>
                <h4>Видео:</h4>
                {video.map((item, index) => (
                  <div key={item.id} className="bottom-border-div">
                    <div className="apply_tags_additional_area">
                      {videotags[index]
                        .filter((tag) => tag.status === "active")
                        .map((obj, index) => (
                          <a key={index} className="btn btn-outline-primary resize_font disabled">
                            {"#" + obj.tag}
                          </a>
                        ))}
                    </div>
                    <a href={item.video_url + "?t=" + props.token} target="_blank">
                      <img
                        style={{ width: "100%", cursor: "pointer" }}
                        src={getImage(miniature[item.id.toString()]) + "?size=450x250"}
                        alt="Profile"
                      />
                    </a>
                    <p style={{ wordWrap: "break-word", wordBreak: "break-all" }}>
                      <a href={item.video_url + "?t=" + props.token} target="_blank">
                        {item.video_name}
                      </a>
                    </p>
                    <button
                      style={{ width: "max-content" }}
                      className="btn btn-outline-primary active resize_font"
                      onClick={() => getExternalUrl(item.id)}
                    >
                      Внешняя ссылка
                    </button>

                    <Button
                      id="video_tag_button"
                      className="btn-primary m-2"
                      data-bs-toggle="modal"
                      data-bs-target={"#statsistic_add_video_tag_modal" + index}
                    >
                      #
                    </Button>
                    <AddVideoTagModal
                      key={index}
                      token={props.token}
                      user={props.userId}
                      video={item.id}
                      setVideoTags={setVideotags}
                      videotags={videotags}
                      video_index={index}
                      flag={flag}
                      setFlag={setFlag}
                      taggroups={groups}
                    />

                    {props.isSuperUser === "2" && (
                      <Button className="trash-button" onClick={() => handleDeleteVideo(item.id)}>
                        <TrashIcon />
                      </Button>
                    )}
                  </div>
                ))}
              </>
            ) : null}

            {windowWidth >= 701 && audio?.length ? (
              <>
                <h4>Обратная связь:</h4>
                {audio.map((item) => (
                  <div className="audio_back">
                    <p>
                      <a
                        key={item.id}
                        href={item.audio_url + "?t=" + props.token}
                        target="_blank"
                        style={{ wordWrap: "break-word", wordBreak: "break-all" }}
                      >
                        {item.audio_name}
                      </a>
                    </p>
                    {props.isSuperUser === "2" && (
                      <Button className="trash-button" onClick={() => handleDeleteAudioBack(item.id)}>
                        <TrashIcon />
                      </Button>
                    )}
                  </div>
                ))}
              </>
            ) : null}
            {windowWidth >= 701 && !!records?.length ? (
              <>
                <h4>Записи:</h4>
                {records.map((item) => (
                  <div className="record_back">
                    <p>
                      <a
                        key={item.id}
                        href={item.record_url + "?t=" + props.token}
                        target="_blank"
                        style={{ wordWrap: "break-word", wordBreak: "break-all" }}
                      >
                        {item.record_name}
                      </a>
                    </p>
                    {props.isSuperUser === "2" && (
                      <Button className="trash-button" onClick={() => handleDeleteRecordBack(item.id)}>
                        <TrashIcon />
                      </Button>
                    )}
                  </div>
                ))}
              </>
            ) : null}
          </div>
        </div>
        <div className="col-7">
          <div className={"d-flex justify-content-end apply_button"}>
            {/* <div className={"d-flex justify-content-around apply_button"}> */}
            {/* <h4 className="mt-1 text-center">Соответствие:</h4>
            <h4 className="mt-1 text-center check_list_score">
              {apply.percentage_compliance}%
            </h4> */}

            <button
              // disabled={waitResponse}
              className={"btn btn-light btn-outline-primary resize_font"}
              onClick={() => {
                setShowSocialNetwork(true);
              }}
            >
              Соцсети
            </button>

            <button
              disabled={waitResponse}
              className={"btn btn-light btn-outline-primary resize_font"}
              onClick={sendApplyToCustomer}
              style={{ width: "40px", height: "40px" }}
              title="Внешняя ссылка для заказчика"
            >
              <i className="fa fa-paper-plane fa-xs"></i>
            </button>
            <button
              disabled={waitResponse}
              className={"btn btn-light btn-outline-primary resize_font"}
              onClick={sendApplyToSales}
              style={{ width: "40px", height: "40px" }}
              title="Внешняя ссылка для отдела продаж"
            >
              <i className="fa fa fa-usd fa-xs"></i>
            </button>
            <button
              className={"btn btn-light btn-outline-primary resize_font"}
              onClick={() => {
                setShowModalCheckList({
                  ...modalCheckList,
                  show: true,
                });
              }}
            >
              Чек-лист
            </button>
            <button
              className={"btn btn-light btn-outline-primary resize_font"}
              data-bs-toggle="modal"
              data-bs-target="#add_candidate_modal"
            >
              Кандидат
            </button>
            <button
              className={"btn btn-light btn-outline-primary resize_font"}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={handleShow}
            >
              Добавить ЛПР
            </button>
          </div>

          {/* <div className="mt-3 d-flex">
            <h4 className="text-center">Соответствие:</h4>
            <h4 className="text-center check_list_score">{apply.percentage_compliance}%</h4>
          </div> */}

          <h4>О себе:</h4>
          <p style={{ whiteSpace: "break-spaces" }}>{apply?.about?.replaceAll("<br/>", "\n")}</p>

          {apply?.letter && (
            <div>
              <h4>Сопроводительное письмо:</h4>
              <p style={{ whiteSpace: "break-spaces" }}>{apply?.letter?.replaceAll("<br/>", "\n")}</p>
            </div>
          )}

          <h4>Изменение статуса кандидата:</h4>
          {apply?.candidate_logs?.map((item) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="comment-text" key={item.id} style={{ whiteSpace: "break-spaces" }}>
                {item.change_from !== "-" ? 'Переведён из стадии "' + item.change_from + '" в стадию ' : ""}"
                {item.change_to}"
              </p>
              <span className="comment-recruter">{item.date}</span>
            </div>
          ))}

          <h4>Изображения:</h4>
          <div className="image_area">
            {imagesGallery.map((item, index) => {
              return (
                <div className="image_miniature_wrapper" key={item.id}>
                  <a href={getImage(item.image, "apply_images")} target="_blank">
                    <img
                      className="image_miniature"
                      src={getImage(item.image, "apply_images") + "?size=125x125"}
                      alt=""
                    />
                  </a>
                  {props.isSuperUser === "2" && (
                    <button className="trash-button" onClick={() => handleDeleteImage(item.id)}>
                      <TrashIcon />
                    </button>
                  )}
                </div>
              );
            })}
            <input
              type="file"
              id="input__file"
              className="input__file"
              accept="image/*"
              multiple
              onChange={postImagesGallery}
            />
            <label htmlFor="input__file" className="image_miniature_wrapper image_miniature_plus">
              +
            </label>
          </div>
          <h4>Теги:</h4>
          <div className="apply_tags_additional_area">
            {tags.map((obj, index) => (
              <a key={index} className="btn btn-outline-primary active">
                {"#" + obj.tag}
              </a>
            ))}
          </div>
          <div className="image_area">
            <Button
              id="tag_button"
              className="tag__button image_miniature_wrapper image_miniature_plus"
              variant="outline-primary"
              data-bs-toggle="modal"
              data-bs-target="#statsistic_add_tag_modal"
            >
              #
            </Button>
          </div>

          {/* {!!documents.length > 0 && ( */}
          <>
            <h4>Документы:</h4>
            <table className="table mb-5" style={{ width: "100%", verticalAlign: "middle" }}>
              <thead>
                <tr>
                  <th style={{ width: "15%" }}>Файл</th>
                  <th style={{ width: "40%" }}>Название</th>
                  <th style={{ width: "20%" }}>Автор</th>
                  <th>Дата добавления</th>
                  {/*{props.isSuperUser === '2' &&*/}
                  {/*    <th>Удаление</th>}*/}
                </tr>
              </thead>
              <tbody>
                {documents.map((document) => {
                  return (
                    // <div className="image_documents_wrapper" >
                    <tr className="image_documents_wrapper" key={document.id}>
                      <td>
                        <i
                          onClick={() => getFile(document.document)}
                          className="fa fa-file-o fa-2x file_icon"
                          style={{ marginLeft: "5px" }}
                          aria-hidden="true"
                        ></i>
                      </td>
                      <td>{document.filename}</td>
                      <td>{document.user}</td>
                      <td>{document.date}</td>
                      {props.isSuperUser === "2" && (
                        <td>
                          <button className="trash-button" onClick={() => handleDeleteDocuments(document.id)}>
                            <TrashIcon />
                          </button>
                        </td>
                      )}
                    </tr>
                    // </div>
                  );
                })}

                <tr style={{ lineHeight: "30px", verticalAlign: "top" }}>
                  <td>
                    {showDownloadFilePreloader ? (
                      <div className="preloader rot" style={{ marginLeft: "5px" }}></div>
                    ) : (
                      <>
                        <input
                          id="input_document"
                          type="file"
                          className="input__file"
                          // accept="video/*"
                          multiple
                          onChange={sendDocuments}
                        />

                        <label htmlFor="input_document" className="add_document_lb_plus">
                          +
                        </label>
                      </>
                    )}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </>
          {/* )} */}

          <div className="comment-header">
            <h4>Характеристика рекрутера:</h4>
            <h4 className="plus" data-bs-toggle="modal" data-bs-target="#add_characteristic_modal">
              +
            </h4>
          </div>
          {apply?.characteristics?.map((item) => (
            <div className="comment-rec">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "15px 0",
                }}
              >
                <span className="comment-recruter">{item.recruter}</span>
                <span className="comment-recruter">{item.date}</span>
              </div>
              <p className="comment-text" key={item.id} style={{ whiteSpace: "break-spaces" }}>
                {item.characteristic_text}
              </p>
              {props.isSuperUser === "2" && (
                <Button className="trash-button" onClick={() => handleDeleteComment(item.id)}>
                  <TrashIcon />
                </Button>
              )}
            </div>
          ))}

          <h4>Образование:</h4>
          {education?.length ? (
            <table className="table">
              <thead>
                <tr>
                  <th>Университет</th>
                  <th>Специальность</th>
                  <th>Дата выпуска</th>
                </tr>
              </thead>
              <tbody>
                {education.map((edu) => (
                  <tr key={edu.id}>
                    <td>{edu.university}</td>
                    <td>{edu.speciality}</td>
                    <td>{edu.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h6>Без образования</h6>
          )}
          <h4>Опыт работы:</h4>
          <div style={{ fontSize: "20px", fontWeight: "600" }}>{apply.full_experience}</div>
          <div style={{ fontSize: "16px", fontWeight: "500" }}>Количество мест работы: {apply.experience_count}</div>
          <div style={{ fontSize: "16px", fontWeight: "500", marginBottom: "16px" }}>
            Средняя продолжительность работы: {apply.experience_average} лет
          </div>

          {exp?.length ? (
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>Дата</th>
                  <th>Работа</th>
                </tr>
              </thead>
              <tbody>
                {exp.map((exp) => (
                  <tr key={exp.id}>
                    <td style={{ width: "20%" }}>
                      {exp.date}
                      <br />
                      <div style={{ color: "#888" }}>{exp.time}</div>
                    </td>
                    <td className="job-decription">
                      <p>
                        <strong>{exp.company}</strong>
                      </p>
                      {!!exp.company_url && (
                        <a href={exp.company_url} target="_blank">
                          {exp.company_url}
                        </a>
                      )}
                      <p>{exp.company_description}</p>
                      <br />
                      <p>
                        <strong>{exp.position}</strong>
                      </p>
                      <br />
                      <p style={{ whiteSpace: "pre-wrap" }}>{exp.description.replaceAll("<br/>", "\n")}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h6>Без опыта работы</h6>
          )}
          {!!apply?.history?.length && (
            <>
              <h4>История:</h4>
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ width: "30%" }}>Дата</th>
                    <th>Вакансия</th>
                  </tr>
                </thead>
                <tbody>
                  {apply?.history.map((item) => (
                    <tr key={item.id}>
                      <td style={{ width: "30%" }}>
                        {item.date}
                        <br />
                        {item.conclusion}
                      </td>
                      <td className="vacancy-position">
                        {item?.vacancy_url ? (
                          <a href={item?.vacancy_url?.split("?")[0] || ""} target="_blank" rel="noopener noreferrer">
                            {item?.position}
                          </a>
                        ) : (
                          item?.position
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
      <div>
        {windowWidth < 701 && video?.length ? (
          <>
            <h4>Видео:</h4>
            {video.map((item) => (
              <div key={item.id}>
                <a href={item.video_url + "?t=" + props.token} target="_blank">
                  <img
                    style={{ width: "100%", cursor: "pointer" }}
                    src={getImage(miniature[item.id.toString()]) + "?size=450x250"}
                    alt="Profile"
                  />
                </a>
                <p style={{ wordWrap: "break-word", wordBreak: "break-all" }}>
                  <a href={item.video_url + "?t=" + props.token} target="_blank">
                    {item.video_name}
                  </a>
                </p>
                <button
                  style={{ width: "max-content" }}
                  className="btn btn-primary m-2"
                  onClick={() => getExternalUrl(item.id)}
                >
                  Внешняя ссылка
                </button>
              </div>
            ))}
          </>
        ) : null}

        {windowWidth < 701 && audio?.length ? (
          <>
            <h4>Обратная связь:</h4>
            {audio.map((item) => (
              <p>
                <a
                  key={item.id}
                  href={item.audio_url + "?t=" + props.token}
                  target="_blank"
                  style={{ wordWrap: "break-word", wordBreak: "break-all" }}
                >
                  {item.audio_name}
                </a>
              </p>
            ))}
          </>
        ) : null}

        {windowWidth < 701 && records?.length ? (
          <>
            <h4>Записи:</h4>
            {records.map((item) => (
              <p>
                <a
                  key={item.id}
                  href={item.record_url + "?t=" + props.token}
                  target="_blank"
                  style={{ wordWrap: "break-word", wordBreak: "break-all" }}
                >
                  {item.record_name}
                </a>
              </p>
            ))}
          </>
        ) : null}
      </div>
      <div className={"container modal-content rounded-5 shadow mt-2"}>
        <Modal show={showModal} onHide={handleClose} className={"modal fade"} backdrop={"static"}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h2 className="fw-bold mb-0">{modalTitle}</h2>
            </Modal.Title>
          </Modal.Header>
          <form onSubmit={handleCreate}>
            <Modal.Body>
              {errors && (
                <div className="alert alert-danger" role="alert">
                  {<p>Что-то пошло не так!</p>}
                  {/*{errors?.first_name && <p>Имя обязательное поле!</p>}*/}
                  {/*{errors?.second_name && <p>Фамилия обязательное поле!</p>}*/}
                  {/*{errors?.professional_area && <p>Сфера обязательное поле!</p>}*/}
                  {/*{errors?.professional_industry && <p>Отрасль обязательное поле!</p>}*/}
                  {/*{errors?.company && <p>Имя без ссылки на сайт компании!</p>}*/}
                  {/*{errors?.number && <p className={"mb-0"}>Некорректный номер</p>}*/}
                </div>
              )}
              <div className={"mb-3"}>
                <PhoneInput
                  country={"ru"}
                  value={phone}
                  onChange={onChangePhone}
                  onBlur={blurHandler}
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  // containerClass="invalid-number"
                  inputClass={phoneDirty && "invalid-number"}
                  // dropdownClass={"invalid-number"}
                  buttonClass={phoneDirty && "invalid-number"}
                />
                {/*{phoneDirty && <p style={{visibility: phoneDirty ? 'visible' : 'hidden', color: 'red'}}*/}
                {/*                  className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled MuiFormHelperText-marginDense">Некорректный*/}
                {/*    номер!</p>}*/}
              </div>
              {/*<div className="input-group mb-3">*/}
              <Field
                id={2}
                message={"Фамилия"}
                type={"text"}
                value={secondName}
                required={""}
                onChange={(e) => setSecondName(e.target.value)}
              />
              <Field
                id={1}
                message="Имя"
                type="text"
                value={firstName}
                required={"True"}
                onBlur={(e) => blurHandlerName(e, "firstName")}
                onChange={(e) => setFirstName(e.target.value)}
                dirty={firstNameDirty}
                dirtyMessage={"Имя не может быть пустым!"}
              />
              <Field
                id={3}
                message={"Отчество"}
                type={"text"}
                value={patronymic}
                required={""}
                onChange={(e) => setPatronymic(e.target.value)}
              />
              <div className="form-floating mb-3">
                <SelectLPRStatus
                  value={status}
                  required
                  disabledefault={true}
                  onChange={(e) => setStatus(e.target.value)}
                  setStatus={setStatus}
                ></SelectLPRStatus>
              </div>
              <div className="form-floating mb-3">
                <SelectProfIndustry
                  value={profIndustry}
                  required
                  disablefault={"True"}
                  disabledefault={true}
                  onChange={(e) => setProfIndustry(e.target.value)}
                ></SelectProfIndustry>
              </div>
              <div className="form-floating mb-3">
                <SelectProfArea
                  value={profArea}
                  required
                  disabledefault={true}
                  onChange={(e) => setProfArea(e.target.value)}
                ></SelectProfArea>
              </div>
              <Field
                id={4}
                message={"Должность"}
                type={"text"}
                value={position}
                required={""}
                onChange={(e) => setPosition(e.target.value)}
              />
              <div className="form-floating mb-3">
                <SelectProfLevel
                  value={profLevel}
                  disabledefault={false}
                  onChange={(e) => setProfLevel(e.target.value)}
                />
              </div>
              <Field
                id={5}
                message={"Компания"}
                type={"text"}
                value={companyName}
                required={""}
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <Field
                id={6}
                message={"Ссылка на сайт компании"}
                type={"text"}
                value={companyLink}
                required={""}
                onChange={(e) => setCompanyLink(e.target.value)}
              />
              <div className="form-floating mb-3">
                <textarea
                  id={7}
                  className="form-control form-control-lg"
                  style={{ height: "100px" }}
                  placeholder="1"
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                  validations="True"
                />
                <label htmlFor={props.id}>{"Комментарий"}</label>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type={"submit"}>
                Создать
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
      <CandidateModal apply_id={applyId} token={props.token} />
      <CharacteristicModal apply_id={applyId} token={props.token} refreshApply={getApply} userId={props.userId} />
      {modalCheckList.show && (
        <ModalChekList
          token={props.token}
          apply={apply}
          isOpened={modalCheckList.show}
          onModelClose={() => {
            setShowModalCheckList({
              ...modalCheckList,
              show: false,
            });
          }}
          setApply={setApply}
        ></ModalChekList>
      )}

      {showSocialNetwork && (
        <ModalSocialNetwork
          apply={apply}
          token={props.token}
          socialNetworks={socialNetworks}
          isOpened={showSocialNetwork}
          onModalClose={() => {
            setShowSocialNetwork(false);
          }}
          addSocialNetwork={(item) => {
            setSocialNetworks([...socialNetworks, item]);
          }}
          setApply={setApply}
        ></ModalSocialNetwork>
      )}

      {addAudioModal && (
        <AddAudioModal
          // apply={apply}
          // token={props.token}
          // socialNetworks={socialNetworks}
          setInputAudioStatus={setInputAudioStatus}
          getAudio={getAudio}
          isOpened={addAudioModal}
          onModelClose={() => {
            setAddAudioModal(false);
          }}
          apply={apply}
          // addSocialNetwork={(item) => {
          //   setSocialNetworks([...socialNetworks, item]);
          // }}
          // setApply={setApply}
        ></AddAudioModal>
      )}

      <AddHourModal token={props.token} user={props.userId} apply={applyId} getApply={getApply} />
      <AddTagModal token={props.token} user={props.userId} apply={applyId} video={video} setApplyTags={setTags} />
    </div>
  );
}

const AddAudioModal = (props) => {
  const [phone, setPhone] = useState("");
  const [file, setFile] = useState("");
  const [status, setStatus] = useState("");

  // const [comment, setComment] = useState(props.apply.item.comment);
  // const [commentCorrect, setCommentCorrect] = useState(true);
  // useEffect(() => {
  //   setCommentCorrect(Boolean(comment.trim().length > 0));
  // }, [comment]);

  // const pushBtnApprove = (item) => {
  //   if (commentCorrect) {
  //     props.onModelClose();
  //     props.update({
  //       status: props.apply.status,
  //       comment: comment,
  //       viewed: props.apply.item.viewed,
  //     });
  //   }
  // };

  const sendAudio = async () => {
    if (!file.length) return;
    const audio = file[0];

    let form_data = new FormData();
    form_data.append("audio", audio, audio.name);
    form_data.append("phone", phone);
    let url = variables.API_URL + "/api/audio_for_apply/" + props.apply.id;
    props.setInputAudioStatus("load");
    axios
      .post(url, form_data, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((res) => {
        res.status == 201 ? setStatus("Добавлено!") : setStatus("Ошибка");
        props.getAudio(props.apply);
        setFile("");
        props.setInputAudioStatus("good");
      })
      .catch((err) => {
        props.setInputAudioStatus("err");
      });
  };

  return (
    <div>
      <Modal className="modal_approve" show={props.isOpened} onHide={props.onModelClose} style={{ marginTop: "10%" }}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="fw-bold mb-0">Добавить ОС</h2>
            <h5>{status}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PhoneInput
            style={{ flex: "1" }}
            country={"ru"}
            value={phone}
            onChange={(phone_n) => {
              setPhone(phone_n);
              setStatus("");
            }}
          />

          <input
            id="input_audio"
            type="file"
            className="input__file"
            accept="audio/*"
            onChange={(event) => {
              setFile(event.target.files);
              setStatus("");
            }}
          />
          <label htmlFor="input_audio" className="btn btn-light btn-outline-primary add_audio_lbl">
            Прикрепить аудиофайл
          </label>

          {/* <textarea
            className={commentCorrect ? "comment" : "comment void_comment"}
            rows="6"
            placeholder="Комментарий"
            onChange={(event) => {
              setComment(event.target.value);
            }}
            value={comment}
          ></textarea> */}
        </Modal.Body>
        {/* <Modal.Footer> */}

        {phone.length === 11 && file !== "" && (
          <button
            className="btn btn-primary float-start"
            onClick={async (event) => {
              await sendAudio();
              event.target.value = "";

              // props.onModelClose();
            }}
          >
            {/* {"Подтвердить " + (props.apply.status === "no" ? "НЕТ" : "ДА")} */}
            Добавить
          </button>
        )}

        {/* </Modal.Footer> */}
      </Modal>
    </div>
  );
};
