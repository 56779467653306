import { createSlice } from "@reduxjs/toolkit";


const vacancyFilterSlice = createSlice({
    name: 'vacancy_filter',
    initialState: {
        statusFilter: [],
        stageFilter: [],
        recruterFilter: [],
        companyFilter: [],
        showStat: true,
        hasCandidates: 'no',
    },
    reducers: {
        setVacancyFilter(state, action) {
            if (action.payload.statusFilter) {
                if (!state.statusFilter.includes(action.payload.statusFilter)) {
                    state.statusFilter.push(action.payload.statusFilter)
                } else {
                    state.statusFilter = state.statusFilter.filter((item) => item !== action.payload.statusFilter);
                }
            }
            if (action.payload.stageFilter) {
                if (!state.stageFilter.includes(action.payload.stageFilter)) {
                    state.stageFilter.push(action.payload.stageFilter)
                } else {
                    state.stageFilter = state.stageFilter.filter((item) => item !== action.payload.stageFilter);
                }
            }
            if (action.payload.recruterFilter) {
                state.recruterFilter = action.payload.recruterFilter
            }

            if (action.payload.companyFilter) {
                state.companyFilter = action.payload.companyFilter
            }

            if (action.payload.hasCandidates) {
                state.hasCandidates = action.payload.hasCandidates
            }
            
        },

        setFullVacacnyFilter(state, action) {
            if (action.payload.statusFilter) {
                state.statusFilter = action.payload.statusFilter
                
            }
            if (action.payload.stageFilter) {
                state.stageFilter = action.payload.stageFilter
            }
            if (action.payload.recruterFilter) {
                state.recruterFilter = action.payload.recruterFilter
            }
            if (action.payload.companyFilter) {
                state.companyFilter = action.payload.companyFilter
            }
            if (action.payload.hasCandidates) {
                state.hasCandidates = action.payload.hasCandidates
            }
        },

        setShowStat(state, action) {
            state.showStat = action.payload.showStat
        },

        clearVacancyFilters(state) {
            state.statusFilter = []
            state.stageFilter = []
            state.recruterFilter = []
            state.showStat = true
            state.hasCandidates = 'no'
        },
    }
})

export const { clearVacancyFilters, setVacancyFilter, setFullVacacnyFilter, setShowStat } = vacancyFilterSlice.actions;

export default vacancyFilterSlice.reducer;