import axios from "axios";
import { variables } from "../../Variables";
import { resetUserSlice } from "../../store/userSlice";
import { saveTokenData } from "./LoginService";
import store from "../../store";

const axiosApiInstance = axios.create();
// axiosApiInstance.defaults.withCredentials = true

const { fetch: originalFetch } = window;

window.fetch = async (...args) => {
  let [resource, config] = args;

  let response = await originalFetch(resource, config);
  if (response?.status == 403) {
    const access_token = await refreshAccessToken();
    config.headers["Authorization"] = "Bearer " + access_token;

    let response = await originalFetch(resource, config);
    return response;
  }
  return response;
};

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      originalRequest.headers["Authorization"] = "Bearer " + access_token;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

async function refreshAccessToken() {
  let url = variables.API_URL + "/api/refresh_token/";
  let token = await axios
    .post(url, {}, { withCredentials: true })
    .then((res) => {
      localStorage.setItem("token", JSON.stringify(res.data.token));
      saveTokenData(res.data.token);
      return res.data.token;
    })
    .catch((res) => {
      if (res?.response?.status == 403 || res?.response?.status == 500) {
        store.dispatch(resetUserSlice());
      }
    });
  return token;
}

export default axiosApiInstance;
