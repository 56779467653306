import React, {useState, useEffect} from 'react'
import { variables } from "../Variables";
import Select_1 from './Select.js'

const VacancyChangeModal = ({modalTitle, vacancy, isEdit, recruterOptions, statusList, stageList, token, refreshList}) => {

    const [company, setCompany] = useState("");
    const [name, setName] = useState("");
    const [vacancyId, setVacancyId] = useState('');
    const [respRecruter, setRespRecruter] = useState('')
    const [mentor, setMentor] = useState('')
    const [status, setStatus] = useState(1)
    const [stage, setStage] = useState(1)
    const [isDirector, setisDirector] = useState(false)
    const [candidateAmount, setCandidateAmount] = useState(' ');
    const [closeTime, setCloseTime] = useState(' ')

    useEffect(() => {
        setCompany(vacancy?.company?.name || "")
        setName(vacancy?.name || "")
        setVacancyId(vacancy?.id || '')
        setRespRecruter(vacancy?.recruter || recruterOptions[0]?.value || '')
        setMentor(vacancy?.curator || '')
        setStatus(vacancy?.status || 1) 
        setStage(vacancy?.stage || 1) 
        setisDirector(vacancy?.is_director || false) 
        setCandidateAmount(vacancy?.candidate_count || ' ')
        setCloseTime(vacancy?.closed_time ||' ')
    }, [vacancy])

    const editVacancy = () => {
        fetch(variables.API_URL + '/api/vacancy/' + vacancyId, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                id: vacancyId,
                name: name,
                company: company,
                candidate_count: candidateAmount !== ' ' ? candidateAmount : 0,
                recruter: respRecruter,
                curator: mentor,
                closed_time: closeTime !== ' ' ? closeTime : 0,
                is_director: isDirector,
                status: status,
                stage: stage,
            })
        })
            .then(res => {
                if (refreshList) refreshList()
            })
            .catch(err => {
                console.log(err);
                alert(err)
            })
    }

    function createVacancyClick() {
        fetch(variables.API_URL + '/api/vacancy/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                id: vacancyId,
                name: name,
                company: company,
                candidate_count: candidateAmount !== ' ' ? candidateAmount : 0,
                recruter: respRecruter,
                curator: mentor,
                closed_time: closeTime !== ' ' ? closeTime : 0 ,
                is_director: isDirector,
                status: status,
                stage: stage,
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.id[0] === 'vacancy with this id already exists.')
                    alert('Vacancy with this id already exists.')
                refreshList()

            })
            .catch(err => { console.log(err); alert(err) })
    }

    const changeName = (e) => {
        setName(e.target.value);
    }

    const changeCandidateAmount = (e) => {
        if (e.target.validity.valid) {
            setCandidateAmount(e.target.value.replace(/\D/g, ''))
        }
    }

    const changeID = (e) => {
        setVacancyId(e.target.value);
    }

    const changeCompany = (e) => {
        setCompany(e.target.value);
    }

    const changeRecruter = (e) => {
        setRespRecruter(e.target.value)
    }

    const changeMentor = (e) => {
        setMentor(e.target.value)
    }

    const changeStatus = (e) => {
        setStatus(e.target.value)
    }

    const changeStage = (e) => {
        setStage(e.target.value)
    }

    const changeCloseTime = (e) => {
        if (e.target.validity.valid) {
            setCloseTime(e.target.value.replace(/\D/g, ''))
        }
    }


    return (
        <div className='modal fade' id="exampleModal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centred">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{modalTitle}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" 
                                aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="input-group mb-3">
                                <span className="input-group-text">ID вакансии</span>
                                <input type="text" className='form-control' value={vacancyId} disabled={isEdit}
                                    onChange={changeID} />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Название вакансии</span>
                                <input type="text" className='form-control' value={name}
                                    onChange={changeName} />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Название компании</span>
                                <input type="text" className='form-control' value={company}
                                    onChange={changeCompany} />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Количество кандидатов</span>
                                <input type="number" className='form-control' value={candidateAmount} placeholder="Введите число"
                                    onChange={changeCandidateAmount} />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Ответственный рекрутер</span>
                                <Select_1 className='select-vacancy' value={respRecruter} onChange={changeRecruter} options={recruterOptions} />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Куратор</span>
                                <Select_1 className='select-vacancy' value={mentor} onChange={changeMentor} options={recruterOptions} placeholder='...' />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Статус вакансии</span>
                                <Select_1 className='select-vacancy' options={statusList} onChange={changeStatus} value={status} />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Стадия вакансии</span>
                                <Select_1 className='select-vacancy' options={stageList} onChange={changeStage} value={stage} />
                            </div>
                            <div className="input-group">
                                <span className="input-group-text">Плановый срок закрытия</span>
                                <input type="number" className='form-control' value={closeTime} placeholder="Введите количество дней" pattern="[0-9]*"
                                    onChange={changeCloseTime} />
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="input-group mb-3">
                                <input id="3123" type="checkbox" className="chackbox-vacancy" checked={isDirector} onChange={() => setisDirector(!isDirector)} />
                                <label className="chackbox-vacancy-label" htmlFor="3123">Директор</label>
                            </div>
                        </div>


                        {isEdit ? <button type="button" className="btn btn-primary float-start" data-bs-dismiss="modal" onClick={() => editVacancy(vacancyId)}>Изменить</button> :
                            <button type="button" className="btn btn-primary float-start" data-bs-dismiss="modal" onClick={() => createVacancyClick()}>Создать</button>}
                    </div>
                </div>
            </div>
    );
};

export default VacancyChangeModal;