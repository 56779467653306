import React, {useEffect, useLayoutEffect, useState, PureComponent} from "react";
import axios from '../Services/AxiosService';
import {useNavigate} from "react-router-dom";
import '../Statistic/Statistic.css'
import "./VacancyStatistic.css"
import {variables} from "../../Variables";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, LabelList, Cell} from 'recharts';
import { useSelector } from "react-redux";
// import StatisticSwitcher from "./StatisticSwitcher";
// import {StatisticAddHourModal} from "./StatisticAddHourModal";
import StatusSvg from "./StatusSvg"
import {colors} from "../Colors"

const ENTER_KEY = 13

export function VacancyStatistic(props) {
    const navigate = useNavigate();
    const statusFilterList = useSelector(state => state.vacancy_filter.statusFilter)
    const stageFilterList = useSelector(state => state.vacancy_filter.stageFilter)
    const recruterFilterList = useSelector(state => state.vacancy_filter.recruterFilter)
    const companyFilterList = useSelector(state => state.vacancy_filter.companyFilter)
    const hasCandidateFilter = useSelector(state => state.vacancy_filter.hasCandidates)

    const [barChartStatistic, setBarChartStatistic] = useState([]);


    useLayoutEffect(() => {
        refreshStatistic()
    }, [statusFilterList, recruterFilterList, companyFilterList, stageFilterList, props.vacancies]);

    const refreshStatistic= () =>{

        axios.get(variables.API_URL + '/api/get_all_vacancies_statistic/',
        {   params: {
                "status_filter": statusFilterList ? statusFilterList.join(',') : '',
                "stage_filter": stageFilterList ? stageFilterList.join(',') : '',
                "recruter_filter": recruterFilterList ? recruterFilterList.join(',') : '',
                "company_filter": companyFilterList ? companyFilterList.join(',') : '',
                "has_candidate": hasCandidateFilter ? hasCandidateFilter : '',
            },
            headers: {
                "Accept": " application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${props.token}`
            },
        })
            .then(response => {
                let data = response.data;
                let data_frames = []
                for (let item of data) {
                    
                    data_frames.push(item.data_frame)
                }
                setBarChartStatistic(data_frames);
                console.log(data_frames)

            })
    }

    const CustomizedAxisTick = (data) => {

        return (
            <g transform={`translate(${data.x},${data.y})`}>
              <text x={0} y={0} dy={6} textAnchor="end" fill="#666">
                <tspan textAnchor="end" x="0">
                    {data.payload.value}
                </tspan>
              </text>
            </g> 
          );
    }

    function barOnClick(e) {
        navigate('/vacancy/' + e.activePayload[0].payload.id)
    }


    const CustomIcon = (info) => {
        const {x,y,payload, index} = info
        let vacancy = props.vacancies.filter(item => item.id == barChartStatistic[index].id)[0]
        
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0 - 150} y={0} dy={-10}  fill="#666">
                    <tspan textAnchor="end" style={{fontSize: '14px', fontWeight: '500'}}>
                        {payload.value}
                    </tspan>
                </text>
                <text x={0 - 150} y={0} dy={10}  fill="#666">
                    <tspan textAnchor="end" style={{fontSize: '14px'}}>
                        {vacancy?.id} {vacancy?.company?.name}
                    </tspan>
                </text>
                <StatusSvg customerId={props.customerId} isSuperUser={props.isSuperUser} type={barChartStatistic[index]?.status}  onClick={() => {
                    !props.customerId && props.EditVacancy(vacancy)
                }}/>
            </g>
        )
        
      }


    return (
            <div style={{height: String(50 * barChartStatistic.length + 100) + "px"}}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                    data={barChartStatistic}
                    onClick={barOnClick}
                    layout="vertical"
                    width={500}
                    margin={{
                        top: 20,
                        right: 20,
                        left: 420,
                        bottom: 5,
                    }}
                    >
                    <XAxis
                        type="number"
                        orientation="top"
                    />
                    <YAxis type="category" dataKey="name" offset={3} tick={props.vacancies ? <CustomIcon /> : null} />
                    <Tooltip />
                    <Legend />
                    {props.stageList.map((item, idx) =>
                        <Bar dataKey={item.label} stackId="a" barSize={100} fill={colors.stage[item.value - 1]} />
                    )}
                    </BarChart>
                </ResponsiveContainer>

                
            </div>
    )
}