import React, { useEffect, useState } from "react";
import Pagination from "./pagination/Pagination";
import { variables } from "../Variables";
import axios from './Services/AxiosService';
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "./Videos.css";

const ENTER_KEY = 13;

export function Videos(props) {
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState("");
  const [video, setVideo] = useState(null);
  const [miniature, setMiniature] = useState({});
  const [videoWithoutPerson, setVideoWithoutPerson] = useState([]);
  const [phone, setPhone] = useState("7");
  const [videoId, setVideoId] = useState("");
  const [isVideoSend, setIsVideoSend] = useState(false);
  const [hasPerson, setHasPerson] = useState("all");

  const sendVideo = () => {
    setIsVideoSend(true);
    let form_data = new FormData();
    form_data.append("video", video, video.name);
    // form_data.append('user', props.username);
    let url = variables.API_URL + "/api/video/";
    axios
      .post(url, form_data, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.status.toString() === "200") {
          alert("Видео загружено!");
          setVideo(null);
          setIsVideoSend(false);
          getVideo();
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  useEffect(() => {
    getVideo();
  }, [hasPerson]);

  const getVideo = () => {
    let filter = "";
    if (hasPerson) filter += "&&status=" + hasPerson;
    fetch(variables.API_URL + "/api/video/?page=" + filter, {
      method: "GET",
      headers: {
        Accept: " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${props.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setVideoWithoutPerson(data.data);
        setTotalCount(data.count);
        setMiniature({});
        for (let idData in data.data) {
          getMiniature(data.data[idData].id);
        }
      });
    setPageNumber(1);
  };

  const getVideosByURL = (link) => {
    const url = `${variables.API_URL + "/api/video/"}${link}`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((response) => response.data);
  };

  const changePage = (page) => {
    let filter = "";
    if (hasPerson) filter += "&&status=" + hasPerson;
    getVideosByURL("?page=" + page.toString() + filter).then((result) => {
      setVideoWithoutPerson(result.data);
      setTotalCount(result.count);
      setMiniature({});
      for (let idData in result.data) {
        getMiniature(result.data[idData].id);
      }
      setPageNumber(page);
    });
  };

  function getMiniature(videoId) {
    fetch(variables.API_URL + "/api/miniature/?filter=" + videoId, {
      headers: {
        Authorization: `Bearer ${props.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data[0])
          setMiniature((prevState) => ({
            ...prevState,
            [videoId]: data.data[0].miniature,
          }));
      });
  }

  const getImage = (image) => {
    if (image) {
      return variables.API_URL + "/media/" + image.split("/").pop() + '?size=50x30';
    }
  };

  const addClick = () => {
    setVideo(null);
    setIsVideoSend(false);
  };

  const attachClick = (id_video) => {
    setPhone("7");
    setVideoId(id_video);
  };

  const editPhone = () => {
    fetch(variables.API_URL + "/api/video/" + videoId, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      body: JSON.stringify({
        phone: phone,
      }),
    })
      .then((data) => {
        getVideo();
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex" }}>
          <div>
            <input
              id="3123"
              type="radio"
              className="radio-filter"
              value={"all"}
              checked={hasPerson === "all"}
              onChange={() => setHasPerson("all")}
            />
            <label className="radio-filter-label" htmlFor="3123">
              Все
            </label>
          </div>

          <div className="mx-2">
            <input
              id="3124"
              type="radio"
              className="radio-filter"
              value={"sticked"}
              checked={hasPerson === "sticked"}
              onChange={() => setHasPerson("sticked")}
            />
            <label className="radio-filter-label" htmlFor="3124">
              Прикреплено
            </label>
          </div>

          <div>
            <input
              id="3125"
              type="radio"
              className="radio-filter"
              value={"not_sticked"}
              checked={hasPerson === "not_sticked"}
              onChange={() => setHasPerson("not_sticked")}
            />
            <label className="radio-filter-label" htmlFor="3125">
              Не прикреплено
            </label>
          </div>
        </div>
        <button
          className="btn btn-primary m-2 float-end"
          data-bs-toggle="modal"
          data-bs-target="#addVideo"
          onClick={() => addClick()}
        >
          Добавить видео
        </button>
      </div>
      <br />
      <br />
      <table className="table">
        <thead>
          <tr>
            <th>Миниатюра</th>
            <th>Название</th>
            <th>Ссылка</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {videoWithoutPerson.map((item) => (
            <tr key={item.id}>
              <td>
                <img
                  style={{ marginLeft: "0.5rem" }}
                  src={getImage(miniature[item.id.toString()])}
                  alt="Profile"
                />
              </td>
              <td>{item.video_name}</td>
              <td>
                <a href={item.video_url + "?t=" + props.token} target="_blank">
                  Ссылка
                </a>
              </td>
              <td>
                <button
                  style={{ width: "max-content" }}
                  className="btn btn-primary m-2"
                  data-bs-toggle="modal"
                  data-bs-target="#attachPhone"
                  onClick={() => attachClick(item.id)}
                >
                  Добавить телефон
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-center">
        <Pagination
          className="pagination-bar"
          currentPage={pageNumber}
          totalCount={totalCount}
          pageSize={10}
          onPageChange={(page) => changePage(page)}
        />
      </div>

      <div
        className="modal fade"
        id="addVideo"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centred">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Добавить видео</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="input-group mb-3">
                <input
                  type="file"
                  accept="video/*"
                  onChange={(e) => setVideo(e.target.files[0])}
                />
              </div>
            </div>
            {isVideoSend ? (
              <button
                type="button"
                className="btn btn-primary float-start"
                onClick={sendVideo}
              >
                Идет отправка...
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary float-start"
                onClick={sendVideo}
              >
                Отправить
              </button>
            )}
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="attachPhone"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centred"
          style={{ maxWidth: "333px" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Прикрепление к человеку по номеру телефона
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="input-group mb-3">
                <PhoneInput
                  country={"ru"}
                  value={phone}
                  onChange={(phone_n) => setPhone(phone_n)}
                />
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary float-start"
              onClick={editPhone}
              data-bs-dismiss="modal"
            >
              Прикрепить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
