import React, { useEffect, useLayoutEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { variables } from "../Variables";
import Pagination from "./pagination/Pagination";
import axios from "./Services/AxiosService";
import "./Applies.css";

var ENTER_KEY = 13;

var beginOptions = [
  { id: 0, value: "Отклик" },
  { id: 1, value: "Приглашение" },
  { id: 2, value: "Автоматически" },
  { id: 3, value: "-" },
];

var Applies = (props) => {
  var navigate = useNavigate();
  var { 0: searchParams, 1: setSearchParams } = useSearchParams();

  var { 0: applies, 1: setApplies } = useState([]);
  var { 0: totalCount, 1: setTotalCount } = useState("");

  var { 0: vacancies, 1: setVacancies } = useState([]);
  var { 0: recruters, 1: setRecruters } = useState([]);

  var { 0: videoFilter, 1: setVideoFilter } = useState(searchParams.get("has_video") === "true");
  var { 0: lastActiveOrder, 1: setLastActiveOrder } = useState(searchParams.get("lastActiveOrder") === "true");
  var { 0: statusFilter, 1: setStatusFilter } = useState(searchParams.get("status") || "1");
  var { 0: beginFilter, 1: setBeginFilter } = useState(searchParams.get("begin") || "");
  var { 0: vacancyFilter, 1: setVacancyFilter } = useState(searchParams.get("vacancy") || "-1");
  var { 0: currentRecruterFilter, 1: setCurrentRecruterFilter } = useState(searchParams.get("recruter") || -1);
  var { 0: filterQuery, 1: setFilterQuery } = useState(searchParams.get("filter") || "");
  var { 0: currentPage, 1: setCurrentPage } = useState(Number(searchParams.get("page")) || 1);

  // Modal
  var { 0: modalTitle, 1: setModalTitle } = useState("");
  var { 0: phone, 1: setPhone } = useState("7");
  var { 0: name, 1: setName } = useState("");
  var { 0: vacancy_id, 1: setVacancy_id } = useState("");
  var { 0: position, 1: setPosition } = useState("");

  useLayoutEffect(() => {
    if (props.customerId) {
      navigate("/vacancy");
    }
  }, []);

  var refreshList = () => {
    var url = variables.API_URL + "/api/apply/?" + new URLSearchParams(window.location.search);
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((response) => {
        setApplies(response?.data?.data || []);
        setTotalCount(response?.data?.count || 0);
      });
  };

  useEffect(() => {
    setCurrentPage(1);
    searchParams.delete("page");
    setSearchParams(searchParams, { replace: true });
    refreshList();
  }, [videoFilter, lastActiveOrder, statusFilter, vacancyFilter, currentRecruterFilter, beginFilter]);

  var changePage = (page) => {
    if (page !== currentPage) {
      window.scrollTo(0, 0);
      page > 1 ? searchParams.set("page", page) : searchParams.delete("page");
      setSearchParams(searchParams, { replace: true });
      setCurrentPage(page);
      refreshList();
    }
  };

  useEffect(() => {
    getVacancies();
    getRecruters();
  }, []);

  var getVacancies = () => {
    var url = variables.API_URL + "/api/vacancy_id_and_name/";
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((response) => {
        setVacancies(response?.data || []);
      });
  };

  const getRecruters = () => {
    var url = variables.API_URL + "/api/get_recruter_options/";
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((res) => {
        res?.data?.length && setRecruters(res?.data);
      });
  };

  var handleKeyDown = async (e) => {
    if (e.keyCode === ENTER_KEY) {
      setCurrentPage(1);
      searchParams.delete("page");
      e.target.value ? searchParams.set("filter", e.target.value) : searchParams.delete("filter");
      setSearchParams(searchParams, { replace: true });
      refreshList();
    }
  };

  var changeFilterQuery = (e) => {
    setFilterQuery(e.target.value);
  };

  var changeSelectStatusFilter = (e) => {
    e.target.value === "1" ? searchParams.delete("status") : searchParams.set("status", e.target.value);
    setSearchParams(searchParams, { replace: true });
    setStatusFilter(e.target.value);
  };

  var changeBeginFilter = (e) => {
    e.target.value === "-1" ? searchParams.delete("begin") : searchParams.set("begin", e.target.value);
    setSearchParams(searchParams, { replace: true });
    setBeginFilter(e.target.value);
  };

  var changeSelectVacancyFilter = (e) => {
    e.target.value === "-1" ? searchParams.delete("vacancy") : searchParams.set("vacancy", e.target.value);
    setSearchParams(searchParams, { replace: true });
    setVacancyFilter(e.target.value);
  };

  var changeSelectRecruterFilter = (e) => {
    e.target.value === "-1" ? searchParams.delete("recruter") : searchParams.set("recruter", e.target.value);
    setSearchParams(searchParams, { replace: true });
    setCurrentRecruterFilter(e.target.value);
  };

  var changeHasVideoFilter = (e) => {
    e.target.checked ? searchParams.set("has_video", e.target.checked) : searchParams.delete("has_video");
    setSearchParams(searchParams, { replace: true });
    setVideoFilter(e.target.checked);
  };

  var changeLastActiveOrder = (e) => {
    e.target.checked ? searchParams.set("lastActiveOrder", e.target.checked) : searchParams.delete("lastActiveOrder");
    setSearchParams(searchParams, { replace: true });
    setLastActiveOrder(e.target.checked);
  };

  var deleteApply = (apply_id, e) => {
    e.stopPropagation();
    var result = window.confirm("Вы уверены что хотите удалить отклик?");
    if (result) {
      fetch(variables.API_URL + "/api/delete_apply/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        body: JSON.stringify({
          apply_id: apply_id,
        }),
      })
        .then((res) => {
          refreshList();
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    }
  };

  var checkPhone = (number) => {
    setPhone(number);
  };

  var addClick = () => {
    setModalTitle("Добавить отклик");
    setPhone("7");
    setName("");
    setVacancy_id("");
    setPosition("");
  };

  var addApply = () => {
    fetch(variables.API_URL + "/api/create_from_web/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      body: JSON.stringify({
        phone: phone,
        name: name,
        vacancy_id: vacancy_id,
        position: position,
        username: props.username,
      }),
    })
      .then((data) => {
        refreshList();
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  var clickRowHandler = (e, apply_id) => {
    if (e.ctrlKey) window.open("/apply/" + apply_id, "_blank");
    else navigate("/apply/" + apply_id);
  };

  var onAuxClickHandler = (e, apply_id) => {
    if (e.button == 1) {
      window.open("/apply/" + apply_id, "_blank");
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "8px 0",
        }}
      >
        <button
          style={{
            maxHeight: "38px",
            minHeight: "38px",
            display: "flex",
            flexDirection: "row",
            columnGap: "5px",
            marginTop: "6px",
          }}
          type="button"
          className="btn btn-primary float-end"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={addClick}
        >
          <span style={{ fontSize: "30px", fontWeight: "500", lineHeight: "20px" }}>+</span>
          <div>Добавить отклик</div>
        </button>
      </div>
      <div className="filter_area">
        <div className="toggle_area">
          <div className="applies_switcher">
            <input
              className="hide applies_switcher__input"
              type="checkbox"
              value=""
              onChange={changeHasVideoFilter}
              id="flexCheckChecked"
              checked={videoFilter}
            />
            <label htmlFor={"flexCheckChecked"} className="applies_switcher__switcher"></label>
            <label className="toggle_name" htmlFor="flexCheckChecked">
              С видео
            </label>
          </div>
          <div className="applies_switcher">
            <input
              id={"isLastActiveOrder"}
              className="hide applies_switcher__input"
              type="checkbox"
              checked={lastActiveOrder}
              onChange={changeLastActiveOrder}
            />
            <label htmlFor={"isLastActiveOrder"} className="applies_switcher__switcher"></label>
            <label className="toggle_name" htmlFor="isLastActiveOrder">
              Последняя активность
            </label>
          </div>
        </div>
        <select className="form-select" onChange={changeSelectStatusFilter} value={statusFilter}>
          <option key={1} value={1}>
            Статус
          </option>
          <option key={2} value={"В работе"}>
            В работе
          </option>
          <option key={3} value={"Отказ"}>
            Отказ
          </option>
        </select>

        <select className="form-select" onChange={changeBeginFilter} value={beginFilter}>
          <option key={1} value={"-1"}>
            Добавлен как
          </option>
          {beginOptions?.map((option) => (
            <option key={option?.id} value={option?.id}>
              {option?.value}
            </option>
          ))}
        </select>
        <select className="form-select" onChange={changeSelectVacancyFilter} value={vacancyFilter}>
          <option key={-1} value={-1}>
            Вакансия
          </option>
          {vacancies?.map((vacancy) => (
            <option key={vacancy.id} value={vacancy.id}>
              {vacancy.id} {vacancy.name}
            </option>
          ))}
        </select>
        <select className="form-select" onChange={changeSelectRecruterFilter} value={currentRecruterFilter}>
          <option key={-1} value={-1}>
            Рекрутер
          </option>
          {recruters?.map((vacancy) => (
            <option key={vacancy?.value} value={vacancy?.label}>
              {vacancy?.label}
            </option>
          ))}
        </select>

        <input
          type="text"
          className="form-control"
          placeholder="Введите id вакансии/person или имя..."
          value={filterQuery}
          onChange={changeFilterQuery}
          onKeyDown={handleKeyDown}
        />
      </div>

      <div style={{ textAlign: "end" }}>Всего откликов: {totalCount}</div>
      <div className="table_wrap">
        {!!currentPage && !!totalCount && (
          <div className="text-center">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={100}
              onPageChange={(page) => changePage(page)}
            />
          </div>
        )}
        <table className="table">
          <thead>
            <tr>
              <th width="5%">Apply ID</th>
              <th width="5%">Фото</th>
              <th width="6%">Person ID</th>
              <th width="10%">Компания</th>
              <th width="15%">ФИО</th>
              <th width="5%">Возраст</th>
              <th width="10%">Статус</th>
              <th>Должность</th>
              <th width="5%">ID вакансии</th>
              <th width="7%">Рекрутер</th>
              <th width="4%"></th>
            </tr>
          </thead>
          <tbody>
            {applies?.map((apply) => (
              <tr
                key={apply.id}
                className="apply_row"
                onClick={(e) => {
                  clickRowHandler(e, apply.id);
                }}
                onAuxClick={(e) => {
                  onAuxClickHandler(e, apply.id);
                }}
              >
                <td>
                  <p title={apply.id}>{apply.id}</p>
                </td>
                <td
                  style={{
                    position: "relative",
                    fontSize: "0px",
                    minWidth: "80px",
                  }}
                >
                  <img
                    style={{ objectFit: "cover" }}
                    src={variables.API_URL + "/" + apply.photo + "?size=60x60"}
                    alt="Profile"
                  />
                </td>
                <td>
                  <a
                    href={window.location.origin + "/" + apply.person_id}
                    onAuxClick={(e) => {
                      e.stopPropagation();
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {apply.person_id}
                  </a>
                </td>
                <td>
                  <div title={apply.vacancy_id == 0 ? apply.extension_company_name : apply.company}>
                    {apply.vacancy_id == 0 ? apply.extension_company_name : apply.company}
                  </div>
                </td>
                <td>
                  <div title={apply.name}>{apply.name}</div>
                </td>
                <td>
                  <p title={apply.age}>{apply.age}</p>
                </td>
                <td>
                  <p title={apply.status}>{apply.status}</p>
                </td>
                <td>
                  <div title={apply.position}>{apply.position}</div>
                </td>
                <td>
                  <p title={apply.vacancy_id}>{apply.vacancy_id}</p>
                </td>
                <td>
                  <p title={apply.rec_account}>{apply.rec_account}</p>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={(e) => deleteApply(apply.id, e)}
                    title={"Удалить отклик"}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!!currentPage && !!totalCount && (
          <div className="text-center">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={100}
              onPageChange={(page) => changePage(page)}
            />
          </div>
        )}
      </div>

      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centred" style={{ maxWidth: "333px" }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{modalTitle}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="input-group mb-3" style={{ ".reactTelInput .formControl": "" }}>
                <PhoneInput country={"ru"} value={phone} onChange={(phone_n) => checkPhone(phone_n)} />
              </div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  placeholder="ФИО"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  value={vacancy_id}
                  placeholder="ID вакансии"
                  onChange={(e) => setVacancy_id(e.target.value)}
                />
              </div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  value={position}
                  placeholder="Должность"
                  onChange={(e) => setPosition(e.target.value)}
                />
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary float-start"
              data-bs-dismiss="modal"
              onClick={() => addApply()}
            >
              Создать
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applies;
