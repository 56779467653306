import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { variables } from "../../Variables";
import axios from "../Services/AxiosService";
import Select from "../Select";
import "./Candidate.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
registerLocale("ru", ru);

export function CandidateModal({ token, apply_id }) {
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedStage, setSelectedStage] = useState(0);
  const [users, setUsers] = useState([]);
  const [date, setDate] = useState(new Date());
  const stages = [
    { label: "Кандидат", value: 0 },
    { label: "Передан заказчику", value: 2 },
    { label: "Стажировка", value: 3 },
    { label: "Испытательный срок", value: 4 },
    { label: "Успех", value: 5 },
    { label: "Не прошёл стажировку", value: 6 },
    { label: "Не прошёл испытательный срок", value: 7 },
    { label: "Уволен вскоре после ИС", value: 8 },
  ];

  const getUsers = () => {
    let url = variables.API_URL + "/api/get_recruter_options/";
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSelectedUser(res.data[0].value);
        setUsers(res.data);
      });
  };

  const getCandidate = () => {
    let url = variables.API_URL + "/api/candidate_detail/" + apply_id;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSelectedUser(res.data.recruter);
        setSelectedStage(res.data.status);
        setDate(new Date(res.data.date));
      });
  };

  const updateCandidate = () => {
    let url = variables.API_URL + "/api/candidate_update/";
    axios
      .post(
        url,
        {
          apply: apply_id,
          status: selectedStage,
          recruter: selectedUser ? selectedUser : users[0]?.value,
          date: `${date.getFullYear()}-${
            date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1
          }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSelectedUser(res.data.recruter);
        setSelectedStage(res.data.status);
        setDate(new Date(res.data.date));
      });
  };

  useEffect(() => {
    getUsers();
    getCandidate();
  }, []);

  return (
    <div className="modal fade" id="add_candidate_modal" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centred">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Добавить кандидата</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="input-group mb-3">
              <span className="input-group-text">Дата</span>
              <DatePicker
                wrapperClassName="datePicker"
                locale="ru"
                dateFormat="dd.MM.yyyy"
                selected={date}
                onChange={(e) => setDate(e)}
              />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text">Рекрутер</span>
              <Select
                className="select-user"
                value={selectedUser || ""}
                onChange={(e) => {
                  setSelectedUser(e.target.value);
                }}
                options={users}
              />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text">Стадия</span>
              <Select
                className="select-user"
                value={selectedStage}
                onChange={(e) => setSelectedStage(e.target.value)}
                options={stages}
              />
            </div>
          </div>
          <button
            disabled={selectedStage == 0}
            onClick={updateCandidate}
            type="button"
            className="btn btn-primary float-start"
            data-bs-dismiss="modal"
          >
            Отправить
          </button>
        </div>
      </div>
    </div>
  );
}
