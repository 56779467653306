import axios from './Services/AxiosService';
import {variables} from "../Variables";

const API_URL = variables.API_URL
export default class ProfIndustryService {

    header_with_token(token) {

        return {
            "Accept": " application/json",
            "Content-Type": " application/json",
            Authorization: `Bearer ${token}`
        }
    }

    // searchProducts(name = '', min_price = '', max_price = '', category = '') {
    //     const url = `${API_URL}/api/lpr/?name=${name}&price_min=${min_price}&price_max=${max_price}&category=${category}`;
    //     // return axios.get(url).then(response => response.data.data.results);
    //     return fetch(url).then(response => response.json()).then(data => data.data)
    // }

    getProfIndustries() {
        const url = `${API_URL}/api/professional_industry/`;
        // return axios.get(url).then(response => response.data.data.results);
        return fetch(url).then(response => response.json()).then(data => data.data)
    }


    getProfIndustriesByURL(link) {
        const url = `${link}`;
        return axios.get(url).then(response => response.data.data);
    }

}