import React from "react";
import './Preloader.css'


const Preloader = () => {

    return (
        <div className="preloader-box">
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
    );

};

export default Preloader