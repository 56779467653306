import React, {useEffect, useLayoutEffect, useState} from "react";
import {variables} from "../../../Variables";
import axios from "axios";
import {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import classNames from "classnames";
import {Input} from "react-select/animated";

registerLocale('ru', ru);


export function AddVideoTagModal({token, user, video, setVideoTags, videotags, video_index, taggroups,flag,setFlag}) {
    const [tags, setTags] = useState([]);
    const [search, setSearch] = useState("");
    const [groups, setGroups] = useState([]);
    const getTags = () => {
        setTags(videotags[video_index])
        setGroups(taggroups)
    }

    useEffect(() => {
        if (tags.length !== 0) {
            const clonevideotags = structuredClone(videotags)
            clonevideotags[video_index] = tags.filter((num) => {
                return num.status === "active"
            })
            setVideoTags(clonevideotags)
        }
    }, [tags])

    const ChangeStateTag = async (tagId, status, pk) => {
        let url = variables.API_URL + '/api/video_tags'
        if (status === "inactive") {
            var response = await axios.put(url, {
                    video_id: video,
                    tag_id: tagId,
                    user_id: user,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            if (response.status === 201) {
                const cloneTags = structuredClone(tags)
                var changeTagId = cloneTags.findIndex((tag) => tag.tag_id == tagId)
                cloneTags[changeTagId].status = "active"
                cloneTags[changeTagId].id = response.data.id
                setTags(cloneTags)
            }
        } else if (status === "active") {
            var response = await axios.delete(url + "?id=" + pk, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (response.status === 204) {
                const cloneTags = structuredClone(tags)
                var changeTagId = cloneTags.findIndex((tag) => tag.tag_id == tagId)
                cloneTags[changeTagId].status = "inactive"
                cloneTags[changeTagId].id = ""
                setTags(cloneTags)
            }

        }
    }


    useEffect(() => {
        if (flag){
        getTags()}
        setFlag(false)
    }, [videotags])

    const onChange = (e) => {
        setSearch(e.target.value)
    }

    function byField(fieldName) {
        return (a, b) => a[fieldName] > b[fieldName] ? 1 : -1;
    }


    return (
        <div className='modal fade' id={"statsistic_add_video_tag_modal" + video_index} tabIndex="-1"
             aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centred">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Добавить теги</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="input-group mb-3">
                            <span className="input-group-text">Поиск</span>
                            <input
                                value={search}
                                type="search"
                                className="search"
                                onChange={onChange}
                            />

                        </div>

                        <div className="input-group mb-3" id="tag_block">
                            <div className={"tags_additional_area"}>
                                {tags.filter(tag => tag.tag.toLowerCase().includes(search.toLowerCase())).filter(tag => tag.status === "active").map((obj, index) => [
                                    <a key={index}
                                       role="button"
                                       className={`mb-2 mx-2 btn btn-outline-primary ${obj.status === "active" ? "active" : ""}`}
                                       onClick={() => {
                                           ChangeStateTag(obj.tag_id, obj.status, obj.id)
                                       }}>
                                        {"#" + obj.tag}
                                    </a>
                                ])}
                            </div>

                            {groups.map((obj, index) => [
                                <div className="tags_additional_area">
                                    <p className="tag_label">{obj.group}</p>
                                    {tags.filter(tag => tag.tag.toLowerCase().includes(search.toLowerCase())).filter(tag => tag.group === obj.group && tag.status === "inactive").map((obj, index) => [
                                        <a key={obj.id}
                                           role="button"
                                           className={`mb-2 mx-2 btn btn-outline-primary ${obj.status === "active" ? "active" : ""}`}
                                           onClick={() => {
                                               ChangeStateTag(obj.tag_id, obj.status, obj.id)
                                           }}>
                                            {"#" + obj.tag}
                                        </a>
                                    ])}
                                </div>
                            ])}

                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}