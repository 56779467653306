import React from 'react';
import classNames from 'classnames';
import './StatisticSwitcher.css'

const StatisticSwitcher = ({activeMenuItem, setActiveMenuItem, isYearItem, setIsYearItem}) => {

  return(<>
      <div className={'statistic__menu'}>
            <div className={classNames('statistic__menu-item', {
                'statistic__menu-item active': isYearItem==='week'})}
                onClick={() => setIsYearItem('week')}>
                Неделя
            </div>
            <div className={classNames('statistic__menu-item', {
                'statistic__menu-item active': isYearItem==='month'})}
                onClick={() => setIsYearItem('month')}>
                Месяц
            </div>
            <div className={classNames('statistic__menu-item', {
                'statistic__menu-item active': isYearItem==='year'})}
                onClick={() => setIsYearItem('year')}>
                Год
            </div>
            <div className={classNames('statistic__menu-item', {
                'statistic__menu-item active': isYearItem==='day_number'})}
                onClick={() => setIsYearItem('day_number')}>
                Общая
            </div>
          </div>
          <div className={'statistic__menu'}>
            <div className={classNames('statistic__menu-item', {
                'statistic__menu-item active': activeMenuItem.value === 'candidate'})}
                onClick={() => setActiveMenuItem({label:'Статистика по кандидатам', value: 'candidate'})}>
                Кандидат
            </div>
            <div className={classNames('statistic__menu-item', {
                    'statistic__menu-item active': activeMenuItem.value === 'time'})}
                    onClick={() => setActiveMenuItem({label:'Затраченное время', value: 'time'})}>
                    Время
            </div>
            <div className={classNames('statistic__menu-item', {
                'statistic__menu-item active': activeMenuItem.value === 'add_resume_invite'})}
                onClick={() => setActiveMenuItem({label:'Количество резюме, добавленных в CRM', value: 'add_resume_invite'})}>
                Приглашения
            </div>
            <div className={classNames('statistic__menu-item', {
                'statistic__menu-item active': activeMenuItem.value === 'add_resume_reply'})}
                onClick={() => setActiveMenuItem({label:'Количество резюме, добавленных в CRM', value: 'add_resume_reply'})}>
                Отклики
            </div>
            <div className={classNames('statistic__menu-item', {
                'statistic__menu-item active': activeMenuItem.value === 'first_messages'})}
                onClick={() => setActiveMenuItem({label:'Количество первых сообщений', value: 'first_messages'})}>
                Первые сообщения
            </div>
            <div className={classNames('statistic__menu-item', {
                'statistic__menu-item active': activeMenuItem.value === 'all_messages'})}
                onClick={() => setActiveMenuItem({label:'Общее количество отправленных сообщений', value: 'all_messages'})}>
                Все сообщения
            </div>
            <div className={classNames('statistic__menu-item', {
                'statistic__menu-item active': activeMenuItem.value === 'created_invites'})}
                onClick={() => setActiveMenuItem({label:'Количество созданных приглашений', value: 'created_invites'})}>
                Созданные приглашения
            </div>
            <div className={classNames('statistic__menu-item', {
                'statistic__menu-item active': activeMenuItem.value === 'meets'})}
                onClick={() => setActiveMenuItem({label:'Количество назначенных встреч', value: 'meets'})}>
                Встречи
            </div>
            <div className={classNames('statistic__menu-item', {
                'statistic__menu-item active': activeMenuItem.value === 'videos'})}
                onClick={() => setActiveMenuItem({label:'Количество загруженных видео', value: 'videos'})}>
                Видео
            </div>
            <div className={classNames('statistic__menu-item', {
                'statistic__menu-item active': activeMenuItem.value === 'feedbacks'})}
                onClick={() => setActiveMenuItem({label:'Количество загруженных ОС', value: 'feedbacks'})}>
                ОС
            </div>
            <div className={classNames('statistic__menu-item', {
                'statistic__menu-item active': activeMenuItem.value === 'lprs'})}
                onClick={() => setActiveMenuItem({label:'Количество добавленных ЛПР', value: 'lprs'})}>
                ЛПР
            </div>
            <div className={classNames('statistic__menu-item', {
                'statistic__menu-item active': activeMenuItem.value === 'add_resume_off'})}
                onClick={() => setActiveMenuItem({label:'Количество резюме, добавленных в CRM (OFF)', value: 'add_resume_off'})}>
                Резюме(OFF)
            </div>
            <div className={classNames('statistic__menu-item', {
                'statistic__menu-item active': activeMenuItem.value === 'phone_call'})}
                onClick={() => setActiveMenuItem({label:'Количество звонков, совершенных рекрутером', value: 'phone_call'})}>
                Звонки
            </div>
            <div className={classNames('statistic__menu-item', {
                'statistic__menu-item active': activeMenuItem.value === 'social_network'})}
                onClick={() => setActiveMenuItem({label:'Количество соц. сетей, добавленных рекрутером', value: 'social_network'})}>
                Соц. сети
            </div>
          </div>
      </>
  );
};

export default StatisticSwitcher;
