import { createSlice } from "@reduxjs/toolkit";


const aplyFilterSlice = createSlice({
    name: 'apply_filter',
    initialState: {
        filter: '',
        vacancyFilter: -1,
        statusFilter: 1,
        lastActiveOrder: false,
        videoFilter: ' ',
    },
    reducers: {
        setAplyFilter(state, action) {
            if (action.payload.vacancyFilter) state.vacancyFilter = action.payload.vacancyFilter
            if (action.payload.statusFilter) state.statusFilter = action.payload.statusFilter
        },

        setApplyFilterQuery(state, action) {
            state.filter = action.payload.filter
        },

        setLastActiveOrder(state, action) {
            state.lastActiveOrder = action.payload.lastActiveOrder
        },

        setVideoFilter(state, action) {
            state.videoFilter = action.payload.videoFilter
        },

        setFullAplyFilter(state, action) {
            if (action.payload.vacancyFilter) state.vacancyFilter = action.payload.vacancyFilter
            if (action.payload.statusFilter) state.statusFilter = action.payload.statusFilter
            if (action.payload.filter) state.filter = action.payload.filter
            if (action.payload.lastActiveOrder) state.lastActiveOrder = action.payload.lastActiveOrder
            if (action.payload.videoFilter) state.videoFilter = action.payload.videoFilter
            return state

        },

        clearAplyFilters(state) {
            state.statusFilter = 1
            state.vacancyFilter = -1
            state.filter = ''
            state.lastActiveOrder = false            
            state.videoFilter = ''
        },
    }
})

export const { clearAplyFilters, setAplyFilter, setVideoFilter, setLastActiveOrder, setApplyFilterQuery, setFullAplyFilter } = aplyFilterSlice.actions;

export default aplyFilterSlice.reducer;