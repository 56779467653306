import React, {useEffect, useState} from "react";
import ProfAreaService from "./ProfAreaService";

const profAreaService = new ProfAreaService();

export default function SelectProfArea(props) {
    const [profAreas, setProfAreas] = useState([])

     useEffect(() => {
        profAreaService.getProfAreas().then(function (result) {
            setProfAreas(result.results)
        });
    }, [])

    return (<>
        <select id={"30"} className="form-select" required={"True"} value={props.value} onChange={props.onChange} style={{marginTop: '5px'}}
                defaultValue={""}>
            <option disabled={props.disabledefault} key={"default"} value={""}>{"Профессиональная область"}</option>
            {profAreas?.map(e =>
                <option key={e.id} value={e.id}>{e.name}</option>
            )}
        </select>
        <label htmlFor={"30"}>{props.required && <span className={"text-danger"}> * </span>}Сфера деятельности</label>
    </>
    )
}