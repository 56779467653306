import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { resetUserSlice } from "../../store/userSlice";
import { useDispatch } from "react-redux";
import axios from "../Services/AxiosService";
import { variables } from "../../Variables";
import { useSelector } from "react-redux";

export default function Logout({}) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  var logout = async () => {
    var url = variables.API_URL + "/api/logout/";
    var response = await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
    });
  };

  useEffect(() => {
    logout();
    dispatch(resetUserSlice());
    navigate("/");
    // document.location.reload();
  }, []);

  return (
    <div>
      <h3>You are logged out</h3>
    </div>
  );
}
