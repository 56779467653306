import { createSlice } from "@reduxjs/toolkit";

const windowSlice = createSlice({
    name: 'window',
    initialState: {
        width: window.innerWidth,
        height: window.innerHeight,
    },
    reducers: {
        setWidth(state, action) {
            state.width = action.payload.width
        },
        setHeight(state, action) {
            state.height = action.payload.height
        },
        resetWindowSlice(state){
            state.chat_messages = window.innerWidth;
            state.input_message = window.innerHeight;
        }
    }
})

export const { setWidth, setHeight, resetWindowSlice } = windowSlice.actions;

export default windowSlice.reducer;