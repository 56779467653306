import Pagination from "./pagination/Pagination";
import React, {useEffect, useState} from "react";
import LPRService from "./LPRService";
import SelectProfArea from "./selectProfArea";
import SelectProfIndustry from "./selectProfIndustry";
import Field from "./field";

const lprService = new LPRService();

export default function Lpr(props) {
    const [page, setPage] = useState(1)
    const [nextPageURL, setNext] = useState()
    const [previousPageURL, setPrev] = useState()
    const [totalCount, setTotalCount] = useState(0)
    const [lpr, setLpr] = useState([])
    const [profArea, setProfArea] = useState('')
    const [profIndustry, setProfIndustry] = useState('')
    const [filter, setFilter] = useState('')


    useEffect(() => {
        lprService.getLPRs('1', '','', '', props.token).then(function (result) {
            setLpr(result.results);
            setPrev(result.previous);
            setNext(result.next);
            setPage(1)
            setTotalCount(result.count)
        });
        // lprService.getProfessionalAreas().
    }, [])

    function onChangeProfArea(e) {
        setPage(1)
        setProfArea(e.target.value)
        lprService.getLPRs(page, profIndustry, e.target.value, filter, props.token).then(function (result) {
            setTotalCount(result.count)
            setLpr(result.results);
            setPrev(result.previous);
            setNext(result.next);
        });
    }

    function handleKeyDown(e) {
        if (e.keyCode === 13) {
            console.log('profIndustry = ', profIndustry)
            console.log('profArea = ', profArea)
            console.log('filter = ', filter)
            setPage(1)
            lprService.getLPRs(page, profIndustry, profArea, e.target.value, props.token).then(function (result) {
                setTotalCount(result.count)
                setLpr(result.results);
                setPrev(result.previous);
                setNext(result.next);
            });
        }
    }

    function onChangeIndustryArea(e) {
        setPage(1)
        setProfIndustry(e.target.value)
        lprService.getLPRs(page, e.target.value, profArea, filter, props.token).then(function (result) {
            setTotalCount(result.count)
            setLpr(result.results);
            setPrev(result.previous);
            setNext(result.next);
        });
    }

    const changePage = (page) => {
        lprService.getLPRs(page, profIndustry, profArea, filter, props.token).then((result) => {
            setTotalCount(result.count)
            setLpr(result.results);
            setPrev(result.previous);
            setNext(result.next);
            setPage(page)
        })
    }

    return (
        <div>
            {/*<div style={{display: 'flow-root'}}>*/}
            {/*    <button type="button" className="btn btn-primary m-2 float-end"*/}
            {/*            data-bs-toggle="modal"*/}
            {/*            data-bs-target="#exampleModal"*/}
            {/*            onClick={() => addClick()}>*/}
            {/*        Добавить ЛПР*/}
            {/*    </button>*/}
            {/*</div>*/}
            <div className={"row"}>
                <div className={"col-4"}>
                    <div className={"form-floating"}>
                        <SelectProfIndustry defaultValue={"default"} value={profIndustry}
                                            onChange={onChangeIndustryArea}/>
                    </div>
                </div>
                <div className={"col-4"}>
                    <div className={"form-floating"}>
                        <SelectProfArea defaultValue={"default"} value={profArea} onChange={onChangeProfArea}/>
                    </div>
                </div>
                <div className={"col-4 mt-1"}>
                    <Field id={10} message={"Поиск"} type={"text"} value={filter} required={""}
                           onChange={(e) => setFilter(e.target.value)} onKeyDown={handleKeyDown}/>
                </div>

                {/*<select className="form-select" onChange={onChangeProfArea} style={{marginTop: '5px'}}*/}
                {/*        value={"default"}>*/}
                {/*    <option key={"default"} value={"default"}>{"НЕ ВЫБРАНО"}</option>*/}
                {/*    {profAreas?.map(e =>*/}
                {/*        <option key={e.id} value={e.id}>{e.name}</option>*/}
                {/*    )}*/}
                {/*</select>*/}
                {/*<input type="text" className="form-control"*/}
                {/*       placeholder="Я не работать, меня не посчитать . Без поиска.....("/>*/}
                {/*// style={{marginTop: '5px'}} value={filterQuery}*/}
                {/*// onChange={changeFilterQuery} onKeyDown={handleKeyDown}/>*/}
                {/*Я не работать, меня не посчитать . Без выбиралочки.....(*/}
            </div>
            <table className='table'>
                <thead>
                <tr>
                    <th>Person ID</th>
                    <th>ID Рекрутёра</th>
                    <th>Отрасль компании</th>
                    <th>Сфера деятельности</th>
                    <th>Уровень</th>
                    <th>Компания</th>
                    <th>ФИО</th>
                    <th>Должность</th>
                    <th>Дата</th>
                    {props.isSuperUser && <th>Номер</th>}

                </tr>
                </thead>
                <tbody>
                {lpr?.map(e => {
                        const date = new Date(e.date_created)
                        // const timezonediff = date.getTimezoneOffset() / 60
                        return (
                            <tr key={e?.id}>
                                <td>{e?.person}</td>
                                <td>{e?.user.username}</td>
                                <td>{e?.professional_industry.name}</td>
                                <td>{e?.professional_area.name}</td>
                                <td>{e?.professional_level?.name}</td>
                                <td>
                                    <div className={"row"}> {e.company?.name}</div>
                                    <a className={"row"} href={e.company?.link.match(/^https?:/) ? e.company?.link : '//' + e.company?.link} target='_blank'> {e.company?.link}</a>
                                </td>
                                <td>{e.second_name + " " + e.first_name + " " + e.patronymic}</td>
                                <td>{e.position}</td>
                                <td>{date.toLocaleDateString()} {date.getHours() >= 10 ? date.getHours() : "0" + date.getHours().toString()}:{date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes().toString()}:{date.getSeconds() >= 10 ? date.getSeconds() : "0" + date.getSeconds().toString()}</td>
                                {props.isSuperUser && <td>{e?.number}</td>}

                                {/*<td>*/}
                                {/*    {e.youtrack_url ?*/}
                                {/*        <a href={e.youtrack_url} target="_blank">*/}
                                {/*            Ссылка</a>*/}
                                {/*        : null}</td>*/}
                                {/*<td>*/}
                                {/*    <button type="button" className="btn btn-danger"*/}
                                {/*            onClick={() => deleteApply(e.id)}>*/}
                                {/*        <i className="fa fa-trash" aria-hidden="true"></i>*/}
                                {/*    </button>*/}
                                {/*</td>*/}
                            </tr>
                        )
                    }
                )}
                </tbody>
            </table>
            <div className="text-center">
                <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={totalCount}
                    pageSize={10}
                    onPageChange={page => changePage(page)}
                />
            </div>
            {/*<div className='modal fade' id="exampleModal" tabIndex="-1" aria-hidden="true">*/}
            {/*    <div className="modal-dialog modal-lg modal-dialog-centred" style={{maxWidth: '333px'}}>*/}
            {/*        <div className="modal-content">*/}
            {/*            <div className="modal-header">*/}
            {/*                <h5 className="modal-title">{modalTitle}</h5>*/}
            {/*                <button type="button" className="btn-close" data-bs-dismiss="modal"*/}
            {/*                        aria-label="Close"></button>*/}
            {/*            </div>*/}
            {/*            <div className="modal-body">*/}
            {/*                /!*<div className="input-group mb-3">*!/*/}
            {/*                /!*    <span className="input-group-text">ID вакансии</span>*!/*/}
            {/*                /!*    <input type="text" className='form-control' value={id}*!/*/}
            {/*                /!*           onChange={changeID}/>*!/*/}
            {/*                /!*</div>*!/*/}
            {/*                <div className="input-group mb-3" style={{'.react-tel-input .form-control': ''}}>*/}
            {/*                    <PhoneInput*/}
            {/*                        country={'ru'}*/}
            {/*                        value={phone}*/}
            {/*                        onChange={(phone_n) => checkPhone(phone_n)}*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*                <div className="input-group mb-3">*/}
            {/*                    <input type="text" className='form-control' value={name} placeholder='ФИО'*/}
            {/*                           onChange={(e) => setName(e.target.value)}/>*/}
            {/*                </div>*/}
            {/*                <div className="input-group mb-3">*/}
            {/*                    <input type="text" className='form-control' value={vacancy_id} placeholder="ID вакансии"*/}
            {/*                           onChange={(e) => setVacancy_id(e.target.value)}/>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <button type="button" className="btn btn-primary float-start" data-bs-dismiss="modal"*/}
            {/*                    onClick={() => addApply()}>Создать*/}
            {/*            </button>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}