import React, {useEffect, useState} from "react";
import Pagination from "./pagination/Pagination";
import {variables} from "../Variables";
import axios from './Services/AxiosService';
import {useNavigate} from "react-router-dom";
import PhoneInput from "react-phone-input-2";

const ENTER_KEY = 13

export function Feedbacks(props) {
    const navigate = useNavigate();

    const [pageNumber, setPageNumber] = useState(1)
    const [totalCount, setTotalCount] = useState("")
    const [audio, setAudio] = useState(null);
    const [audioWithoutPerson, setAudioWithoutPerson] = useState([])
    const [phone, setPhone] = useState("7");
    const [videoId, setVideoId] = useState("");
    const [isVideoSend, setIsVideoSend] = useState(false);


    const sendAudio = () => {
        setIsVideoSend(true)
        let form_data = new FormData();
        form_data.append('audio', audio, audio.name);
        // form_data.append('user', props.username);
        let url = variables.API_URL + '/api/audio/';
        axios.post(url, form_data, {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${props.token}`
            }
        })
        .then(res=> {
                console.log(res)
                if (res.status.toString() === '200'){
                    alert('Обратная связь загружена!')
                    setAudio(null)
                    setIsVideoSend(false)
                    getAudio()
                }
            }
        )
        .catch(err => {
            console.log(err);
            alert(err)
        })
    }


    useEffect(() => {
        getAudio()
    }, []);

    const getAudio= () =>{
        fetch(variables.API_URL + '/api/audio/?status&&page=', {
            method: 'GET',
            headers: {
                "Accept": " application/json",
                "Content-Type": " application/json",
                Authorization: `Bearer ${props.token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setAudioWithoutPerson(data.data);
                console.log(data.data)
                setTotalCount(data.count);
            });
        setPageNumber(1)
    }

    const getVideosByURL=(link)=>{
		const url = `${variables.API_URL+'/api/audio/'}${link}&&status`;
		return axios.get(url, {
            headers: {
              Authorization: `Bearer ${props.token}`
            }
            }).then(response => response.data);
	}

    const changePage=(page)=> {
        getVideosByURL("?page=" + page.toString()).then((result) => {
            setAudioWithoutPerson(result.data);
            setTotalCount(result.count);
            setPageNumber(page)
        })
    }

    const addClick = () => {
        setAudio(null);
        setIsVideoSend(false);
    }

    const attachClick = (id_video) => {
        setPhone('7');
        setVideoId(id_video);
    }

    return (
        <div>
            <button className='btn btn-primary m-2 float-end'
                    data-bs-toggle="modal"
                    data-bs-target="#addAudio"
                    onClick={() => addClick()}>Добавить  обратную связь</button>
            <br/><br/>
            <table className='table'>
                <thead>
                <tr>
                    <th>Название</th>
                    <th>Ссылка</th>
                </tr>
                </thead>
                <tbody>
                {audioWithoutPerson.map(item=>
                <tr key={item.id}>
                    <td>{item.audio_name}</td>
                    <td><a href={item.audio_url} target='_blank'>Ссылка</a></td>
                </tr>
                )}
                </tbody>
            </table>
            <div className="text-center">
                <Pagination
                    className="pagination-bar"
                    currentPage={pageNumber}
                    totalCount={totalCount}
                    pageSize={10}
                    onPageChange={page => changePage(page)}
                  />
            </div>

            <div className='modal fade' id="addAudio" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centred">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Добавить обратную связь</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="input-group mb-3">
                                <input type="file" accept='audio/*' onChange={(e)=>setAudio(e.target.files[0])}/>
                            </div>
                        </div>
                        {isVideoSend? <button type="button" className="btn btn-primary float-start" onClick={sendAudio}>Идет отправка...</button>:
                            <button type="button" className="btn btn-primary float-start" onClick={sendAudio}>Отправить</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}