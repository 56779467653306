import {configureStore} from '@reduxjs/toolkit';
import vacancyFilter from './vacancyFilterSlice'
import applyFilter from './applyFilterSlice'
import applyGalleryFilter from './applyGalleryFilterSlice'
import windowSlice from "./windowSlice"
import scrollSlice from './scrollSlice';
import userSlice from './userSlice';

export default configureStore({
    reducer: {
        vacancy_filter: vacancyFilter,
        apply_filter: applyFilter,
        window: windowSlice,
        apply_gallery_filter: applyGalleryFilter,
        scroll: scrollSlice,
        user: userSlice,
    }
});