import React, { useEffect, useLayoutEffect, useState, PureComponent } from "react";
import axios from "../Services/AxiosService";
import { useNavigate } from "react-router-dom";
import "../Statistic/Statistic.css";
import "./VacancyStatistic.css";
import "./VacancyDetail.css";
import { variables } from "../../Variables";
import { useParams } from "react-router-dom";
import Select_1 from '../Select.js'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  ReferenceLine,
} from "recharts";
import new_image from "../../static/undefined.jpg";
import { Button } from "react-bootstrap";
import { colors } from "../Colors";
import VacancyChangeModal from "../VacancyChangeModal";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import Modal from "react-bootstrap/Modal";
import Pagination from "../pagination/Pagination";
// import StatisticSwitcher from "./StatisticSwitcher";
// import {StatisticAddHourModal} from "./StatisticAddHourModal";

const ENTER_KEY = 13;

export function VacancyDetail(props) {
  let { vacancyId } = useParams();
  const navigate = useNavigate();

  const [infoDict, setInfoDict] = useState({});
  const [statusList, setStatusList] = useState([]);
  const [barChartStatistic, setBarChartStatistic] = useState([]);
  const [statisticSum, setStatisticSum] = useState("");
  const [statistic, setStatistic] = useState("");
  const [isSumStatistic, setIsSumStatistic] = useState(false);
  const [statOptions, setStatOptions] = useState([]);
  const [applies, setApplies] = useState([]);

  const [sumTime, setSumTime] = useState("");

  const [stageList, setStageList] = useState([]);
  const [recruterOptions, setRecruterOptions] = useState([]);
  const [allStatusList, setAllStatusList] = useState([]);
  const [applyCustomerDecision, setApplyCustomerDecision] = useState([]);
  const [currentVideoIndexes, setCurrentVideoIndexes] = useState([]);
  const [showModalApprove, setShowModalApprove] = useState(false);
  const [changedApplyStatus, setChangedApplyStatus] = useState({
    item: "",
    newStatus: "",
  });
  const [currentFilter, setCurrentFilter] = useState("not_selected");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  var { 0: currentRecruter, 1: setCurrentRecruter } = useState('');

  const patchApplyCustomerDecisionVideo = async (item) => {
    const response = await fetch(variables.API_URL + `/api/apply_customer_decision/${item.id}`, {
      method: "PATCH",
      headers: {
        Accept: " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${props.token}`,
      },
      body: JSON.stringify(item),
    });
    if (response.ok) {
      const data = await response.json();
    } else {
    }
  };

  const patchApplyCustomerDecision = async (item) => {
    const response = await fetch(variables.API_URL + `/api/apply_customer_decision/${changedApplyStatus.item.id}`, {
      method: "PATCH",
      headers: {
        Accept: " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${props.token}`,
      },
      body: JSON.stringify(item),
    });
    if (response.ok) {
      const data = await response.json();
      getApplyCustomerDecision({ decision: currentFilter, page: currentPage });
    } else {
    }
  };

  const getApplyCustomerDecision = async ({ decision, page }) => {
    const response = await fetch(
      variables.API_URL +
        `/api/apply_customer_decision/?vacancy_id=${vacancyId}&decision=${decision}&page=${page}&pageSize=${pageSize}`,
      {
        method: "GET",
        headers: {
          Accept: " application/json",
          "Content-Type": " application/json",
          Authorization: `Bearer ${props.token}`,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      const currentIndexes = data.values.map((item) => {
        if (item.video.length > 0) {
          return 0;
        }
      });
      setCurrentVideoIndexes(currentIndexes);
      setApplyCustomerDecision(data.values);
      setTotalCount(data.total);
    } else {
    }
  };

  const refreshStatusList = () => {
    axios
      .get(variables.API_URL + "/api/get_vacancy_status_list/", {
        headers: {
          Accept: " application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((response) => {
        let data = response.data;
        setAllStatusList(data);
      });
  };

  const refreshStageList = () => {
    axios
      .get(variables.API_URL + "/api/get_vacancy_stage_list/", {
        headers: {
          Accept: " application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((response) => {
        let data = response.data;
        setStageList(data);
      });
  };

  const refreshRecruterList = () => {
    fetch(variables.API_URL + "/api/get_recruter_options/", {
      headers: {
        Accept: " application/json",
        "Content-Type": " application/json",
        Authorization: `Bearer ${props.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRecruterOptions(data);
      });
  };

  useLayoutEffect(() => {
    refreshData();
    refreshApplies();
    refreshStatusList();
    refreshStageList();
    refreshRecruterList();
  }, []);

  useEffect(() => {
    refreshData()
  }, [currentRecruter]);

  useEffect(() => {
    getApplyCustomerDecision({
      decision: currentFilter,
      page: currentPage,
    });
  }, []);

  const refreshData = () => {
    axios
      .get(variables.API_URL + "/api/vacancy_statistic_detail/" + vacancyId, {
        headers: {
          Accept: " application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        params: {user_id: currentRecruter}
      })
      .then((response) => {
        let data = response.data;
        setInfoDict({
          name: data.name,
          id: data.id,
          company: data.company,
          date: data.date,
          close_date: data.close_date,
          recruter: data.recruter_id,
          recruter_name: data.recruter,
          curator: data.curator_id,
          curator_name: data.curator,
          closed_time: data.closed_time,
          days_passed: data.days_passed,
          status: data.status,
          stage: data.stage,
          is_director: data.is_director,
          candidate_count: data.candidate_count,
        });
        setStatusList(
          Object.keys(data.bar_data).filter(function (x) {
            return x !== "name";
          })
        );
        setBarChartStatistic([data.bar_data]);
        setStatistic(data.statistic_chart);
        sumStatistic(data.statistic_chart);
        if (data.statistic_chart[0]) {
          setStatOptions(
            Object.keys(data.statistic_chart[0]).filter(function (x) {
              return x !== "name";
            })
          );
        }
      });
  };

  const refreshApplies = () => {
    axios
      .get(variables.API_URL + "/api/vacancy_applies/" + vacancyId, {
        headers: {
          Accept: " application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.token}`,
        },
        // params: {date: selectedDate, type: activeMenuItem.value, year: isYearItem}
      })
      .then((response) => {
        let data = response.data;
        setApplies(data);
      });
  };

  const sumStatistic = (list_statistic) => {
    let calculated_statistic = [];
    let previous_user_count = {};
    for (let item of list_statistic) {
      let users_list = Object.keys(item);
      var user_count = {};
      for (let user of users_list) {
        if (user === "name") user_count["name"] = item["name"];
        else {
          user_count[user] = previous_user_count[user] ? previous_user_count[user] + item[user] : item[user];
          previous_user_count[user] = user_count[user];
        }
      }
      calculated_statistic.push(user_count);
    }

    if (user_count) {
      setSumTime(Math.floor(user_count["Время"] / 60));
    }

    setStatisticSum(calculated_statistic);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{label}</p>
          {payload.map((item) => {
            if (item.value) {
              if (item.name === "Время") {
                return (
                  <p style={{ color: item.color, fontWeight: "600" }} key={item.name}>
                    {item.name}: {Math.floor(item.value / 60)} ч {item.value % 60} м
                  </p>
                );
              } else {
                return (
                  <p style={{ color: item.color }} key={item.name}>
                    {item.name}: {item.value}
                  </p>
                );
              }
            }
          })}
        </div>
      );
    }

    return null;
  };

  const changeApplyVideoViewed = (item, index) => {
    if (!item.viewed) {
      var newArr = [...applyCustomerDecision];
      newArr[index].viewed = true;
      setApplyCustomerDecision(newArr);

      const changeItem = {
        id: item.id,
        apply_id: item.apply_id,
        viewed: true,
      };
      patchApplyCustomerDecisionVideo(changeItem);
    }
  };

  const toTimeFormat = (decimal, fixed = 0) => `${Math.floor(decimal / 60)}ч${decimal % 60}м`;

  const videoItem = (item, index) => {
    return (
      <>
        <div className="video_slider">
          {/* {item.video[item.curVideoIndex].url === item.video[0].url ? ( */}
          {item.video[currentVideoIndexes[index]].id === item.video[0].id ? (
            <div className="dis_button"></div>
          ) : (
            <button
              className="btn_video_slider btn_video_slider_left"
              onClick={(event) => {
                var newCurrentInexes = [...currentVideoIndexes];
                newCurrentInexes[index] -= 1;
                setCurrentVideoIndexes(newCurrentInexes);
              }}
            >
              <i className="fa fa-angle-left" aria-hidden="true"></i>
            </button>
          )}

          <video
            className="fast_video"
            src={item.video[currentVideoIndexes[index]].url + "?t=" + props.token}
            type="video/mp4"
            muted
            poster={getImage(item.video[currentVideoIndexes[index]]?.miniature)}
            controls
            onPlay={() => {
              changeApplyVideoViewed(item, index);
            }}
          >
            {/* <source src={item.video[currentVideoIndexes[index]].url + "?t=" + props.token} type="video/mp4"></source> */}
          </video>

          {/* {item.video[item.curVideoIndex].url === item.video.at(-1).url ? ( */}
          {item.video[currentVideoIndexes[index]].id === item.video.at(-1).id ? (
            <div className="dis_button"></div>
          ) : (
            <button
              className="btn_video_slider btn_video_slider_right"
              onClick={(event) => {
                var newCurrentInexes = [...currentVideoIndexes];
                newCurrentInexes[index] += 1;
                setCurrentVideoIndexes(newCurrentInexes);
              }}
            >
              <i className="fa fa-angle-left fa-flip-horizontal" aria-hidden="true"></i>
            </button>
          )}
        </div>
      </>
    );
  };

  const openApproveModal = ({ item, status }) => {
    var newDecision = [...applyCustomerDecision];
    setChangedApplyStatus({
      // index: index,
      item: item,
      status: status,
    });
    setShowModalApprove(true);
  };

  const changeCurrentFilter = async (filter) => {
    setCurrentFilter(filter);

    getApplyCustomerDecision({
      decision: filter,
      page: currentPage,
    });
  };

  const getImage = (image, folder) => {
    if (image) {
      if (folder) {
        return variables.API_URL + "/media/media/" + folder + "/" + image.split("/").pop();
      } else {
        return variables.API_URL + "/media/media/" + image.split("/").pop();
      }
    }
  };

  const addControlButtons = (item) => {
    return (
      <table style={{ width: "100%" }} className="table_with_text_on_center">
        <tbody>
          <tr style={{ justifyContent: "spaceBetween" }}>
            {(() => {
              if (currentFilter === "not_selected" || currentFilter === "yes")
                return (
                  <td>
                    <button
                      className=" btn_verdict btn_refuse"
                      onClick={() => {
                        openApproveModal({
                          item: item,
                          status: "no",
                        });
                      }}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </td>
                );
            })()}

            {(() => {
              if (currentFilter === "not_selected" || currentFilter === "no")
                return (
                  <td>
                    <button
                      className="btn_verdict btn_accept"
                      onClick={() => {
                        openApproveModal({
                          item: item,
                          status: "yes",
                        });
                      }}
                    >
                      <i className="fa fa-check" aria-hidden="true"></i>
                    </button>
                  </td>
                );
            })()}
          </tr>
        </tbody>
      </table>
    );
  };

  const changePage = (page) => {
    if (page === currentPage) return;
    setCurrentPage(page);
    getApplyCustomerDecision({
      decision: currentFilter,
      page: page,
    });
  };

  // const showModalApprove = () => {};

  return (
    <>
      <div style={{ height: "calc(100% - 400px)" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignContent: "center",
              columnGap: "15px",
            }}
          >
            <h3 style={{ textAlign: "left", padding: "10px 10px 10px 0" }}>{infoDict.id ? infoDict.id : ""}</h3>
            <h3 style={{ textAlign: "left", padding: "10px 10px 10px 0" }}>{infoDict.name}</h3>
            <div
              className="mx-3"
              style={{
                padding: "5px",
                margin: "auto 0px",
                minWidth: "100px",
                background: "blue",
                height: "50px",
                color: "white",
                fontSize: "25px",
              }}
            >
              {sumTime ? sumTime : "0"} ч
            </div>
            <div
              style={{
                color: "gray",
                fontSize: "25px",
                fontWeight: "300",
                padding: "10px",
              }}
            >
              {infoDict.days_passed} дней
            </div>
          </div>
          <div>
            {!props.customerId && props.isSuperUser.toString() === "2" && (
              <Button className="mx-2" variant="outline-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Изменить вакансию
              </Button>
            )}
            <Button height={"45px"} style={{ margin: "auto 0" }} variant="primary" onClick={() => navigate(-1)}>
              Назад
            </Button>
          </div>
        </div>
        <div className="vacancy-info color">
          <p>
            <strong>{infoDict.company ? infoDict.company : ""}</strong>
          </p>
          <p className="mx-3">
            {infoDict.date ? infoDict.date : ""} - {infoDict.close_date ? infoDict.close_date : ""}
          </p>
        </div>
        <div className="vacancy-info mb-3">
          <p>
            Рекрутер: <strong>{infoDict.recruter_name ? infoDict.recruter_name : " — "}</strong>
          </p>
          <p className="mx-3">
            Куратор: <strong>{infoDict.curator_name ? infoDict.curator_name : " — "}</strong>
          </p>
          <p>
            Срок: <strong> {infoDict.closed_time ? infoDict.closed_time : " — "}</strong>
          </p>
          <div className="switcher" style={{ marginLeft: "auto" }}>
            <input
              id={"isSumStatistic"}
              className="switcher__input"
              type="checkbox"
              checked={isSumStatistic}
              onChange={() => setIsSumStatistic(!isSumStatistic)}
            />
            <label htmlFor={"isSumStatistic"} className="switcher__switcher"></label>
            <div>Накопом</div>
          </div>
        </div>

        <div style={{ height: "100%" }}>
          <ResponsiveContainer width="100%" height={150}>
            <BarChart
              data={barChartStatistic}
              layout="vertical"
              margin={{
                top: 20,
                right: 0,
                left: 0,
                bottom: 5,
              }}
            >
              <XAxis type="number" domain={[0, infoDict?.closed_time ? infoDict?.closed_time + 1 : null]} />
              <YAxis type="category" dataKey="name" tick={null} />
              <Tooltip wrapperStyle={{ zIndex: 1000 }} />
              <Legend />
              <CartesianGrid strokeDasharray="3 3" />
              {statusList.map((item, index) => (
                <Bar dataKey={item} stackId="a" fill={colors.stageByName[item]} key={index} />
              ))}
              {infoDict?.closed_time && <ReferenceLine x={infoDict.closed_time} stroke="red" strokeWidth={3} />}
            </BarChart>
          </ResponsiveContainer>

          <Select_1
            className="vacancy_statistic_select_user"
            value={currentRecruter}
            onChange={(e) => {setCurrentRecruter(e.target.value); console.log(e.target.value);
            }}
            options={[{ value: '', label: "Все" }, ...recruterOptions]}
          />
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={isSumStatistic ? statisticSum : statistic}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="5 5" />
              <XAxis dataKey="name" padding={{ left: 10, right: 10, top: 20 }} />
              <YAxis yAxisId="left" />
              <YAxis
                yAxisId="right"
                orientation="right"
                stroke="black"
                strokeWidth={3}
                tick={{ fill: "rgba(0, 0, 0)" }}
                tickFormatter={toTimeFormat}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend
                formatter={(value, entry, index) => (
                  <span style={{ fontWeight: value != "Время" ? "" : "600" }}>{value}</span>
                )}
              />
              {statOptions.map((option, index) => {
                if (option === "Время")
                  return (
                    <Line
                      connectNulls
                      yAxisId="right"
                      type="monotone"
                      dataKey={option}
                      strokeWidth={3}
                      stroke={"#333"}
                      key={index}
                    />
                  );
                else
                  return (
                    <Line
                      connectNulls
                      yAxisId="left"
                      type="monotone"
                      dataKey={option}
                      stroke={colors.action[index - 1]}
                      key={index}
                    />
                  );
              })}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div style={{ marginTop: "350px" }}>
        {applies.length !== 0 && (
          <div className="mt-5">
            <table className="table">
              <thead>
                <tr>
                  <th>Фотография</th>
                  <th>ФИО</th>
                  <th>Статус</th>
                  <th>Видео</th>
                </tr>
              </thead>
              <tbody>
                {applies.map((item) => (
                  <tr key={item.id} style={{ verticalAlign: "baseline" }}>
                    <td>
                      {item.photo ? (
                        <img
                          width="60px"
                          height="60px"
                          style={{ objectFit: "cover" }}
                          src={variables.API_URL + "/media/" + item.photo}
                          alt="Profile"
                        />
                      ) : (
                        <img src={new_image} width="60px" height="60px" />
                      )}
                    </td>
                    <td>
                      {props.customerId ? (
                        <a className="link" href={`/customer_apply/${item.id}`}>
                          {item.name}
                        </a>
                      ) : (
                        <a className="link" href={`/apply/${item.id}`}>
                          {item.name}
                        </a>
                      )}
                    </td>

                    <td>{item.stage_status}</td>
                    <td>{item.video_counter ? "Есть" : "Нет"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <VacancyChangeModal
        modalTitle={"Изменить вакансию"}
        vacancy={infoDict}
        isEdit={true}
        recruterOptions={recruterOptions}
        statusList={allStatusList}
        stageList={stageList}
        token={props.token}
        refreshList={refreshData}
      />

      <div
        className="selector_area mt-5 mb-5"
        onChange={(event) => {
          changeCurrentFilter(event.target.value);
        }}
      >
        <div>
          <input type="radio" id="not_selected" name="contact" value="not_selected" defaultChecked />
          <label htmlFor="not_selected">Без выбора</label>
        </div>
        <div>
          <input type="radio" id="yes" name="contact" value="yes" />
          <label htmlFor="yes">Да</label>
        </div>
        <div>
          <input type="radio" id="no" name="contact" value="no" />
          <label htmlFor="no">Нет</label>
        </div>
      </div>
      <h5 className="count_title">Количество: {totalCount}</h5>

      {/* ПОПЫТКА В МОБИЛЬНУЮ ВЕРСТКУ */}

      {/* <div className="videos_area">
        <h5 className="count_title">Количество: 10</h5>

        <div className="video_item">
          <video className="fast_video" controls></video>
          <div className="btn_video_arae">
            <div className="mode_div">
              <div className="form_toggle_item item-1">
                <input
                  id="fid-1"
                  type="radio"
                  name="radio"
                  value="first"
                  defaultChecked
                />
                <label for="fid-1">
                  <p>ДА</p>
                </label>
              </div>
              <div className="form_toggle_item item-2">
                <input id="fid-2" type="radio" name="radio" value="second" />
                <label for="fid-2">
                  <p>НЕТ</p>
                </label>
              </div>
            </div>
          </div>
          <div>
            <textarea
              className="textarea_comment"
              rows="3"
              placeholder="Комментарий"
            ></textarea>
            <div>
              <button className="btn_video_confirm btn_video_confirm_yes">
                Подтвердить ДА
              </button>
              <button className="btn_video_confirm btn_video_confirm_no">
                Подтвердить НЕТ
              </button>
            </div>
          </div>
        </div>
      </div> */}

      <table className="table" style={{ width: "100%" }}>
        <thead style={{ width: "100%" }}>
          <tr>
            <th style={{ width: "35%" }}>Видео</th>
            {/* <th>Комментари</th> */}
            <th
            // style={{ textAlign: "center" }}
            >
              ФИО
            </th>
            {currentFilter === "no" || currentFilter === "yes" ? (
              <th span="1" style={{ width: "25%" }}>
                Комментарий
              </th>
            ) : undefined}
            <th span="1" style={{ width: "10%" }}>
              Вердикт
            </th>
          </tr>
        </thead>
        <tbody>
          {!!applyCustomerDecision.length &&
            applyCustomerDecision.map((item, index) => {
              return (
                <tr style={{ verticalAlign: "middle" }} key={item.id}>
                  <td>{videoItem(item, index)}</td>
                  <td>
                    {props.customerId ? (
                      <a className="link" href={`/customer_apply/${item.apply_id}`}>
                        {item.name}
                      </a>
                    ) : (
                      <a className="link" href={`/apply/${item.apply_id}`}>
                        {item.name}
                      </a>
                    )}
                  </td>
                  {currentFilter === "no" || currentFilter === "yes" ? <td>{item.comment}</td> : undefined}
                  {/* // <a>
                //   <td
                //     className="link"
                //     onClick={() => {
                //       if (props.customerId) {
                //         navigate("/customer_apply/" + item.apply_id);
                //       } else {
                //         navigate("/apply/" + item.apply_id);
                //       }
                //     }}
                //     // onClick={() => navigate("/apply/" + item.apply_id)}
                //   >
                //     {item.name}
                //   </td>
                // </a> */}
                  <td>{addControlButtons(item)}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {showModalApprove ? (
        <ModalApprove
          apply={changedApplyStatus}
          token={props.token}
          update={patchApplyCustomerDecision}
          isOpened={showModalApprove}
          onModelClose={() => {
            setShowModalApprove(false);
          }}
        ></ModalApprove>
      ) : (
        <></>
      )}
      <div className="text-center d-flex justify-content-center mb-5">
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          onPageChange={(page) => changePage(page)}
        />
      </div>
    </>
  );
}

function ModalApprove(props) {
  const [comment, setComment] = useState(props.apply.item.comment);
  const [commentCorrect, setCommentCorrect] = useState(true);
  useEffect(() => {
    setCommentCorrect(Boolean(comment.trim().length > 0));
  }, [comment]);

  const pushBtnApprove = (item) => {
    if (commentCorrect) {
      props.onModelClose();
      props.update({
        status: props.apply.status,
        comment: comment,
        viewed: props.apply.item.viewed,
      });
    }
  };

  return (
    <div>
      <Modal className="modal_approve" show={props.isOpened} onHide={props.onModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="fw-bold mb-0">Подтверждение</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            className={commentCorrect ? "comment" : "comment void_comment"}
            rows="6"
            placeholder="Комментарий"
            onChange={(event) => {
              setComment(event.target.value);
            }}
            value={comment}
          ></textarea>
        </Modal.Body>
        {/* <Modal.Footer> */}
        <button className="btn btn-primary float-start" onClick={pushBtnApprove}>
          {"Подтвердить " + (props.apply.status === "no" ? "НЕТ" : "ДА")}
        </button>
        {/* </Modal.Footer> */}
      </Modal>
    </div>
  );
}
